import { useListContext } from 'react-admin'
import { Checkbox } from '@mui/material'
import { FC } from 'react'
import _ from 'lodash'
import { useTranslate } from 'react-admin'

export const CustomAllCheckboxSelected: FC = () => {
  const { data, onSelect, selectedIds } = useListContext()
  const ids = data?.map(item => item['@id']) || []
  const t = useTranslate()
  return (
    <div className='flex items-center '>
      <Checkbox
        onChange={e =>
          e.target.checked
            ? onSelect(_.uniq([...selectedIds, ...ids]))
            : onSelect(selectedIds.filter(id => !ids.includes(id)))
        }
        checked={ids.every(id => selectedIds.includes(id))}
      />
      {t('users.select_all')}
    </div>
  )
}
