import { PreAdminLayout } from 'shared/ui'
import { RegistrationForm } from './registration-form'

export const RegistrationPage = () => {
  return (
    <PreAdminLayout>
      <RegistrationForm />
    </PreAdminLayout>
  )
}
