import { Link, useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { Form } from 'shared/libs'
import { CustomTextInput } from 'shared/ui'
import { phoneSchema } from '../lib'
import { useAuthenticated } from 'react-admin'

export const SettingsPhoneForm = () => {
  const navigate = useNavigate()
  useAuthenticated()
  return (
    <Form
      validationSchema={phoneSchema}
      onSubmit={({ phone }) =>
        navigate('/settings/code', {
          state: phone,
        })
      }
      className='flex flex-col'
    >
      {() => (
        <>
          <p className='text-black text-title mb-big'>Change phone number</p>
          <p className='text-subtext text-gray mb-big'>
            Verification code will be sent to this number
          </p>
          {/* TODO:Добавить маску */}
          <CustomTextInput
            name='phone'
            source='phone'
            label='New phone number'
            className='!mb-[70px]'
            InputProps={{ className: 'w-input' }}
          />
          <div className='flex items-center gap-base'>
            <Link to='/settings'>
              <Button
                color='secondary'
                variant='contained'
                size='large'
                className='w-button'
              >
                Cancel
              </Button>
            </Link>
            <Button
              type='submit'
              variant='contained'
              size='large'
              className=' w-button'
            >
              Next
            </Button>
          </div>
        </>
      )}
    </Form>
  )
}
