import { HydraAxiosError, ServerSideValidator } from 'shared/@types'
import { ONLY_HTTPS_URL_REG_EXP } from 'shared/config'
import { Translate } from 'react-admin'
import * as yup from 'yup'
import { ReferralEvent } from '../model'

export const referralSystemSchema = (t: Translate) =>
  yup
    .object({
      name: yup
        .string()
        .required(t('payout_building.create.ref_system_name_req')),
      targetLink: yup
        .string()
        .matches(
          ONLY_HTTPS_URL_REG_EXP,
          t('payout_building.create.use_https_link')
        )
        .required(t('payout_building.create.ref_address_req')),
      sessionLifeSpan: yup
        .number()
        .integer(t('payout_building.create.session_life_span_integer'))
        .min(1, t('payout_building.create.session_life_span_min'))
        .required(t('payout_building.create.session_life_span_req')),
      customReferralEvents: yup.array().of(
        yup.object({
          name: yup
            .string()
            .required(t('payout_building.create.event_name_req')),
          sum: yup.number().when('hasCost', {
            is: true,
            then: schema =>
              schema.min(0, t('payout_building.create.cost_must_greater')),
          }),
        })
      ),
      company: yup.object({
        '@id': yup.string().required(t('payout_building.create.company_req')),
      }),
      compensation: yup.number().when('customReferralEvents', {
        is: (events: ReferralEvent[]) => events?.some(event => event.sum),
        then: schema =>
          schema.min(
            0,
            t('payout_building.create.compensation_must_not_negative')
          ),
        otherwise: schema =>
          schema.moreThan(
            0,
            t('payout_building.create.compensation_must_greater')
          ),
      }),
    })
    .required()

export const serverSideReferralSystemsValidation: ServerSideValidator =
  notify => e => {
    const { response } = e as HydraAxiosError
    if (response?.status === 422) {
      if (
        response.data.violations?.some(
          error =>
            error.message ===
            'referralSystem.errors.name_already_exist_in_company'
        )
      ) {
        return notify?.('Referral system with this name already exists', {
          type: 'error',
        })
      }
    }

    return notify?.(response?.data['hydra:description'] || '', {
      type: 'error',
    })
  }
