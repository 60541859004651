import list from './referral-systems-list'
import create from './referral-systems-create'
import edit from './referral-systems-edit'
export * from './referral-system-step'
export * from './referral-system-form'

export default {
  list,
  create,
  edit,
}
