import { PHONE_REG_EXP } from 'shared/config'
import * as yup from 'yup'

export const phoneSchema = yup
  .object({
    phone: yup
      .string()
      .matches(PHONE_REG_EXP, 'Invalid phone numbeer')
      .required('Phone is required'),
  })
  .required()

export const codeSchema = yup
  .object({
    code: yup.string().required('Code is required'),
  })
  .required()

export const companySettingsSchema = yup
  .object({
    language: yup.string().notRequired(),
  })
  .required()
