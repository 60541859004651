import { FC, useState } from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useNotify, useListContext } from 'react-admin'
import { Button } from '@mui/material'
import {
  acceptExternalTransaction,
  dismissExternalTransaction,
  ExternalTransactionActionType,
  EXTERNAL_TRANSACTION_ACTION_TYPE,
} from '../model'
import { getNumberFromString } from 'shared/helpers'
import { LoadingButton } from '@mui/lab'
import { HydraAxiosError } from 'shared/@types'
import { useTranslate } from 'react-admin'
export interface ConfirmExternalTransactionProps extends DialogProps {
  transactionId?: string
  type?: ExternalTransactionActionType
  open: boolean
  onClose: () => void
  isForce?: boolean
}

export const ConfirmExternalTransaction: FC<
  ConfirmExternalTransactionProps
> = ({
  transactionId,
  type = 'accept',
  open,
  onClose,
  className = '',
  isForce,
  ...rest
}) => {
  const t = useTranslate()
  const { refetch } = useListContext()
  const [isLoading, setIsLoading] = useState(false)
  const [needForce, setNeedForce] = useState(false)
  const notify = useNotify()

  function handleAcceptExternalTransactionError(error: HydraAxiosError) {
    const errorMessage = error.response?.data?.['hydra:description']

    if (errorMessage?.includes('транзакция будет отплачена TRX')) {
      setNeedForce(true)
      return
    }
    if (errorMessage) {
      notify?.(errorMessage, {
        type: 'error',
      })
      return
    }
    notify?.(`Failed to ${type} transaction`, {
      type: 'error',
    })
  }
  const onConfirm = async (transactionId: string) => {
    if (!transactionId) return
    try {
      setIsLoading(true)
      const id = +getNumberFromString(transactionId)
      if (type === EXTERNAL_TRANSACTION_ACTION_TYPE) {
        await acceptExternalTransaction(id, needForce)
      } else {
        await dismissExternalTransaction(id)
      }
      await refetch()
      const notifyText = t(
        'external_transactions.notifications.' +
          (type === 'accept'
            ? 'successfully_accepted'
            : 'successfully_dismissed')
      )
      notify?.(notifyText, { type: 'success' })
      onClose()
    } catch (error) {
      handleAcceptExternalTransactionError(error as HydraAxiosError)
    } finally {
      setIsLoading(false)
    }
  }

  const confirmContent = (() => {
    let header = ''
    let text = ''
    let okButtonText = ''
    let okButtonMethod
    const defaultOkMethod = () => transactionId && onConfirm(transactionId)
    if (needForce) {
      header = t('external_transactions.table.modal.low_energy_error')
      text = t('external_transactions.table.modal.next_transation_with_trx')
      okButtonText = t('external_transactions.table.modal.accept')
      okButtonMethod = () => [defaultOkMethod(), setNeedForce(false)]
    } else {
      header =
        type === EXTERNAL_TRANSACTION_ACTION_TYPE
          ? t('external_transactions.table.modal.do_you_want')
          : t('external_transactions.table.modal.do_you_want_dismiss')
      text = t('external_transactions.table.modal.cannot_be_undone')
      okButtonText =
        type === EXTERNAL_TRANSACTION_ACTION_TYPE
          ? t('external_transactions.table.modal.accept')
          : t('external_transactions.table.modal.dismiss')
      okButtonMethod = defaultOkMethod
    }
    return {
      header,
      text,
      okButtonText,
      okButtonMethod,
    }
  })()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      {...rest}
    >
      <DialogContent
        dividers
        className='flex flex-col justify-between w-[306px] h-[257px] p-big'
      >
        <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
          <p className='font-medium text-center text-black text-title mb-base'>
            {confirmContent.header}
          </p>
          <p className='text-center text-[#727272] text-subtext'>
            {confirmContent.text}
          </p>
        </DialogContentText>
        <div className='flex flex-col gap-3'>
          <LoadingButton
            type='submit'
            variant='contained'
            size='large'
            loading={isLoading}
            onClick={confirmContent.okButtonMethod}
          >
            {confirmContent.okButtonText}
          </LoadingButton>
          <Button
            variant='contained'
            color='secondary'
            size='large'
            disabled={isLoading}
            onClick={() => [setNeedForce(false), onClose()]}
          >
            {t('toolbar.cancel')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
