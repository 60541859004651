import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import _ from 'lodash'
import Cookies from 'universal-cookie'
import { auth, TokenResponse } from './auth-request'
import { AXIOS_DEFAULT_HEADERS } from './config'
import { BASE_URL } from './config/env'
import { addLocaleIfNeed } from './helpers'

export interface RequestQueue {
  resolve: (value: unknown) => void
  reject: (value: unknown) => void
  config: AxiosRequestConfig
}
const instance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
})

const cookies = new Cookies()

export const setCookieTokens = (data: TokenResponse) => {
  cookies.set('user_access_token', data.access_token, {
    path: '/',
    maxAge: data.expires_in,
  })
  cookies.set('user_refresh_token', data.refresh_token, { path: '/' })
}

export const getTokens = () => {
  const access_token = cookies.get('user_access_token')
  const refresh_token = cookies.get('user_refresh_token')
  return { access_token, refresh_token }
}

export const logout = () => {
  localStorage.removeItem('referral_admin_current_user')
  cookies.remove('user_access_token', { path: '/' })
  cookies.remove('user_refresh_token', { path: '/' })
  cookies.remove('company_access_token', { path: '/' })
  cookies.remove('company_refresh_token', { path: '/' })
}

export const requestTokens = _.debounce(
  (refresh_token: string, requestQueue: RequestQueue[]) =>
    auth(refresh_token).then(() => {
      const isList = !!(window.location.hash.split('/').length < 3)
      Promise.allSettled(
        requestQueue.map(({ config, reject, resolve }) =>
          httpClient(config).then(resolve).catch(reject)
        )
      ).finally(() => isList && window.location.reload())
    }),
  300
)

instance.interceptors.request.use(requestInterceptor)
instance.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (error.response?.status === 308) {
      return Promise.resolve()
    }
    return Promise.reject(error)
  }
)

async function requestInterceptor(config: AxiosRequestConfig) {
  const access_token = getTokens().access_token

  config.headers = {
    ...AXIOS_DEFAULT_HEADERS,
    ...config.headers,
  }

  if (access_token) config.headers.Authorization = `Bearer ${access_token}`

  return config
}

export const httpClient = <T, D = undefined>(
  config: AxiosRequestConfig<D>
): Promise<AxiosResponse<T, D>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await instance(addLocaleIfNeed(config))
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
}
