export const EXTERNAL_TRANSACTION_STATUS_NEW = 0
export const EXTERNAL_TRANSACTION_STATUS_IN_QUEUE = 1
export const EXTERNAL_TRANSACTION_STATUS_IN_PROCESS = 2
export const EXTERNAL_TRANSACTION_STATUS_COMPLETED = 3
export const EXTERNAL_TRANSACTION_STATUS_ERROR = 4
export const EXTERNAL_TRANSACTION_STATUS_CANCEL = 5

export const EXTERNAL_TRANSACTION_TYPE_WEBHOOK = 1
export const EXTERNAL_TRANSACTION_TYPE_PAYOUT = 2

export const EXTERNAL_TRANSACTION_ACTION_TYPE = 'accept'
export const EXTERNAL_TRANSACTION_DISMISS_TYPE = 'dismiss'

export const ACCEPT_EXTERNAL_TRANSACTION_TARGET = 'accept_withdraw'
export const DISMISS_EXTERNAL_TRANSACTION_TARGET = 'cancel_withdraw'

export enum EXTERNAL_TRANSACTION_STATUSES {
  New = EXTERNAL_TRANSACTION_STATUS_NEW,
  'In Queue' = EXTERNAL_TRANSACTION_STATUS_IN_QUEUE,
  'In Process' = EXTERNAL_TRANSACTION_STATUS_IN_PROCESS,
  Completed = EXTERNAL_TRANSACTION_STATUS_COMPLETED,
  Error = EXTERNAL_TRANSACTION_STATUS_ERROR,
  Cancel = EXTERNAL_TRANSACTION_STATUS_CANCEL,
}

export enum EXTERNAL_TRANSACTION_TYPES {
  Webhook = EXTERNAL_TRANSACTION_TYPE_WEBHOOK,
  Payout = EXTERNAL_TRANSACTION_TYPE_PAYOUT,
}
