import {
  HydraAdmin,
  ResourceGuesser,
  hydraDataProvider,
} from '@api-platform/admin'
import { CustomRoutes } from 'react-admin'
import { Route } from 'react-router-dom'
import { parseHydraDocumentation } from '@api-platform/api-doc-parser'
import { authProvider, LoginPage } from 'features/auth/basic'
import {
  convertAxioseResponseDataToHydra,
  convertFetchConfigToAxios,
  getDefaultAuthHeaders,
} from 'shared/helpers'
import { ReactComponent as Balance } from 'app/assets/icons/balance.svg'
import { ReactComponent as Users } from 'app/assets/icons/users.svg'
import { ReactComponent as InternalTransactions } from 'app/assets/icons/internal-transactions.svg'
import { ReactComponent as ExternalTransactions } from 'app/assets/icons/external-transactions.svg'
import { ReactComponent as Builder } from 'app/assets/icons/builder.svg'
import Layout from 'widgets/layout'
import { BASE_URL, theme } from 'shared/config'
import users, { ROLE_ADMIN } from 'entities/users'
import referralSystems from 'entities/referral-systems'
import transactions from 'entities/transactions'
import externalTransactions from 'entities/external-transactions'
import './index.css'
import {
  SettingsPage,
  SettingsPhoneForm,
  SettingsCodeForm,
} from 'features/settings'
import { RegistrationPage, UserConfirmationPage } from 'features/registration'
import { NewPasswordPage, ResetPasswordPage } from 'features/reset-password'
import company from 'entities/company'
import { Permissions } from 'features/auth/basic'
import { getTokens, httpClient, logout } from 'shared/http-client'
import { i18nProvider } from './i18n'
import { auth } from 'shared/auth-request'
import Cookies from 'universal-cookie'

const dataProvider = () =>
  hydraDataProvider({
    entrypoint: BASE_URL,
    useEmbedded: true,
    apiDocumentationParser: url =>
      parseHydraDocumentation(url, {
        headers: getDefaultAuthHeaders,
      }).catch(error => {
        new Cookies().remove('user_access_token', { path: '/' })

        const { refresh_token } = getTokens()

        if (refresh_token) {
          auth(refresh_token)
            .then(() => Promise.resolve())
            .catch(error => [logout(), Promise.reject(error)])
            .finally(() => window.location.reload())
        }

        return Promise.reject(error)
      }),
    httpClient: (url, options?) =>
      httpClient(convertFetchConfigToAxios(url, options)).then(res =>
        convertAxioseResponseDataToHydra(res)
      ),
  })

const App = () => (
  <HydraAdmin
    basename='/admin'
    entrypoint={BASE_URL}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    layout={Layout}
    loginPage={LoginPage}
    theme={theme}
    dataProvider={dataProvider()}
  >
    {permissions => {
      const isAdmin = (permissions as Permissions)?.roles?.includes(ROLE_ADMIN)
      return (
        <>
          {isAdmin && (
            <ResourceGuesser
              name='companies'
              key='companies'
              options={{
                label: 'tabs.company_balance',
              }}
              icon={Balance}
              {...company}
              create={<></>}
              edit={<></>}
              show={<></>}
            />
          )}
          <ResourceGuesser
            name='users'
            key='users'
            icon={Users}
            options={{
              label: 'tabs.users',
            }}
            {...users}
            create={<></>}
            show={<></>}
          />
          <ResourceGuesser
            name='transactions'
            key='transactions'
            options={{ label: 'tabs.internal_transactions' }}
            icon={InternalTransactions}
            {...transactions}
            create={<></>}
            edit={<></>}
            show={<></>}
          />
          {isAdmin && (
            <ResourceGuesser
              name='external_transactions'
              key='external_transactions'
              options={{ label: 'tabs.external_transactions' }}
              icon={ExternalTransactions}
              {...externalTransactions}
              create={<></>}
              edit={<></>}
              show={<></>}
            />
          )}
          <ResourceGuesser
            name='referral_systems'
            key='referral_systems'
            options={{ label: 'tabs.payout_building' }}
            icon={Builder}
            {...referralSystems}
            show={<></>}
          />
          <CustomRoutes noLayout>
            <Route
              key='registration'
              path='/registration'
              element={<RegistrationPage />}
            />
            <Route
              key='activate'
              path='/activate'
              element={<UserConfirmationPage />}
            >
              <Route
                key='activate-code'
                path=':code'
                element={<UserConfirmationPage />}
              />
            </Route>
            <Route key='reset-password' path='/reset-password'>
              <Route
                key='reset-password-main'
                path='/reset-password'
                element={<ResetPasswordPage />}
              />
              <Route
                key='reset-password-code'
                path=':code'
                element={<NewPasswordPage />}
              />
            </Route>
          </CustomRoutes>
          {!isAdmin && (
            <CustomRoutes>
              <Route
                key='settings'
                path='/settings'
                element={<SettingsPage />}
              />
              <Route
                key='settings.phone'
                path='/settings/phone'
                element={<SettingsPhoneForm />}
              />
              <Route
                key='settings.code'
                path='/settings/code'
                element={<SettingsCodeForm />}
              />
            </CustomRoutes>
          )}
        </>
      )
    }}
  </HydraAdmin>
)

export default App
