import { FC, ReactElement } from 'react'
import {
  FilterButton,
  FilterForm,
  useListContext,
  usePermissions,
  useTranslate,
} from 'react-admin'
import { Stack } from '@mui/material'
import { CustomCreateButton, CustomCreateButtonProps } from 'shared/ui'
import { getCapitalizedString } from 'shared/helpers'
import { ROLE_COMPANY } from 'entities/users'
import { Permissions } from 'features/auth/basic'

export interface CustomActionListContentProps {
  title?: string
  filters: ReactElement[]
  withCreateButton?: boolean | ((data: unknown[]) => boolean)
  withFilterButton?: boolean
  withRoleUserCompany?: boolean
  CreateButtonProps?: CustomCreateButtonProps
}

export const CustomActionList: FC<CustomActionListContentProps> = ({
  title,
  filters,
  withCreateButton = true,
  withFilterButton = true,
  withRoleUserCompany = false,
  CreateButtonProps,
}) => {
  const t = useTranslate()
  const { data, displayedFilters, resource } = useListContext()
  const isFilterSelected = !!Object.keys(displayedFilters).length
  const { permissions } = usePermissions<Permissions>()
  const deleteFilter =
    withRoleUserCompany && permissions?.roles.includes(ROLE_COMPANY)
      ? filters.filter(({ key }) => key !== 'roles' && key !== 'companies')
      : filters

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      className='px-table tablet-small:pr-0'
    >
      <div className='flex items-center justify-between w-full h-16'>
        <div className='flex items-center gap-12'>
          <p className='text-black text-title'>
            {title || getCapitalizedString(resource)}
          </p>
          {data &&
            ((typeof withCreateButton !== 'function' && withCreateButton) ||
              (typeof withCreateButton === 'function' &&
                withCreateButton(data))) && (
              <CustomCreateButton {...CreateButtonProps} />
            )}
        </div>
        {withFilterButton && (
          <FilterButton filters={deleteFilter} filterValues={{}} />
        )}
      </div>
      {isFilterSelected && (
        <div className='flex items-center gap-8 py-1 mb-base'>
          <p className='text-gray text-subtext'>{t('ra.action.filters')}</p>
          <FilterForm
            filters={filters}
            className='!items-center gap-y-[18px] gap-x-8'
          />
        </div>
      )}
    </Stack>
  )
}
