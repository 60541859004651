import { FC } from 'react'
import { SelectArrayInput, SelectArrayInputProps } from 'react-admin'
import cn from 'classnames'

export const CustomSelectArrayInput: FC<SelectArrayInputProps> = ({
  className = '',
  ...rest
}) => {
  return (
    <SelectArrayInput
      variant='filled'
      className={cn('[&_.MuiFilledInput-root]:h-14', {
        [className]: className,
      })}
      helperText={false}
      {...rest}
    />
  )
}
