import list from './user-list'
import create from './user-create'
import edit from './user-edit'
export * from './user-form'
export * from './user-info'
export * from './custom-button-user-info'

export default {
  list,
  create,
  edit,
}
