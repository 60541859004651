import { AppBar, AppBarProps, useLogout, useGetIdentity } from 'react-admin'
import { Button } from '@mui/material'
import Cookies from 'universal-cookie'
import { ROLE_ADMIN, ROLE_DEFAULT } from 'entities/users'
import { useTranslate } from 'react-admin'
import { LocaleSwitcher } from 'features/locale-switcher'
import { PROJECT_VERSION } from 'shared/config'

export const Header = (props: AppBarProps) => {
  const cookies = new Cookies()
  const t = useTranslate()

  const logout = useLogout()
  const { identity } = useGetIdentity()
  const user_access_token = cookies.get('company_access_token')
  const user_refresh_token = cookies.get('company_refresh_token')
  const logoutOfCompany = () => {
    cookies.set('user_access_token', user_access_token)
    cookies.set('user_refresh_token', user_refresh_token)
    cookies.remove('company_access_token')
    cookies.remove('company_refresh_token')
    localStorage.setItem(
      'referral_admin_current_user',
      JSON.stringify({
        user: { ...identity?.user, roles: [ROLE_ADMIN, ROLE_DEFAULT] },
      })
    )
    window.location.reload()
  }
  return (
    <AppBar
      {...props}
      color='primary'
      userMenu={
        <div className='w-[600px] flex items-center justify-between'>
          <div className='whitespace-nowrap'>{PROJECT_VERSION}</div>
          {user_access_token && user_refresh_token && (
            <Button
              variant='text'
              onClick={logoutOfCompany}
              className='!text-white'
            >
              {t('header.return')}
            </Button>
          )}
          <LocaleSwitcher />
          <Button variant='text' onClick={logout} className='!text-white'>
            {t('header.exit')}
          </Button>
        </div>
      }
      className='h-16'
    >
      <div className='flex items-baseline justify-between w-full'>
        <span className='text-white text-title font-medium tracking-[0.15px]'>
          {props.title}
        </span>
      </div>
    </AppBar>
  )
}
