import { i18nProvider } from 'app/i18n'
import { AxiosError } from 'axios'
import { User } from 'entities/users'
import { getCurrentViewer } from 'entities/viewer'
import { CLIENT_ID, CLIENT_SECRET, TOKEN_PATH } from 'shared/config'
import { httpClient, setCookieTokens } from 'shared/http-client'

export interface TokenResponse {
  access_token: string
  expires_in: number
  refresh_token: string
  token_type: 'Bearer'
}

export const AuthGrantTypePassword = 'password'

export const AuthGrantTypeRefresh = 'refresh_token'

export async function auth(
  username: string,
  password: string
): Promise<User | void>
export async function auth(refresh_token: string): Promise<User | void>
export async function auth(arg1: string, arg2?: string): Promise<User | void> {
  const formData = new FormData()
  const { translate: t } = i18nProvider
  if (arg2) {
    formData.append('username', arg1)
    formData.append('password', arg2)
    formData.append('grant_type', AuthGrantTypePassword)
  } else {
    formData.append('grant_type', AuthGrantTypeRefresh)
    formData.append('refresh_token', arg1)
  }
  formData.append('client_id', CLIENT_ID as string)
  formData.append('client_secret', CLIENT_SECRET as string)
  try {
    const res = await httpClient<TokenResponse, FormData>({
      url: TOKEN_PATH,
      method: 'POST',
      data: formData,
    })
    setCookieTokens(res.data)
    const user = await getCurrentViewer()
    return user as User
  } catch (err) {
    const error = err as AxiosError<any>
    const message = error.response?.data?.message

    if (message === 'The refresh token is invalid.') {
      window.history.pushState(null, '', '/admin#/login')
      window.location.reload()
      return Promise.reject({
        message,
      })
    }

    if (error.response?.status === 400) {
      return Promise.reject(t('login_page.invalid_email_or_password'))
    }

    if (error.response?.status === 401) {
      return Promise.reject('login_page.user_not_activated')
    }

    return Promise.resolve()
  }
}
