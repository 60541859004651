import Mock from 'mockjs'
import { rest } from 'msw'
import { addBaseURL, addDefaultHeaders, getListSize } from '../helpers'

export const wallets = [
  rest.get(addBaseURL('/wallets'), (req, res, ctx) => {
    return res(
      addDefaultHeaders,
      ctx.status(200),
      ctx.json(
        Mock.mock({
          '@context': '/contexts/Wallet',
          '@id': '/wallets',
          '@type': 'hydra:Collection',
          [getListSize(req.url.searchParams)]: [WALLET_ENTITY_MOCK],
          'hydra:totalItems': 100,
        })
      )
    )
  }),
  rest.get(addBaseURL('/wallets/:id'), (req, res, ctx) => {
    const id = req.params.id
    return res(
      ctx.status(200),
      ctx.json(
        Mock.mock({
          ...WALLET_ENTITY_MOCK,
          '@context': '/contexts/Wallet',
          'id|+1': `/wallets/${id}`,
          '@id|+1': `/wallets/${id}`,
        })
      )
    )
  }),
]

const WALLET_ENTITY_MOCK = {
  'id|+1': 1,
  '@id|+1': function () {
    return `/wallets/${this.id}`
  },
  '@type': 'Wallet',
  address: '@guid',
  company: /\/companies\/[0-9]/,
  user: /\/users\/[0-9]/,
  dateCreate: '@date("dd.MM.yyyy in HH:MM")',
  dateUpdate: '@date("dd.MM.yyyy in HH:MM")',
  privateKey: '@guid',
  'total|10-10000': 10,
} as { [key: string]: unknown }
