import { SaveButton, SaveButtonProps } from 'react-admin'
import cn from 'classnames'
import { useTranslate } from 'react-admin'

export const CustomSaveButton = ({
  className = '',
  label = 'OK',
  ...rest
}: SaveButtonProps) => {
  const t = useTranslate()

  return (
    <SaveButton
      variant='contained'
      size='medium'
      label={t(`toolbar.${label}`)}
      fullWidth
      icon={<></>}
      className={cn('max-w-[180px] tablet-small:w-full', {
        className,
      })}
      {...rest}
    />
  )
}
