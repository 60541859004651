import { FC } from 'react'
import { CreateBase, CreateProps, useNotify } from 'react-admin'
import {
  ReferralSystemForm,
  preSubmitTransformReferralSystemData,
} from 'entities/referral-systems'
import { serverSideReferralSystemsValidation } from 'entities/referral-systems'
import { useTranslate } from 'react-admin'

const ReferralSystemCreate: FC<CreateProps> = props => {
  const t = useTranslate()

  const notify = useNotify()
  return (
    <CreateBase
      {...props}
      transform={preSubmitTransformReferralSystemData}
      mutationOptions={{
        onError: serverSideReferralSystemsValidation(notify),
      }}
      redirect='list'
    >
      <p className='mt-10 text-black text mb-big'>
        {t('payout_building.create.add_payout_way')}
      </p>
      <ReferralSystemForm />
    </CreateBase>
  )
}

export default ReferralSystemCreate
