import { FC } from 'react'
import {
  ListBase,
  ListProps,
  Datagrid,
  FunctionField,
  TextField,
  DateField,
  Pagination,
  usePermissions,
  useAuthenticated,
} from 'react-admin'
import {
  getUserRole,
  ROLE_ADMIN,
  ROLE_NAME_BY_IDENTIFIER,
  ROLE_USER,
  ROLE_COMPANY,
  User,
} from 'entities/users'
import {
  CustomActionList,
  CustomTextInput,
  CustomSelectInput,
  CustomActionButtons,
  CustomAutoCompleteInput,
} from 'shared/ui'
import { getNumberFromString } from 'shared/helpers'
import { Helmet } from 'react-helmet'
import { useMediaTheme } from 'shared/hooks'
import { Permissions } from 'features/auth/basic'
import { UserMobile } from './user-mobile'
import { SORT_DESC } from 'shared/config'
import { useTranslate } from 'react-admin'

const UserList: FC<ListProps> = props => {
  const t = useTranslate()

  useAuthenticated()
  const { permissions } = usePermissions<Permissions>()
  const { isMinMedium } = useMediaTheme()

  if (!permissions) return null

  return (
    <ListBase
      {...props}
      filterDefaultValues={{
        deletedAt: false,
      }}
      sort={{ field: 'id', order: SORT_DESC }}
      perPage={25}
    >
      <Helmet>
        <title>{t('users.users_title')}</title>
      </Helmet>
      <CustomActionList
        title={t('users.users_title')}
        withCreateButton={false}
        withRoleUserCompany
        filters={[
          <CustomTextInput key='id' label='users.filter.id' source='id' />,
          <CustomSelectInput
            key='roles'
            label='users.filter.role'
            source='roles'
            emptyText={t('users.filter.all')}
            className='tablet-small:!w-[160px]'
            choices={[
              {
                id: ROLE_ADMIN,
                name: t(`users.roles.${ROLE_NAME_BY_IDENTIFIER[ROLE_ADMIN]}`),
              },
              {
                id: ROLE_COMPANY,
                name: t(`users.roles.${ROLE_NAME_BY_IDENTIFIER[ROLE_COMPANY]}`),
              },
              {
                id: ROLE_USER,
                name: t(`users.roles.${ROLE_NAME_BY_IDENTIFIER[ROLE_USER]}`),
              },
            ]}
          />,
          <CustomTextInput
            key='email'
            label='users.filter.email'
            source='email'
          />,
          <CustomAutoCompleteInput
            key='companies'
            emptyText='All'
            label='users.filter.company'
            optionText='name'
            source='company.id'
            reference='companies'
            className='!w-[160px]'
            link={false}
            filterToQuery={value => ({ name: value })}
          />,
        ]}
      />
      {isMinMedium ? (
        <UserMobile />
      ) : (
        <Datagrid>
          <FunctionField
            label='users.table.id'
            source='id'
            render={({ id }: User) => getNumberFromString(String(id))}
          />
          <FunctionField
            label='users.table.role'
            source='roles'
            sortable={false}
            render={({ roles }: User) =>
              getUserRole(roles) && t(`users.roles.${getUserRole(roles)}`)
            }
          />
          <TextField
            label='users.table.email'
            source='email'
            sortable={false}
          />
          <FunctionField
            label='users.table.company'
            source='company'
            sortable={false}
            render={({ company }: User) => company?.name}
          />
          <DateField
            label='users.table.creation_date'
            source='dateCreate'
            options={{ month: 'numeric', day: 'numeric', year: 'numeric' }}
          />
          <CustomActionButtons<User>
            hasDelete={false}
            ShowButtonProps={{
              title: t('users.show.user_view'),
              showData: record => [
                {
                  label: t('users.show.role'),
                  value: getUserRole(record.roles),
                },
                {
                  label: t('users.show.company_name'),
                  value: (
                    <FunctionField
                      label={t('users.table.company')}
                      source='company'
                      render={({ company }: User) => company?.name}
                    />
                  ),
                },
                { label: t('users.show.email'), value: record.email },
                {
                  label: t('users.show.external_login_account'),
                  value: record.externalUsername,
                },
              ],
            }}
          />
        </Datagrid>
      )}
      <Pagination />
    </ListBase>
  )
}

export default UserList
