import { FC } from 'react'
import {
  Button,
  Dialog,
  DialogProps,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import cn from 'classnames'

export interface DialogAlertProps extends DialogProps {
  text?: string
  closeButtonText?: string
  onClose: () => void
}

export const DialogAlert: FC<DialogAlertProps> = ({
  text,
  open,
  onClose,
  closeButtonText = 'OK',
  className = '',
  ...rest
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      className={cn('', {
        [className]: className,
      })}
      {...rest}
    >
      <DialogContent
        dividers
        className='flex flex-col items-center gap-10 min-w-[460px] !p-big tablet:min-w-[200px]'
      >
        <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
          <p className='text-center text-black text-title'>{text}</p>
        </DialogContentText>
        <Button
          variant='contained'
          color='primary'
          onClick={onClose}
          size='large'
          className='w-40'
        >
          {closeButtonText}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
