import {
  useAuthenticated,
  IconButtonWithTooltip,
  useNotify,
  usePermissions,
} from 'react-admin'
import { Permissions } from 'features/auth/basic'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { ROLE_ADMIN } from 'entities/users'
import { useQuery } from 'react-query'
import { COMPANT_KEYS_PRIMARY_KEY, getCompanyKeys } from 'entities/company'
import {
  WALLETS_COLLECTION_PRIMARY_KEY,
  getWalletsCollection,
} from 'entities/transactions'
import { Button } from '@mui/material'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { INSTRUCTION_PRIMARY_KEY } from '../lib'
import { getInstruction } from '../model'
import { BASE_URL } from 'shared/config'
import { useTranslate } from 'react-admin'

export const SettingsPage = () => {
  const t = useTranslate()

  useAuthenticated()
  const { permissions } = usePermissions<Permissions>()
  const notify = useNotify()
  const isRoleNoAdmin = !!(
    permissions?.roles && !permissions.roles.includes(ROLE_ADMIN)
  )
  const { data } = useQuery(COMPANT_KEYS_PRIMARY_KEY, getCompanyKeys, {
    enabled: isRoleNoAdmin,
    select: data => data.data,
  })

  const { data: wallet } = useQuery(
    WALLETS_COLLECTION_PRIMARY_KEY,
    getWalletsCollection,
    {
      enabled: isRoleNoAdmin,
      select: data => data.data['hydra:member'][0],
    }
  )

  const { data: instruction } = useQuery(
    INSTRUCTION_PRIMARY_KEY,
    getInstruction,
    {
      retry: false,
      enabled: isRoleNoAdmin,
      select: data => data.data.instruction,
    }
  )

  const publicKey = data?.publicKey
  const privateKey = data?.privateKey
  const instructionPath = `${BASE_URL}/uploads/${instruction?.path}`

  return (
    <div className='flex flex-col'>
      {/* TODO: Понадобится в будущем */}
      {/* <Form
        validationSchema={companySettingsSchema}
        className={
          'grid grid-cols-[repeat(2,_316px)] gap-x-5 gap-y-10 mb-5 tablet:grid-cols-1'
        }
      >
        {({ control }) => (
          <>
            <Controller
              name='language'
              control={control}
              render={({ field }) => (
                <CustomMuiSelect
                  label='Language'
                  fullWidth
                  choices={[
                    {
                      id: 'en',
                      name: 'English',
                    },
                  ]}
                  {...field}
                  inputRef={field.ref}
                />
              )}
            />
            <Button
              type='submit'
              variant='contained'
              size='large'
              className='!text-white w-button'
            >
              Save
            </Button>
          </>
        )}
      </Form> */}
      <div className='flex gap-3 mb-5 items-center'>
        <p className='text-black'>{t('settings.integration_instruction')}</p>
        {instructionPath ? (
          <a download target='_blank' rel='noreferrer' href={instructionPath}>
            <Button className='gap-2' variant='outlined'>
              <DownloadForOfflineIcon />
              {t('settings.download')}
            </Button>
          </a>
        ) : (
          <p className='text-black'> {t('settings.none')}</p>
        )}
      </div>
      {wallet && (
        <p className='mb-5 text-black'>
          {!wallet.activated
            ? t('settings.company_wallet_no_activated')
            : t('settings.company_wallet_activated')}
        </p>
      )}
      {publicKey && (
        <div className='flex items-center gap-1'>
          <p className='text-black break-all'>
            <span className='font-bold'>{t('settings.public_key')}</span>{' '}
            {publicKey}
          </p>
          <IconButtonWithTooltip
            className='!p-2'
            label='settings.copy'
            size='small'
            onClick={() =>
              navigator.clipboard
                .writeText(publicKey)
                .then(() => notify(t('dialog.copied'), { type: 'success' }))
            }
          >
            <ContentCopyIcon color='primary' className='!text-title' />
          </IconButtonWithTooltip>
        </div>
      )}
      {privateKey && (
        <div className='flex items-center gap-1'>
          <p className='text-black break-all'>
            <span className='font-bold'>{t('settings.private_key')}</span>{' '}
            {privateKey}
          </p>
          <IconButtonWithTooltip
            className='!p-2'
            label='settings.copy'
            size='small'
            onClick={() =>
              navigator.clipboard
                .writeText(privateKey)
                .then(() => notify(t('dialog.copied'), { type: 'success' }))
            }
          >
            <ContentCopyIcon color='primary' className='!text-title' />
          </IconButtonWithTooltip>
        </div>
      )}
    </div>
  )
}
