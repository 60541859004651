import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Button } from '@mui/material'
import { Form } from 'shared/libs'
import { CustomTextInput } from 'shared/ui'
import { codeSchema } from '../lib'
import { ButtonWithDelay } from 'shared/ui/button-with-delay'
import { useAuthenticated } from 'react-admin'

export const SettingsCodeForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  useAuthenticated()
  return (
    <Form
      validationSchema={codeSchema}
      onSubmit={() => navigate('/settings')}
      className='flex flex-col'
    >
      {() => (
        <>
          <p className='text-black text-title mb-big'>Change phone number</p>
          <p className='text-subtext text-gray mb-big'>
            {`Code has been sent to the number ${location.state}, enter it to
            confirm`}
          </p>
          <CustomTextInput
            name='code'
            source='code'
            label='Enter SMS code'
            className='!mb-big'
            InputProps={{ className: 'w-input' }}
          />
          <ButtonWithDelay
            label='Send the code again'
            labelAfterDelay='Send again'
            delay={60}
            className='w-64 !mb-[50px]'
          />
          <div className='flex items-center gap-base'>
            <Link to='/settings/phone'>
              <Button
                color='secondary'
                variant='contained'
                size='large'
                className='w-button'
              >
                Cancel
              </Button>
            </Link>
            <Button
              type='submit'
              variant='contained'
              size='large'
              className=' w-button'
            >
              Send
            </Button>
          </div>
        </>
      )}
    </Form>
  )
}
