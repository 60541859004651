import Cookies from 'universal-cookie'
import { Identify, LoginData } from './types'
import { AuthProvider } from 'react-admin'
import { ROLE_DEFAULT } from 'entities/users'
import { AxiosError } from 'axios'
import { Permissions } from 'features/auth/basic'
import { HydraAxiosError, Nullable } from 'shared/@types'
import { TOKEN_PATH } from 'shared/config'
import {
  getTokens,
  logout,
  RequestQueue,
  requestTokens,
} from 'shared/http-client'
import { auth } from 'shared/auth-request'

let requestQueue: RequestQueue[] = []

export default {
  login: async ({ username, password }: LoginData) => {
    return await auth(username, password)
  },
  checkAuth: async () => {
    const refresh_token = getTokens().refresh_token
    if (refresh_token && localStorage.getItem('referral_admin_current_user')) {
      return Promise.resolve()
    }
    return Promise.reject()
  },
  checkError: async (error: AxiosError) => {
    const cookies = new Cookies()

    const status = error.response?.status

    const refresh_token = getTokens().refresh_token

    if (status === 401) {
      if (refresh_token) {
        if (!error.config?.url?.includes(TOKEN_PATH))
          new Promise((resolve, reject) =>
            requestQueue.push({ resolve, reject, config: error.config! })
          )

        cookies.remove('user_access_token', { path: '/' })
        await requestTokens(refresh_token, requestQueue)
        requestQueue = []
        return Promise.resolve()
      } else return Promise.reject({ ...error, logoutUser: true })
    }

    if (status === 403) return Promise.reject({ message: 'No access!' })

    const errorMessage = (error as HydraAxiosError).response?.data[
      'hydra:description'
    ]
    if (errorMessage) {
      return Promise.reject({ message: errorMessage, logoutUser: false })
    }

    return Promise.reject({ ...error, logoutUser: false })
  },
  getPermissions: async () => {
    try {
      const identity = JSON.parse(
        localStorage.getItem('referral_admin_current_user') || 'null'
      ) as Nullable<Identify>

      if (
        identity?.user &&
        (identity.user.roles?.length || 0) < 2 &&
        identity.user.roles[0] === ROLE_DEFAULT
      ) {
        logout()
        return Promise.reject({
          message:
            'It is necessary to activate the account using the link from the email that was sent after registration',
        })
      }
      return Promise.resolve<Permissions>({
        roles: identity!.user!.roles,
        company: identity!.user!.company,
      })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  getIdentity: async () => {
    try {
      return Promise.resolve<Nullable<Identify>>(
        JSON.parse(
          localStorage.getItem('referral_admin_current_user') || 'null'
        )
      )
    } catch (error) {
      return Promise.reject(error)
    }
  },
  logout: () => {
    logout()
    return Promise.resolve()
  },
} as AuthProvider
