import {
  REFERRAL_EVENT_TYPE_PAYOUT,
  REFERRAL_EVENT_TYPE_WEB_HOOK,
} from './constants'
import { getDeepClone } from 'shared/helpers'
import { ReferralSystems, ReferralSystemsFormData } from '../model'
import { ReferralEvent } from '../model'

export const preSubmitTransformReferralSystemData = (
  data: ReferralSystemsFormData
) => {
  const clone = getDeepClone(data) as Omit<ReferralSystemsFormData, 'company'>
  //@ts-expect-error
  clone.company = data.company['@id']
  delete clone.compensation
  clone.referralEvents = clone.customReferralEvents
  delete clone.customReferralEvents
  let compensation = {}
  if (clone.referralEvents) {
    compensation = clone.referralEvents[clone.referralEvents.length]
  } else {
    clone.referralEvents = []
  }
  clone.referralEvents = clone.referralEvents.map((event, index) => ({
    ...event,
    id: event['@id'],
    sum: event['hasCost'] ? event['sum'] : 0,
    orderNumber: index,
    type: REFERRAL_EVENT_TYPE_WEB_HOOK,
  }))
  clone.referralEvents.unshift({
    ...compensation,
    name: 'Compensation',
    sum: data.compensation,
    type: REFERRAL_EVENT_TYPE_PAYOUT,
  })
  return clone
}

export function findConpenstionEvent(events: ReferralEvent[]): ReferralEvent {
  return events.find(event => event.type === REFERRAL_EVENT_TYPE_PAYOUT)!
}

export function transformReferralEvents(
  events: ReferralEvent[]
): ReferralEvent[] {
  return events
    .reduce((acc, event) => {
      if (event.type === REFERRAL_EVENT_TYPE_PAYOUT) return acc
      return [...acc, { ...event, hasCost: !!event.sum }]
    }, [] as ReferralEvent[])
    .sort((eventA, eventB) => (eventA > eventB ? 1 : -1))
}

export const preEditNormalizeReferralSystemData = (record?: ReferralSystems) =>
  record &&
  ({
    ...record,
    customReferralEvents: transformReferralEvents(record.referralEvents),
    compensation: record.referralEvents.find(
      event => event.type === REFERRAL_EVENT_TYPE_PAYOUT
    )?.sum,
  } as ReferralSystems)
