import { RaThemeOptions } from 'react-admin'
export default {
  palette: {
    primary: {
      main: 'rgb(var(--primary))',
      contrastText: 'rgb(var(--white))',
    },
    secondary: {
      main: 'rgb(var(--secondary))',
      contrastText: 'rgb(var(--black))',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        prefix: 'RaSidebar',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          '& .MuiPaper-root': {
            width: ownerState.open ? '250px !important' : '68px !important',
            boxShadow:
              '0px 9px 18px rgba(0, 0, 0, 0.18), 0px 5.5px 5px rgba(0, 0, 0, 0.24)',
          },
        }),
      },
    },
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          '& .RaBulkActionsToolbar-toolbar': {
            color: 'rgb(var(--white))',
            background: 'rgb(var(--primary)) !important',
          },
          '& .RaBulkActionsToolbar-icon': {
            color: 'rgb(var(--white))',
          },
        },
      },
    },
    RaFilterFormInput: {
      // -hideButton
      styleOverrides: {
        root: {
          alignItems: 'center',
          gap: '8px',
          '& .RaFilterFormInput-hideButton': {
            marginBottom: '0 !important',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          flexShrink: 0,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingTop: '0px !important',
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          background: 'rgb(var(--primary)) !important',
        },
      },
    },
    RaSimpleFormIterator: {
      styleOverrides: {
        root: {
          '& .RaSimpleFormIterator-line .RaSimpleFormIterator-indexContainer p':
            {
              display: 'flex !important',
            },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: 64,
          justifyContent: 'center',
          '& .RaAppBar-toolbar': {
            paddingRight: '30px !important',
          },
          '& .RaAppBar-menuButton': {
            width: 36,
            height: 36,
            marginLeft: '22px !important',
            marginRight: '22px !important',
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          background: 'rgba(0, 0, 0, 0.65)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        outlined: {
          border: '1px solid rgb(var(--primary))',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0 0 40px 0',
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          '& .RaLayout-content': {
            padding: '40px 106px 40px 0',
            '@media(max-width:500px)': {
              padding: '40px 40px 40px 0',
            },
          },
          '& .RaLayout-contentWithSidebar': {
            gap: 40,
            '@media(max-width:400px)': {
              gap: 20,
            },
            '& .RaMenu-open': {
              width: '228px',
            },
            '& .RaMenu-closed': {
              width: '78px',
            },
          },
          '& .RaLayout-appFrame': {
            marginTop: 64,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 48,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.column-undefined': {
            width: 80,
          },
        },
        head: {
          fontWeight: 700,
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          paddingRight: 20,
          paddingLeft: 30,
          paddingTop: 13,
          paddingBottom: 13,
          gap: 12,
          '& .RaMenuItemLink-icon': {
            alignItems: 'center',
            justifyContent: 'center',
            width: 38,
            height: 38,
            minWidth: 'unset',
          },
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          height: 'calc(100vh - 64px)',
        },
      },
    },
  },
} as RaThemeOptions
