import Mock from 'mockjs'
import { rest } from 'msw'
import qs from 'qs'
import { addBaseURL, addDefaultHeaders, getListSize } from '../helpers'

const COMPANY_ENTITY_MOCK = {
  'id|+1': 1,
  '@id|+1': function () {
    return `/companies/${this.id}`
  },
  '@type': 'Company',
  name: '@title(1,3)',
  description: '@sentence(3, 5)',
  siteUrl: '@url',
} as { [key: string]: unknown }

export const companies = [
  rest.get(addBaseURL('/companies'), (req, res, ctx) => {
    const ids = qs.parse(req.url.search).id

    if (ids) {
      return res(
        ctx.status(200),
        ctx.json(
          Mock.mock({
            '@context': '/contexts/Company',
            '@id': '/companies',
            '@type': 'hydra:Collection',
            'hydra:member': (ids as string[]).map(id => ({
              ...COMPANY_ENTITY_MOCK,
              'id|+1': id,
              '@id|+1': id,
            })),
            'hydra:totalItems': ids.length,
          })
        )
      )
    }

    return res(
      addDefaultHeaders,
      ctx.status(200),
      ctx.json(
        Mock.mock({
          '@context': '/contexts/Company',
          '@id': '/companies',
          '@type': 'hydra:Collection',
          [getListSize(req.url.searchParams)]: [COMPANY_ENTITY_MOCK],
          'hydra:search': {
            '@type': 'hydra:IriTemplate',
            'hydra:template': '/companies{id,id[]}',
            'hydra:variableRepresentation': 'BasicRepresentation',
            'hydra:mapping': [
              {
                '@type': 'IriTemplateMapping',
                variable: 'id',
                property: 'id',
                required: false,
              },
              {
                '@type': 'IriTemplateMapping',
                variable: 'id[]',
                property: 'id',
                required: false,
              },
            ],
          },
          'hydra:totalItems': 100,
        })
      )
    )
  }),
]
