import { createTheme, useMediaQuery } from '@mui/material'

export const useMediaTheme = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 400,
        sm: 600,
        md: 820,
        lg: 1200,
        xl: 1536,
      },
    },
  })
  const isSmallMax = useMediaQuery(theme.breakpoints.down('xs'))
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMinMedium = useMediaQuery(theme.breakpoints.down('md'))
  const isMaxMedium = useMediaQuery(theme.breakpoints.down('lg'))
  const isBig = useMediaQuery(theme.breakpoints.down('xl'))
  return { isSmall, isMinMedium, isMaxMedium, isBig, isSmallMax }
}
