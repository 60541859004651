import {
  useListContext,
  IconButtonWithTooltip,
  useDeleteMany,
  useNotify,
} from 'react-admin'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import { FC } from 'react'

export interface CustomSelectedBarProps {
  withDelete?: boolean
}

export const CustomSelectedBar: FC<CustomSelectedBarProps> = ({
  withDelete = true,
}) => {
  const { selectedIds, onUnselectItems, resource } = useListContext()
  const notify = useNotify()
  const [deleteMany] = useDeleteMany(resource, { ids: selectedIds })
  return (
    <div className='flex justify-between w-full mb-3 text-white rounded-md bg-primary'>
      <div className='flex items-center ml-1'>
        <IconButtonWithTooltip
          label='Unselect'
          onClick={() => onUnselectItems()}
        >
          <CloseIcon className='text-white' />
        </IconButtonWithTooltip>
        {selectedIds.length} items selected
      </div>
      {withDelete && (
        <div className='flex items-center mr-5 text-center'>
          <IconButtonWithTooltip
            label='Delete'
            onClick={() => {
              deleteMany()
                .then(() =>
                  notify('Element success delete', { type: 'success' })
                )
                .catch(e => notify(e.message, { type: 'error' }))
              onUnselectItems()
            }}
          >
            <div className='text-sm text-error'>
              <DeleteIcon className='text-error' />
              Delete
            </div>
          </IconButtonWithTooltip>
        </div>
      )}
    </div>
  )
}
