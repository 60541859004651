import {
  IconButtonWithTooltipProps,
  IconButtonWithTooltip,
  useRecordContext,
  RaRecord,
} from 'react-admin'
import { DialogDataRow, DialogShowPage } from 'shared/ui'
import { Button, ButtonPropsVariantOverrides } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import cn from 'classnames'
import { useModalState } from 'shared/hooks'

export interface CustomWithDrawButtonProps<T>
  extends Partial<IconButtonWithTooltipProps> {
  title?: string
  showData?: (record: T) => DialogDataRow[]
  variant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >
  className?: string
}

export const CustomWithdrawButton = <T extends RaRecord>({
  title,
  showData,
  label = 'Withdraw',
  variant,
  className = '',
  ...rest
}: CustomWithDrawButtonProps<T>) => {
  const record = useRecordContext() as T
  const { isOpen, onToggle } = useModalState()
  return (
    <>
      <IconButtonWithTooltip {...rest} label={label} onClick={onToggle}>
        <Button
          variant={variant}
          className={cn('w-[100px]', { [className]: className })}
        >
          {label}
        </Button>
      </IconButtonWithTooltip>
      <DialogShowPage
        withDrawLabel={label}
        withDraw
        open={isOpen}
        onClose={onToggle}
        title={title}
        showData={showData?.(record) || []}
      />
    </>
  )
}
