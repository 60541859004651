import { forwardRef, useState } from 'react'
import { InputAdornment, IconButton } from '@mui/material'
import { TextInput, TextInputProps } from 'react-admin'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import cn from 'classnames'

export interface CustomPasswordInputProps extends TextInputProps {
  label?: string
}

export const CustomPasswordInput = forwardRef<
  HTMLInputElement,
  CustomPasswordInputProps
>(({ className = '', source = 'password', ...rest }, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <TextInput
      type={showPassword ? 'text' : 'password'}
      variant='standard'
      size='medium'
      helperText={false}
      source={source}
      {...rest}
      InputProps={{
        ...rest.InputProps,
        ref,
        className: cn('h-9', {
          [rest.InputProps?.className || '']: rest.InputProps?.className,
        }),
        endAdornment: (
          <InputAdornment position='end' className='mr-base'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword(prev => !prev)}
              edge='end'
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      className={cn({
        [className]: className,
      })}
    />
  )
})
