import { FC } from 'react'
import { SelectInput, SelectInputProps } from 'react-admin'
import SearchIcon from '@mui/icons-material/Search'
import cn from 'classnames'
export interface CustomSelectInputProps extends SelectInputProps {
  withSearch?: boolean
}

export const CustomSelectInput: FC<CustomSelectInputProps> = ({
  className = '',
  withSearch,
  ...rest
}) => {
  return (
    <SelectInput
      variant='filled'
      {...rest}
      className={cn('!my-0', {
        [className]: className,
      })}
      FormHelperTextProps={{
        ...rest.FormHelperTextProps,
        className: cn('!mt-0', {
          [rest.FormHelperTextProps?.className || '']:
            rest.FormHelperTextProps?.className,
        }),
      }}
      InputProps={{
        ...rest.InputProps,
        className: cn('h-14 w-[180px] tablet-small:!w-[160px]', {
          [rest.InputProps?.className || '']: rest.InputProps?.className,
        }),
        endAdornment: withSearch && (
          <SearchIcon color='disabled' className='cursor-pointer' />
        ),
      }}
      InputLabelProps={{
        ...rest.InputLabelProps,
        className: cn('!top-1', {
          [rest.InputLabelProps?.className || '']:
            rest.InputLabelProps?.className,
        }),
      }}
    />
  )
}
