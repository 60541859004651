import { FC } from 'react'
import {
  ListBase,
  ListProps,
  Datagrid,
  TextField,
  Pagination,
  DateField,
  usePermissions,
  useAuthenticated,
  BooleanInput,
} from 'react-admin'
import {
  ReferralSystems,
  transformReferralEvents,
  findConpenstionEvent,
} from 'entities/referral-systems'
import {
  CustomActionList,
  CustomTextInput,
  CustomActionButtons,
  DialogAlert,
} from 'shared/ui'
import { SORT_DESC } from 'shared/config'
import { useModalState } from 'shared/hooks'
import { Helmet } from 'react-helmet'
import { ROLE_ADMIN, ROLE_COMPANY } from 'entities/users'
import { Permissions } from 'features/auth/basic'
import { useMediaTheme } from 'shared/hooks'
import { ReferralSystemsMobile } from './referral-system-mobile'
import { CURRENCY_NAME } from 'entities/company'
import { useTranslate } from 'react-admin'

const ReferralSystemsList: FC<ListProps> = props => {
  const t = useTranslate()

  useAuthenticated()
  const { isOpen, onToggle } = useModalState()
  const { permissions } = usePermissions<Permissions>()
  const { isMinMedium } = useMediaTheme()

  if (!permissions) return null
  const isAdmin = permissions.roles?.includes(ROLE_ADMIN)

  return (
    <>
      <ListBase
        {...props}
        filterDefaultValues={{
          isArchive: false,
          deletedAt: false,
        }}
        sort={{ field: 'dateCreate', order: SORT_DESC }}
        perPage={25}
      >
        <Helmet>
          <title>{t('payout_building.payout_building_title')}</title>
        </Helmet>
        <CustomActionList
          withCreateButton={data =>
            !(permissions?.roles.includes(ROLE_COMPANY) && data?.length)
          }
          withRoleUserCompany
          title={t('payout_building.payout_building_title')}
          filters={[
            <CustomTextInput
              key='name'
              label='payout_building.filter.way_name'
              source='name'
            />,
            <CustomTextInput
              key='referralEventsCount'
              label='payout_building.filter.steps'
              source='referralEventsCount'
            />,

            <CustomTextInput
              key='referralEventsSum'
              label={
                t('payout_building.filter.compensation') + `, ${CURRENCY_NAME}`
              }
              source='referralEventsSum'
            />,
            <BooleanInput
              key='isBlocked'
              label='payout_building.filter.blocked'
              source='isBlocked'
            />,
          ]}
          CreateButtonProps={{
            label: 'Add payout way',
            onAdd: data =>
              data?.length && data.length > 1 && !isAdmin
                ? onToggle
                : undefined,
          }}
        />
        {isMinMedium ? (
          <ReferralSystemsMobile />
        ) : (
          <Datagrid>
            <TextField
              label='payout_building.table.way_name'
              source='name'
              sortable={false}
            />
            <TextField
              label='payout_building.table.steps'
              source='referralEventsCount'
              sortable={false}
            />
            <TextField
              label={
                t('payout_building.table.compensation') + `, ${CURRENCY_NAME}`
              }
              source='referralEventsSum'
              sortable={false}
            />
            <DateField
              label='payout_building.table.creation_date'
              source='dateCreate'
              options={{
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
              }}
            />
            {isAdmin && (
              <TextField
                label='payout_building.table.company'
                source='company.name'
                sortable={false}
              />
            )}
            <CustomActionButtons<ReferralSystems>
              ShowButtonProps={{
                title: t('payout_building.show.payout_way_view'),
                showData: record => [
                  {
                    label: t('payout_building.show.way_name'),
                    value: record.name,
                  },
                  {
                    label: t('payout_building.show.life_span'),
                    value: <p>{`${record.sessionLifeSpan} h.`}</p>,
                  },
                  ...transformReferralEvents(record.referralEvents).map(
                    (event, i) => ({
                      label: `${t('payout_building.show.step')} ${++i}`,
                      value: (
                        <div className='flex flex-col gap-0.5'>
                          <p className='text-black'>{event.name}</p>
                          {event.isNegation && (
                            <p className='text-gray'>
                              {t(
                                'payout_building.show.condition_must_not_be_met'
                              )}
                            </p>
                          )}
                          <a href={event.webhookUrl}>{event.webhookUrl}</a>
                          {!!event.sum && (
                            <p className='text-gray'>
                              {t('payout_building.show.cost') +
                                `, ${CURRENCY_NAME} = ${event.sum}`}
                            </p>
                          )}
                        </div>
                      ),
                    })
                  ),
                  {
                    label: t('payout_building.show.compensation'),
                    value:
                      String(
                        findConpenstionEvent(record.referralEvents)?.sum
                      ) || '0',
                  },
                ],
              }}
            />
          </Datagrid>
        )}
        <Pagination />
      </ListBase>
      <DialogAlert
        open={isOpen}
        onClose={onToggle}
        text={t('payout_building.alert.createdAlready')}
      />
    </>
  )
}

export default ReferralSystemsList
