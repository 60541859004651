import { Button } from '@mui/material'
import { CustomPasswordInput, CustomTextField, PreAdminLayout } from 'shared/ui'
import { useLogin, useNotify } from 'react-admin'
import { Form } from 'shared/libs'
import { LoginData, loginSchema } from 'features/auth/basic'
import { Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { useTranslate } from 'react-admin'
import { LocaleSwitcher } from 'features/locale-switcher'

export const LoginPage = () => {
  const t = useTranslate()

  const login = useLogin()
  const notify = useNotify()

  return (
    <PreAdminLayout>
      <Form<LoginData>
        validationSchema={loginSchema(t)}
        onSubmit={data => login(data).catch(e => notify(e, { type: 'error' }))}
        className={`flex flex-col justify-center
      w-[306px] bg-white px-big pb-[22px] pt-5 rounded`}
      >
        {({ control, isLoading }) => (
          <>
            <CustomTextField
              name='username'
              variant='standard'
              label={t('login_page.login')}
              className='!mb-base'
            />
            <Controller
              name='password'
              control={control}
              render={({ field }) => (
                <CustomPasswordInput
                  label='login_page.password'
                  source='password'
                  className='!mb-big'
                  {...field}
                />
              )}
            />
            <LoadingButton
              type='submit'
              variant='contained'
              size='large'
              className='h-10 !mb-3'
              loading={isLoading}
            >
              {t('login_page.log_in')}
            </LoadingButton>
            <Link to='/registration' className='contents'>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                className='h-10'
              >
                {t('login_page.create_account')}
              </Button>
            </Link>
            <Link to='/reset-password' className='contents'>
              <Button variant='text' size='large' className='h-10 !mt-1'>
                {t('login_page.forgot_password')}
              </Button>
            </Link>
            <LocaleSwitcher className='!text-black' />
          </>
        )}
      </Form>
    </PreAdminLayout>
  )
}
