import { en } from './locale'
import { ru } from './locale'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { TranslationMessages } from 'ra-core'
import { DefaultUserBrowserLanguage } from 'shared/config'

const translations = { en, ru } as Record<string, TranslationMessages>

export const i18nProvider = polyglotI18nProvider(
  locale => translations[locale],
  DefaultUserBrowserLanguage,
  [{ locale: 'ru' }, { locale: 'en' }]
)
