import { rest } from 'msw'
import { addBaseURL, addDefaultHeaders } from '../helpers'

export const initial = [
  rest.get(addBaseURL('/'), (_, res, ctx) => {
    return res(
      addDefaultHeaders,
      ctx.status(200),
      ctx.json({
        '@context': '/contexts/Entrypoint',
        '@id': '/',
        '@type': 'Entrypoint',
        company: '/companies',
        companyAuthModel: '/company_auth_models',
        confirmCode: '/confirm_codes',
        currentBalanceArbitratorModel: '/balance/arbitrator/current',
        currentBalanceModel: '/balance/current',
        file: '/files',
        referralEvent: '/referral_events',
        referralSession: '/referral_sessions',
        referralSystem: '/referral_systems',
        referralWebHookModel: '/referral_web_hook_models',
        innerTransaction: '/transactions',
        externalTransaction: '/external_transactions',
        user: '/users',
        wallet: '/wallets',
      })
    )
  }),
]
