import { AxiosRequestConfig } from 'axios'
import { Notify, ServerSideValidator } from 'shared/@types'

export interface OnSubmitProps<
  T,
  R extends (config: AxiosRequestConfig<T>) => ReturnType<R>
> {
  submitRequest: R
  notify?: Notify
  onSuccess?: (formData: T, response: ReturnType<R>) => Promise<void> | void
  onError?: ServerSideValidator
}

export const onSubmit =
  <T, R extends (config: AxiosRequestConfig<T>) => ReturnType<R>>(data: T) =>
  async ({
    submitRequest,
    notify,
    onSuccess,
    onError,
  }: OnSubmitProps<T, R>) => {
    try {
      const response = await submitRequest({ data })
      onSuccess?.(data, response)
    } catch (error) {
      onError?.(notify)(error)
    }
  }
