import { Nullable } from 'shared/@types'
import { ReferralEvent, ReferralStep } from 'entities/referral-systems'

export const DEFAULT_REFERRAL_STEP: ReferralStep = {
  isNegation: false,
  hasCost: false,
  name: '',
  webhookUrl: '',
  sum: 0,
}

export const normalizeReferralSteps = (
  referralEvents?: ReferralEvent[]
): Nullable<ReferralStep[]> => {
  if (!referralEvents) {
    return null
  }

  return referralEvents.map(referralStep => ({
    name: referralStep.name,
    isNegation: referralStep.isNegation,
    cost: referralStep.sum,
    webhookUrl: referralStep.webhookUrl,
    hasCost: !!referralStep.sum,
  }))
}
