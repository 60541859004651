import { useState } from 'react'

export const useModalState = () => {
  const [condition, setCondition] = useState(false)

  const onOpen = () => setCondition(true)

  const onClose = () => setCondition(false)

  const onToggle = () => setCondition(prev => !prev)

  return { isOpen: condition, onOpen, onClose, onToggle }
}
