import { FC } from 'react'
import { UserInfo } from '../model'
import { getUserRole } from '../lib'
import { DialogShowPage } from 'shared/ui'
import { useModalState } from 'shared/hooks'
import { Button } from '@mui/material'
import { getNumberFromString } from 'shared/helpers'
import { useTranslate } from 'react-admin'

export const UserInfoComponent: FC<UserInfo> = ({
  id,
  username,
  email,
  phone,
  roles,
  company,
  externalUsername,
}) => {
  const t = useTranslate()
  const { isOpen, onToggle } = useModalState()
  return (
    <>
      <Button
        variant='text'
        className={'!text-primary !p-0 !justify-start'}
        onClick={onToggle}
      >
        {externalUsername}
      </Button>
      <DialogShowPage
        withUserInfo
        open={isOpen}
        onClose={onToggle}
        title={t('users.show.user_view')}
        showData={[
          { label: 'id', value: getNumberFromString(String(id)) },
          { label: t('users.show.name'), value: username },
          { label: t('users.show.email'), value: email },
          { label: t('users.show.phone'), value: phone },
          {
            label: t('users.show.company_id'),
            value: getNumberFromString(company?.['@id'] as string),
          },
          { label: t('users.show.company_name'), value: company?.name },
          { label: t('users.show.role'), value: roles && getUserRole(roles) },
        ]}
      />
    </>
  )
}
