import { BalanceRequest, ROLE_ADMIN, getUserEnergy } from 'entities/users'
import {
  Identify,
  USERS_BALANCE_CURRENT_PATH,
  Permissions,
} from 'features/auth/basic'
import { useQuery } from 'react-query'
import { httpClient } from 'shared/http-client'
import {
  useGetIdentity,
  usePermissions,
  IconButtonWithTooltip,
  useNotify,
  useTranslate,
} from 'react-admin'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { CURRENCY_NAME } from 'entities/company'
import { useLocation } from 'react-router-dom'
import { USER_ENERGY_TARGET } from 'entities/users'
import { BalanceItem, Energy } from 'entities/users'
import { FC } from 'shared/@types'

export const Title = () => {
  const { permissions } = usePermissions<Permissions>()
  const isAdmin = permissions?.roles?.includes(ROLE_ADMIN)
  const { identity } = useGetIdentity()

  const { pathname } = useLocation()
  const isTransactions = pathname.includes('external_transactions')
  const { data: userEnergy } = useQuery(USER_ENERGY_TARGET, getUserEnergy, {
    enabled: isTransactions,
  })

  const { data: userBalance } = useQuery(
    USERS_BALANCE_CURRENT_PATH,
    () =>
      httpClient<BalanceRequest>({
        url: USERS_BALANCE_CURRENT_PATH,
      }),
    {
      enabled: !!(identity as Identify)?.user?.id && !isAdmin,
      select: data => data.data['hydra:member'][0],
    }
  )
  if (userBalance?.balance && userBalance.wallet)
    return <BalanceTitle userBalance={userBalance} />
  if (
    isTransactions &&
    userEnergy?.data.balanceTRX &&
    userEnergy.data.energyLeft &&
    userEnergy.data.energyMax
  )
    return <EnergyTitle userEnergy={userEnergy.data} />
  return <></>
}

interface EnergyTitleProps {
  userEnergy: Energy
}

const EnergyTitle: FC<EnergyTitleProps> = ({ userEnergy }) => {
  const t = useTranslate()
  const { balanceTRX, energyMax, energyLeft } = userEnergy
  return (
    <div className='flex flex-wrap items-center gap-x-4 laptop:text-subtext text-title'>
      <p className='whitespace-nowrap'>{`${t(
        'header.balance'
      )}: ${balanceTRX.toFixed(3)}`}</p>
      <div className='flex items-center gap-1'>
        <p className='whitespace-nowrap overflow-ellipsis'>{`${t(
          'header.energy'
        )}: ${energyLeft}/${energyMax}`}</p>
      </div>
    </div>
  )
}

interface BalanceTitleProps {
  userBalance: BalanceItem
}

const BalanceTitle: FC<BalanceTitleProps> = ({ userBalance }) => {
  const balance = userBalance.wallet.balance
  const wallet = userBalance.wallet.address
  const notify = useNotify()
  const t = useTranslate()

  return (
    <div className='flex flex-wrap items-center gap-x-4 laptop:text-subtext text-title'>
      {balance && (
        <p className='whitespace-nowrap'>{`${t(
          'header.balance'
        )}: ${balance} ${CURRENCY_NAME}`}</p>
      )}
      {wallet && (
        <div className='flex items-center gap-1'>
          <p className='whitespace-nowrap overflow-ellipsis'>{`${t(
            'header.wallet'
          )}: ${wallet}`}</p>
          <IconButtonWithTooltip
            label='Copy'
            size='small'
            onClick={() =>
              navigator.clipboard
                .writeText(wallet)
                .then(() => notify(t('dialog.copied'), { type: 'success' }))
                .catch(() =>
                  notify('Copy failed, please try again later!', {
                    type: 'error',
                  })
                )
            }
          >
            <ContentCopyIcon
              color='secondary'
              className='laptop:!text-subtext !text-title'
            />
          </IconButtonWithTooltip>
        </div>
      )}
    </div>
  )
}
