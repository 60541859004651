import { FC } from 'react'
import { IconButtonWithTooltip, useSimpleFormIteratorItem } from 'react-admin'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

export interface ReferralSystemRemoveButtonProps {
  className?: string
  onClick?: () => void
}

export const ReferralSystemRemoveButton: FC<
  ReferralSystemRemoveButtonProps
> = props => {
  const { index } = useSimpleFormIteratorItem()

  if (!index) {
    return null
  }

  return (
    <div {...props}>
      <IconButtonWithTooltip label='toolbar.remove' className='!p-0'>
        <AddCircleOutlineIcon className='rotate-45' />
      </IconButtonWithTooltip>
    </div>
  )
}
