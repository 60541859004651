import { HydraAxiosError, ServerSideValidator } from 'shared/@types'
import { Translate } from 'ra-core'
import {
  EMAIL_REG_EXP,
  PASSWORD_REQUIREMENTS_REG_EXP,
  PHONE_REG_EXP,
  URL_REG_EXP,
} from 'shared/config'
import * as yup from 'yup'

export const registrationSchema = (t: Translate) =>
  yup.object({
    company: yup.object().shape({
      name: yup.string().required(t('create_account.company_name_req')),
      description: yup
        .string()
        .required(t('create_account.company_description_req')),
      siteUrl: yup
        .string()
        .matches(URL_REG_EXP, t('create_account.url_site_invalid'))
        .required(t('create_account.url_site_req')),
    }),
    email: yup
      .string()
      .matches(EMAIL_REG_EXP, t('create_account.email_invalid'))
      .required(t('create_account.email_req')),
    firstName: yup.string().required(t('create_account.name_req')),
    lastName: yup.string().required(t('create_account.lastname_req')),
    phone: yup
      .string()
      .matches(PHONE_REG_EXP, t('create_account.phone_number_invalid'))
      .required(t('create_account.phone_number_req')),
    username: yup.string().required(t('create_account.login_req')),
    plainPassword: yup
      .string()
      .min(6, t('create_account.password_invalid'))
      .matches(
        PASSWORD_REQUIREMENTS_REG_EXP,
        t('create_account.password_must_contain')
      )
      .required(t('create_account.password_req')),
    repeatPassword: yup
      .string()
      .oneOf(
        [yup.ref('plainPassword')],
        t('create_account.repeat_password_must_match')
      )
      .required(t('create_account.repeat_password_req')),
    privacy: yup.array().min(1, t('create_account.personal_data_req')),
  })

export const serverSideUsersRegisterValidation: ServerSideValidator =
  (notify, t) => e => {
    const error = e as HydraAxiosError
    if (error.response?.status === 422) {
      const violations = error.response.data.violations || []
      for (const { propertyPath } of violations) {
        if (propertyPath === 'phone') {
          notify?.(t!('create_account.user_phone_exist'), {
            type: 'error',
          })
        }
        if (propertyPath === 'username') {
          notify?.(t!('create_account.user_login_exist'), {
            type: 'error',
          })
        }
        if (propertyPath === 'email') {
          notify?.(t!('create_account.user_email_exist'), {
            type: 'error',
          })
        }
      }
    }
  }
