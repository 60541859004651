import {
  IconButtonWithTooltipProps,
  IconButtonWithTooltip,
  useRecordContext,
  RaRecord,
} from 'react-admin'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useModalState } from 'shared/hooks'
import { DialogDataRow, DialogShowPage } from 'shared/ui'

export interface CustomShowButtonProps<T>
  extends Partial<IconButtonWithTooltipProps> {
  title?: string
  showData?: (record: T) => DialogDataRow[]
}

export const CustomShowButton = <T extends RaRecord>({
  title,
  showData,
  ...rest
}: CustomShowButtonProps<T>) => {
  const record = useRecordContext() as T
  const { isOpen, onToggle } = useModalState()

  return (
    <>
      <IconButtonWithTooltip
        {...rest}
        label='action_button.show'
        onClick={onToggle}
      >
        <InfoOutlinedIcon />
      </IconButtonWithTooltip>
      <DialogShowPage
        open={isOpen}
        onClose={onToggle}
        title={title}
        showData={showData?.(record) || []}
      />
    </>
  )
}
