import { FCWithChildren } from 'shared/@types'
import cn from 'classnames'

export interface PreAdminLayout {
  className?: string
}

export const PreAdminLayout: FCWithChildren<PreAdminLayout> = ({
  children,
  className = '',
}) => {
  return (
    <div
      className={cn(
        `flex items-center justify-center w-screen h-screen
      bg-gradient-to-b from-[#003499] to-[#16040B]`,
        {
          [className]: className,
        }
      )}
    >
      {children}
    </div>
  )
}
