import { USER_ROLE } from '../model'

export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_USER = 'ROLE_USER'
export const ROLE_DEFAULT = 'ROLE_DEFAULT'
export const ROLE_COMPANY = 'ROLE_COMPANY'

export const ROLE_NAME_BY_IDENTIFIER = {
  [ROLE_ADMIN]: 'Admin',
  [ROLE_USER]: 'Referral',
  [ROLE_COMPANY]: 'Company',
}

export const isUserHasRole = (roles: USER_ROLE[], role: USER_ROLE) =>
  roles.includes(role)

export const getUserRole = (roles: USER_ROLE[]) => {
  if (isUserHasRole(roles, ROLE_ADMIN)) {
    return ROLE_NAME_BY_IDENTIFIER[ROLE_ADMIN]
  }
  if (isUserHasRole(roles, ROLE_COMPANY)) {
    return ROLE_NAME_BY_IDENTIFIER[ROLE_COMPANY]
  }
  if (isUserHasRole(roles, ROLE_USER)) {
    return ROLE_NAME_BY_IDENTIFIER[ROLE_USER]
  }
  return ''
}
