import { RaRecord, IconButtonWithTooltipProps } from 'react-admin'
import {
  CustomEditButton,
  CustomShowButton,
  CustomDeleteButton,
  CustomShowButtonProps,
} from 'shared/ui'
import cn from 'classnames'
import {
  CustomAuthCompanyButton,
  CustomAuthCompanyButtonProps,
} from 'features/auth/company'
import {
  CustomWithDrawButtonProps,
  CustomWithdrawButton,
} from 'features/withdraw'

export interface CustomActionButtonsProps<T> {
  hasEdit?: boolean
  hasDelete?: boolean
  hasShow?: boolean
  hasWithdraw?: boolean
  hasAuthCompany?: boolean
  EditButtonProps?: Partial<IconButtonWithTooltipProps>
  ShowButtonProps?: CustomShowButtonProps<T>
  DeleteButtonProps?: Partial<IconButtonWithTooltipProps>
  WithdrawButtonProps?: CustomWithDrawButtonProps<T>
  AuthCompanyButtonProps?: CustomAuthCompanyButtonProps<T>
  className?: string
}

export const CustomActionButtons = <T extends RaRecord>({
  className = '',
  hasEdit = true,
  hasDelete = true,
  hasShow = true,
  hasWithdraw = false,
  hasAuthCompany = false,
  EditButtonProps = {},
  ShowButtonProps = {},
  DeleteButtonProps = {},
  WithdrawButtonProps = {},
  AuthCompanyButtonProps = {},
}: CustomActionButtonsProps<T>) => {
  return (
    <div
      className={cn(
        `column-actions flex items-center w-fit [&_button]:!p-[5px]
     [&_svg]:!w-5 [&_svg]:!h-5`,
        className
      )}
    >
      {hasEdit && <CustomEditButton {...EditButtonProps} />}
      {hasShow && <CustomShowButton {...ShowButtonProps} />}
      {hasDelete && <CustomDeleteButton {...DeleteButtonProps} />}
      {hasWithdraw && <CustomWithdrawButton {...WithdrawButtonProps} />}
      {hasAuthCompany && (
        <CustomAuthCompanyButton {...AuthCompanyButtonProps} />
      )}
    </div>
  )
}
