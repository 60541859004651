import { MockedResponse } from 'msw'
import { BASE_URL } from 'shared/config'

export const addBaseURL = (path: string) => BASE_URL + path

export const getListSize = (searchParams: URLSearchParams) =>
  `hydra:member|${searchParams.get('itemsPerPage') || 100}`

export const addDefaultHeaders = (res: MockedResponse) => {
  res.headers.set('cache-control', 'no-cache, private')
  res.headers.set('access-control-expose-headers', 'link')
  res.headers.set('Content-Type', 'application/ld+json; charset=utf-8')
  res.headers.set(
    'link',
    '<https://api.referral.dev2.webant.ru/docs.jsonld>; rel="http://www.w3.org/ns/hydra/core#apiDocumentation"'
  )

  return res
}
