import { createElement } from 'react'
import {
  Menu as MUIMenu,
  MenuProps,
  useResourceDefinitions,
  useSidebarState,
  useGetResourceLabel,
  useCreatePath,
  MenuItemLink,
  ResourceDefinitions,
  usePermissions,
} from 'react-admin'
import { useLocation } from 'react-router-dom'
import DefaultIcon from '@mui/icons-material/ViewList'
import cn from 'classnames'
import { ReactComponent as Settings } from 'app/assets/icons/settings.svg'
import { ROLE_ADMIN } from 'entities/users'
import { Permissions } from 'features/auth/basic'

export const Menu = (props: MenuProps) => {
  const resources = useResourceDefinitions()
  const getResourceLabel = useGetResourceLabel()
  const createPath = useCreatePath()
  const [open] = useSidebarState()
  const { pathname } = useLocation()
  const { permissions } = usePermissions<Permissions>()
  const isAdmin = permissions?.roles?.includes(ROLE_ADMIN)

  const customResources = {
    ...resources,
    ...customRoutes,
  } as ResourceDefinitions

  return (
    <MUIMenu {...props} className='!my-[12px] gap-2 !mx-3'>
      {Object.keys(customResources).map(name => (
        <div
          key={name}
          className={cn(
            `pl-[6px] border-l-2 border-transparent w-[220px]
          !transition-[width] !ease-default !duration-[195ms] overflow-hidden`,
            {
              'border-primary [&_path]:stroke-primary !text-primary':
                new RegExp(`/${name}`).test(pathname),
              '!w-[46px]': !open,
              hidden: isAdmin && customResources[name].options?.isClient,
            }
          )}
        >
          <MenuItemLink
            to={createPath({
              resource: name,
              type: 'list',
            })}
            state={{ _scrollToTop: true }}
            primaryText={getResourceLabel(name, 2)}
            leftIcon={
              customResources[name].icon ? (
                createElement(customResources[name].icon)
              ) : (
                <DefaultIcon />
              )
            }
            dense
            className={cn('w-full !p-0 !rounded hover:!bg-blue !text-black', {
              '!text-primary': new RegExp(`/${name}`).test(pathname),
            })}
          />
        </div>
      ))}
    </MUIMenu>
  )
}

const customRoutes = {
  settings: {
    hasCreate: false,
    hasEdit: false,
    hasList: false,
    hasShow: false,
    icon: Settings,
    name: 'settings',
    options: {
      isClient: true,
    },
  },
}
