export const getCapitalizedString = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)
export const getNumberFromString = (str: string) => str.replace(/\D+/g, '')
export const removeStartSlashFromUrl = (str?: string) => {
  if (!str) return ''

  if (str.startsWith('/')) {
    return str.slice(1)
  }

  return str
}
