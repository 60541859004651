import { FC } from 'react'
import { CreateBase, CreateProps, useNotify } from 'react-admin'
import { serverSideUsersValidation, UserForm } from 'entities/users'
import { getDeepClone } from 'shared/helpers'

const UserCreate: FC<CreateProps> = props => {
  const notify = useNotify()

  return (
    <CreateBase
      {...props}
      resource='users'
      transform={data => {
        const clone = getDeepClone(data)
        clone.isAgreementAccepted = true
        clone.company = clone.company?.id
        return clone
      }}
      mutationOptions={{
        onError: serverSideUsersValidation(notify),
      }}
      redirect='/users'
    >
      <p className='text-black text mb-big'>Add User</p>
      <UserForm />
    </CreateBase>
  )
}

export default UserCreate
