import { httpClient } from 'shared/http-client'
import { AxiosRequestConfig } from 'axios'
import {
  AccountConfirmationFormData,
  AccountConfirmationResponse,
} from './types'

export const accountConfirmation = (
  config: AxiosRequestConfig<AccountConfirmationFormData>
) =>
  httpClient<AccountConfirmationResponse, AccountConfirmationFormData>({
    ...config,
    url: '/confirm-code',
    method: 'POST',
  })
