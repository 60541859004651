import { forwardRef } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import cn from 'classnames'

export const CustomTextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ className = '', ...rest }, ref) => (
    <TextField
      variant='filled'
      helperText={false}
      className={cn({
        [className]: className,
      })}
      {...rest}
      FormHelperTextProps={{
        ...rest.FormHelperTextProps,
        className: cn({
          [rest.FormHelperTextProps?.className || '']:
            rest.FormHelperTextProps?.className,
        }),
      }}
      InputProps={{
        ...rest.InputProps,
        ref,
        className: cn('!h-10 !py-0', {
          [rest.InputProps?.className || '']: rest.InputProps?.className,
        }),
      }}
      InputLabelProps={{
        ...rest.InputLabelProps,
        className: cn('!top-1', {
          [rest.InputLabelProps?.className || '']:
            rest.InputLabelProps?.className,
        }),
      }}
    />
  )
)
