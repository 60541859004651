import Mock from 'mockjs'
import { rest } from 'msw'
import { addBaseURL, addDefaultHeaders, getListSize } from '../helpers'

export const referral_systems = [
  rest.get(addBaseURL('/referral_systems'), (req, res, ctx) => {
    return res(
      addDefaultHeaders,
      ctx.status(200),
      ctx.json(
        Mock.mock({
          '@context': '/contexts/ReferralSystem',
          '@id': '/referral_systems',
          '@type': 'hydra:Collection',
          [getListSize(req.url.searchParams)]: [REFERRAL_SYSTEM_ENTITY_MOCK],
          'hydra:totalItems': 100,
        })
      )
    )
  }),
  rest.get(addBaseURL('/referral_systems/:id'), (req, res, ctx) => {
    const id = req.params.id
    return res(
      ctx.status(200),
      ctx.json(
        Mock.mock({
          ...REFERRAL_SYSTEM_ENTITY_MOCK,
          '@context': '/contexts/ReferralSystem',
          'id|+1': `/referral_systems/${id}`,
          '@id|+1': `/referral_systems/${id}`,
        })
      )
    )
  }),
  rest.put(addBaseURL('/referral_systems/:id'), (req, res, ctx) => {
    const body = req.body
    return res(ctx.status(201), ctx.json(Mock.mock(body)))
  }),
]

const REFERRAL_EVENT_ENTITY_MOCK = {
  'id|+1': 1,
  '@id|+1': function () {
    return `/referral_events/${this.id}`
  },
  '@type': 'ReferralEvent',
  isNegation: '@boolean',
  name: '@title(1,3)',
  orderNumber: function () {
    return this.id
  },
  'sum|1-100': 100,
  type: 1,
} as { [key: string]: unknown }

const REFERRAL_SYSTEM_ENTITY_MOCK = {
  'id|+1': 1,
  '@id|+1': function () {
    return `/referral_systems/${this.id}`
  },
  '@type': 'ReferralSystem',
  name: '@title(1,3)',
  company: /\/companies\/[0-9]/,
  'referralEvents|2-25': [REFERRAL_EVENT_ENTITY_MOCK],
  referralEventsCount: function () {
    return (this.referralEvents as []).length
  },
  referralEventsSum: function () {
    return (this.referralEvents as [{ sum: number }]).reduce(
      (acc, el) => acc + el.sum,
      0
    )
  },
} as { [key: string]: unknown }
