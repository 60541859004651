import { useState } from 'react'
import { useNotify } from 'react-admin'
import { Form, onSubmit } from 'shared/libs'
import { CustomTextField, PreAdminContent } from 'shared/ui'
import {
  passwordRecoverySchema,
  serverSideResetPasswordValidation,
} from '../lib'
import { resetPassword, ResetPasswordFormData } from '../model'
import { SuccessfullSendEmail } from './successful-send-email'
import { useTranslate } from 'react-admin'

export const ResetPasswordPage = () => {
  const t = useTranslate()

  const notify = useNotify()
  const [resetedEmail, setResetedEmail] = useState('')

  if (resetedEmail) {
    return <SuccessfullSendEmail email={resetedEmail} />
  }

  return (
    <Form<ResetPasswordFormData>
      validationSchema={passwordRecoverySchema(t)}
      onSubmit={data =>
        onSubmit(data)({
          submitRequest: resetPassword,
          notify,
          onSuccess: data => setResetedEmail(data.email),
          onError: serverSideResetPasswordValidation,
        })
      }
    >
      <PreAdminContent
        title={t('reset_password_page.password_recovery')}
        description={t('reset_password_page.password_recovery_description')}
        buttonText={t('reset_password_page.next')}
        cancelButtonText={t('reset_password_page.cancel')}
        cancelRedirectLink='/login'
      >
        <CustomTextField
          name='email'
          variant='standard'
          label={t('reset_password_page.email')}
          fullWidth
          className='!mb-big'
        />
      </PreAdminContent>
    </Form>
  )
}
