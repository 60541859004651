import { FC, Fragment, ReactElement } from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { IconButtonWithTooltip, useNotify } from 'react-admin'
import { Nullable } from 'shared/@types'
import CloseIcon from '@mui/icons-material/Close'
import DialogActions from '@mui/material/DialogActions'
import { Button } from '@mui/material'
import { forcePayout } from 'features/withdraw'
import { onAuthCompany } from 'features/auth/company'
import { useTranslate } from 'react-admin'

export interface DialogDataRow {
  label: string
  value?: Nullable<string | ReactElement>
}

export interface DialogShowPageProps extends DialogProps {
  title?: string
  open: boolean
  showData: DialogDataRow[]
  onClose: () => void
  withAuthCompany?: boolean
  authCompanyLabel?: string
  authLogin?: string
  withDraw?: boolean
  withDrawLabel?: string
  withUserInfo?: boolean
}

export const DialogShowPage: FC<DialogShowPageProps> = ({
  title = 'View',
  open,
  onClose,
  className = '',
  showData,
  withAuthCompany,
  authCompanyLabel,
  authLogin,
  withDraw,
  withDrawLabel = '',
  withUserInfo,
  ...rest
}) => {
  const t = useTranslate()

  const notify = useNotify()
  const onWithdraw = () => {
    forcePayout()
      .then(() => notify(`${withDrawLabel} Success`, { type: 'success' }))
      .catch(e => notify(e.message, { type: 'error' }))
    onClose()
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      {...rest}
    >
      <DialogTitle
        id='scroll-dialog-title'
        className='flex items-center justify-between w-full h-16 !bg-primary !pl-big !pr-4'
      >
        <p className='font-medium text-white text-title'>{title}</p>
        <IconButtonWithTooltip
          label='Close'
          onClick={onClose}
          className='!p-0 w-8 h-8'
        >
          <CloseIcon className='text-white' />
        </IconButtonWithTooltip>
      </DialogTitle>
      <DialogContent
        dividers
        className='min-w-[460px] !p-big tablet:min-w-[250px]'
      >
        <DialogContentText
          id='scroll-dialog-description'
          tabIndex={-1}
          className='grid grid-cols-2 gap-y-5 gap-x-2'
        >
          {showData.map(record => (
            <Fragment key={record.label}>
              <p className='text-subtext text-gray'>{record.label}</p>
              <div className='text-black text-subtext overflow-auto scroll-m-2'>
                {record.value}
              </div>
            </Fragment>
          ))}
        </DialogContentText>
      </DialogContent>
      {withDraw && (
        <DialogActions>
          <Button onClick={onClose}>{t('toolbar.cancel')}</Button>
          <Button onClick={onWithdraw} variant='contained'>
            {withDrawLabel}
          </Button>
        </DialogActions>
      )}
      {withAuthCompany && (
        <DialogActions>
          <Button onClick={onClose}>{t('toolbar.cancel')}</Button>
          <Button
            onClick={() => onAuthCompany(authLogin || '', notify)}
            variant='contained'
          >
            {authCompanyLabel}
          </Button>
        </DialogActions>
      )}
      {withUserInfo && <Button onClick={onClose}>{t('toolbar.cancel')}</Button>}
    </Dialog>
  )
}
