import { FC } from 'react'
import {
  IconButtonWithTooltipProps,
  IconButtonWithTooltip,
  Link,
  useRecordContext,
  useListContext,
} from 'react-admin'
import Edit from '@mui/icons-material/Edit'
import { useCreatePath } from 'ra-core'

export const CustomEditButton: FC<
  Partial<IconButtonWithTooltipProps>
> = props => {
  const record = useRecordContext()
  const { resource } = useListContext()
  const createPath = useCreatePath()

  return (
    <Link to={createPath({ type: 'edit', resource, id: record.id })}>
      <IconButtonWithTooltip {...props} label='action_button.edit'>
        <Edit />
      </IconButtonWithTooltip>
    </Link>
  )
}
