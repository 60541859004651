const getEnv = (key: string) => {
  const env = process.env[key]

  if (env === undefined) {
    throw new Error(`Env variable ${key} is required`)
  }

  return env
}

export const BASE_URL = getEnv('REACT_APP_TARGET')
export const TOKEN_PATH = getEnv('REACT_APP_TOKEN_PATH')
export const API_MOCKING = getEnv('REACT_APP_API_MOCKING')
export const CLIENT_ID = getEnv('REACT_APP_CLIENT_ID')
export const CLIENT_SECRET = getEnv('REACT_APP_CLIENT_SECRET')
export const PROJECT_VERSION = getEnv('REACT_APP_TAG')

// Режим запуска программы
export const NODE_ENV = getEnv('NODE_ENV')
// Режим разработки
export const isDevEnv = NODE_ENV === 'development'
// Режим продакшена
export const isProdEnv = NODE_ENV === 'production'

export default getEnv
