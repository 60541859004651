import { Button } from '@mui/material'
import { PreAdminLayout } from 'shared/ui'
import { Link } from 'react-router-dom'
import { ReactNode } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import cn from 'classnames'
import { FCWithChildren } from 'shared/@types'

export interface PreAdminContent {
  isLoading?: boolean
  layoutClassName?: string
  wrapperClassName?: string
  title?: string | ReactNode
  description?: string | ReactNode
  buttonText?: string
  cancelButtonText?: string
  redirectLink?: string
  cancelRedirectLink?: string
}

export const PreAdminContent: FCWithChildren<PreAdminContent> = ({
  isLoading,
  layoutClassName,
  wrapperClassName = '',
  title,
  description,
  buttonText,
  redirectLink,
  children,
  cancelButtonText,
  cancelRedirectLink,
}) => {
  const button = (
    <Button type='submit' variant='contained' fullWidth className='h-10'>
      {buttonText || 'Continue'}
    </Button>
  )
  const cancelButton = (
    <Button
      type='submit'
      variant='contained'
      color='secondary'
      fullWidth
      className='h-10'
    >
      {cancelButtonText || 'Cancel'}
    </Button>
  )

  return (
    <PreAdminLayout className={layoutClassName}>
      <section
        className={cn('flex flex-col rounded bg-white w-[306px] p-big', {
          [wrapperClassName]: wrapperClassName,
          'items-center': isLoading,
        })}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {title && (
              <p className='text-center text-black text-title mb-base'>
                {title}
              </p>
            )}
            {description && (
              <p className='text-center text-subtext text-gray mb-big'>
                {description}
              </p>
            )}
            {children}
            {redirectLink ? <Link to={redirectLink}>{button}</Link> : button}
            {cancelRedirectLink ? (
              <Link className={'mt-3'} to={cancelRedirectLink}>
                {cancelButton}
              </Link>
            ) : null}
          </>
        )}
      </section>
    </PreAdminLayout>
  )
}
