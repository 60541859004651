// TS не может найти модуль ra-language-russian
// @ts-expect-error
import russianMessages from 'ra-language-russian'

export default {
  ra: {
    ...russianMessages.ra,
    action: {
      ...russianMessages.ra.action,
      add_filter: 'ФИЛЬТР',
      remove_all_filters: 'Удалить все фильтры',
      filters: 'Фильтры',
    },
    notification: {
      ...russianMessages.ra.notification,
      updated: 'Сохранить изменения',
    },
  },
  resources: {
    settings: {
      name: 'Настройки',
    },
  },
  login_page: {
    login: 'Логин',
    login_req: 'Логин обязателен',
    password: 'Пароль',
    password_req: 'Пароль обязателен',
    log_in: 'Войти',
    create_account: 'Создать аккаунт',
    forgot_password: 'Забыли пароль?',
    invalid_email_or_password: 'Неверный e-mail или пароль',
    user_not_activated: 'Пользователь не активирован',
  },
  reset_password_page: {
    password_recovery: 'Восстановление пароля',
    password_recovery_description: 'Введите зарегистрированный e-mail',
    email: 'E-mail',
    invalid_email: 'Неверный e-mail',
    email_req: 'E-mail обязателен',
    next: 'Далее',
    cancel: 'Отмена',
    follow_the_link:
      'Перейдите по ссылке из электронного письма, отправленного на',
    got_it: 'Понятно',
  },
  create_account: {
    create_account_title: 'Создание аккаунта',
    company_name: 'Название компании',
    company_name_req: 'Название компании обязательно',
    company_description: 'Описание компании',
    company_description_req: 'Описание компании обязательно',
    url_site: 'URL сайта',
    url_site_req: 'URL сайта обязателен',
    url_site_invalid: 'URL сайта неверный. Пример: https://example.com',
    email: 'E-mail',
    email_req: 'E-mail обязателен',
    email_invalid: 'E-mail невереный',
    name: 'Имя',
    name_req: 'Имя обязательно',
    lastname: 'Фамилия',
    lastname_req: 'Фамилия обязательна',
    phone_number: 'Номер телефона',
    phone_number_req: 'Номер телефона обязателен',
    phone_number_invalid: 'Номер телефона неверный',
    login: 'Логин',
    login_req: 'Логин обязателен',
    password: 'Пароль',
    password_req: 'Пароль обязателен',
    password_invalid: 'Пароль должен содержать минимум 6 символов',
    password_must_contain:
      'Пароль должен содержать буквы латинского алфавита, цифры, пробелы и знаки ( , _ - . )',
    repeat_password: 'Повторите пароль',
    repeat_password_req: 'Повторите пароль',
    repeat_password_must_match: 'Пароли должны совпадать',
    personal_data: 'Я согласен с обработкой персональных данных',
    personal_data_req: 'Вы должны принять политику конфиденциальности',
    next: 'Далее',
    cancel: 'Отмена',
    user_phone_exist: 'Пользователь с таким номером уже существует',
    user_email_exist: 'Пользователь с таким email уже существует',
    user_login_exist: 'Пользователь с таким логином уже существует',
    successful_user_registration: {
      title: 'Подтверждение E-mail',
      text: 'Перейдите по ссылке из сообщения отправленному на',
      got_it: 'Понятно',
      success: 'Пользователь успешно зарегистрирован',
    },
  },
  action_button: {
    edit: 'Редактировать',
    show: 'Просмотреть',
    delete: 'Удалить',
  },
  dialog: {
    are_you_sure: 'Вы уверены, что хотите удалить эту запись?',
    copied: 'Скопированно',
  },
  header: {
    return: 'Вернуться',
    exit: 'Выход',
    balance: 'Баланс',
    wallet: 'Кошелек',
    energy: 'Энергия',
  },
  toolbar: {
    cancel: 'Отменить',
    OK: 'ОК',
    save: 'Сохранить',
    add: 'Добавить',
    remove: 'Удалить',
    yes: 'Да',
  },
  tabs: {
    company_balance: 'Баланс компании',
    users: 'Пользователи',
    internal_transactions: 'Внутренние операции',
    external_transactions: 'Внешние операции',
    payout_building: 'Выплаты',
  },
  companies: {
    sort_by_id: 'Сортировка по Id',
    companies_title: 'Компании',
    filter: {
      id: 'ID',
      company: 'Компания',
      creation_date_after: 'Дата создания после',
      creation_date_before: 'Дата создания до',
    },
    table: {
      id: 'ID',
      balance: 'Баланс',
      company: 'Компания',
      activation: 'Активация',
      isActivated: 'Активирована',
      isNotActivated: 'Не активирована',
      creation_date: 'Дата создания',
      auth: 'Авторизация',
      modal: {
        signIn: 'Вход в систему с помощью компании',
      },
    },
  },
  users: {
    sort_by_id: 'Сортировка по Id',
    sort_by_creation_date: 'Сортировка по дате создания',
    select_all: 'Выбрать все',
    users_title: 'Пользователи',
    user_title: 'Пользователь',
    roles: {
      Admin: 'Админ',
      Referral: 'Реферал',
      Company: 'Компания',
    },
    filter: {
      id: 'ID',
      role: 'Роль',
      email: 'E-mail',
      company: 'Компания',
      all: 'Все',
    },
    table: {
      id: 'ID',
      role: 'Роль',
      email: 'E-mail',
      company: 'Компания',
      creation_date: 'Дата создания',
    },
    show: {
      user_view: 'Информация о пользователе',
      role: 'Роль',
      company_name: 'Название компании',
      company_id: 'ID компании',
      email: 'E-mail',
      phone: 'Телефон',
      name: 'Имя',
      external_login_account: 'Сторонняя учетная запись',
      internal_wallet: 'Внутренний кошелек',
      extarnal_wallet: 'Внешний кошелек',
    },
    edit: {
      edit_title: 'Редактирование данных пользователя',
      role: 'Роль',
      first_name: 'Имя',
      last_name: 'Фамилия',
      login: 'Логин',
      email: 'E-mail',
      phone: 'Телефон',
      password: 'Пароль',
      company: 'Компания',
    },
    confirmation: {
      success: {
        title: 'Ура!',
        description: 'Вы успешно активировали аккаунт!',
        buttonText: 'Хорошо',
      },
      error: {
        title: 'Упс! :(',
        description: 'Что-то пошло не так',
        buttonText: 'Отправить E-mail еще раз',
      },
    },
  },
  internal_transactions: {
    sort_by_creation_date: 'Сортировка по дате создания',
    transaction_history: 'История переводов',
    filter: {
      type: 'Тип',
      arbitrage_user: 'Арбитражник',
      user: 'Пользователь',
      creation_date_after: 'Дата создания после',
      creation_date_before: 'Дата создания до',
    },
    table: {
      type: 'Тип',
      arbitrage_user: 'Арбитражник',
      event_type: 'Тип события',
      company: 'Компания',
      user: 'Пользователь',
      sum: 'Сумма',
      status: 'Статус',
      creation_date: 'Дата создания',
    },
    statuses: {
      New: 'Новый',
      'In Queue': 'В очереди',
      'In Process': 'В процессе',
      Completed: 'Завершен',
      Error: 'Ошибка',
      Cancel: 'Отменен',
    },
  },
  external_transactions: {
    total: 'Всего',
    fee_cost: 'Стоимость сбора',
    withdraw_all: 'Вывести все',
    sort_by_creation_date: 'Сортировка по дате создания',
    external_transactions_title: 'Внешние переводы',
    filter: {
      wallet: 'Кошелек',
      user: 'Пользователь',
      status: 'Статус',
      creation_date_after: 'Дата создания после',
      creation_date_before: 'Дата создания до',
      all: 'Все',
    },
    notifications: {
      successfully_accepted: 'Транзакция успешно подтверждена',
      successfully_dismissed: 'Транзакция успешно отменена',
    },
    table: {
      wallet_to: 'Кошелек для',
      company: 'Компания',
      user: 'Пользователь',
      date_time: 'Дата & Время',
      status: 'Статус',
      sum: 'Сумма',
      accept: 'Принять',
      dismiss: 'Отклонить',
      creation_date: 'Дата создания',
      modal: {
        do_you_want: 'Вы хотите принять транзакцию?',
        do_you_want_dismiss: 'Вы хотите отменить транзакцию?',
        cannot_be_undone: 'Это действие не может быть отменено',
        accept: 'Принять транзакцию',
        dismiss: 'Отменить транзакцию',
        low_energy_error:
          'На данный момент энергии недостаточно для оплаты транзакции',
        next_transation_with_trx: 'Транзакция будет отплачена TRX',
      },
    },
    statuses: {
      New: 'Новый',
      'In Queue': 'В очереди',
      'In Process': 'В процессе',
      Completed: 'Завершен',
      Error: 'Ошибка',
      Cancel: 'Отменен',
    },
  },
  payout_building: {
    sort_by_creation_date: 'Сортировка по Дате создания',
    payout_building_title: 'Создание выплат',
    add: 'Добавить',
    filter: {
      way_name: 'Название способа',
      steps: 'Шаги',
      compensation: 'Компенсация',
      blocked: 'Блокировка',
    },
    table: {
      way_name: 'Название способа',
      steps: 'Шаги',
      compensation: 'Компенсация',
      creation_date: 'Дата создания',
      company: 'Компания',
    },
    show: {
      payout_way_view: 'Информация о способах выплаты',
      way_name: 'Название способа',
      life_span: 'Срок жизни',
      step: 'Шаг',
      compensation: 'Компенсация',
      condition_must_not_be_met: 'Условие не выполнено',
      cost: 'Стоимость',
    },
    edit: {
      edit_payout_way: 'Редактировать способ выплаты',
    },
    create: {
      add_payout_way: 'Добавить способ выплаты',
      way_name: 'Название способа',
      referral_address: 'Реферальный адрес',
      company: 'Компания',
      session_life_span: 'Срок жизни сессии, часы',
      session_life_span_tooltip:
        'Продолжительность сеанса пользователя, в течение которого он может выполнить шаги по перенаправлению (в часах)',
      step: 'Шаг',
      add_step: 'Добавить шаг',
      condition_must_not_be_met: 'Условие не выполнено',
      add_cost: 'Добавить стоимость',
      step_name: 'Название шага',
      webhook_token: 'Вебхук токен',
      copy: 'Копировать',
      webhook_url: 'Вебхук URL',
      cost: 'Стоимость',
      compensation: 'Компенсация',
      after_saving_ref_system:
        'После сохранения "Реферальной системы" отредактировать ее будет невозможно!',
      ref_system_name_req: 'Требуется название реферальной системы',
      use_https_link: 'Используйте https-ссылку. Пример: https://example.com',
      ref_address_req: 'Требуется реферальный адрес',
      session_life_span_integer:
        'Продолжительность жизни сеанса должна быть целым числом',
      session_life_span_min: 'Минимальная продолжительность сеанса - 1 час',
      session_life_span_req: 'Требуется продолжительность сеанса',
      event_name_req: 'Требуется название события',
      cost_must_greater: 'Стоимость должна быть больше 0',
      company_req: 'Компания обязательна',
      compensation_must_not_negative:
        'Компенсация не должна быть отрицательной',
      compensation_must_greater: 'Компенсация должна быть больше 0',
    },
    alert: {
      createdAlready:
        'Вы уже создали способ выплаты, чтобы создать новый, вам нужно удалить предыдущий способ и повторить попытку',
    },
  },
  settings: {
    copy: 'Копировать',
    company_wallet_no_activated: 'Кошелек компании не активирован',
    company_wallet_activated: 'Кошелек компании активирован',
    public_key: 'Публичный ключ:',
    private_key: 'Приватный ключ:',
    integration_instruction: 'Инструкция по интеграции',
    download: 'Скачать',
    none: 'Ничего',
  },
}
