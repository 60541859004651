import { FC } from 'react'
import { EditBase, EditProps, useNotify } from 'react-admin'
import { serverSideUsersValidation, UserForm } from 'entities/users'
import { getDeepClone } from 'shared/helpers'
import { useTranslate } from 'react-admin'

const UserEdit: FC<EditProps> = props => {
  const notify = useNotify()
  const t = useTranslate()

  return (
    <EditBase
      {...props}
      transform={data => {
        const clone = getDeepClone(data)
        if (!clone.plainPassword) {
          delete clone.plainPassword
        }
        clone.company = clone.company?.['@id']
        return clone
      }}
      redirect='edit'
      mutationOptions={{
        onError: serverSideUsersValidation(notify),
      }}
    >
      <p className='text-black text mb-big'>{t('users.edit.edit_title')}</p>
      <UserForm isEdit />
    </EditBase>
  )
}

export default UserEdit
