import { HydraAxiosError, ServerSideValidator } from 'shared/@types'
import {
  EMAIL_REG_EXP,
  PASSWORD_REQUIREMENTS_REG_EXP,
  PHONE_REG_EXP,
} from 'shared/config'
import * as yup from 'yup'

export const userSchema = (isEdit?: boolean) =>
  yup
    .object({
      roles: yup.array().required('Role is required'),
      username: yup.string().required('Login is required'),
      firstName: yup.string().required('Name is required'),
      lastName: yup.string().required('Surname is required'),
      plainPassword: yup.string().when({
        is: (password: string) => !isEdit || password,
        then: shema =>
          shema
            .min(6, 'Password must contain at least 6 characters')
            .matches(
              PASSWORD_REQUIREMENTS_REG_EXP,
              'The password must contain letters of the Latin alphabet, numbers, spaces and signs ( , _ - . )'
            )
            .required('Password is required'),
      }),
      email: yup
        .string()
        .matches(EMAIL_REG_EXP, 'Invalid e-mail')
        .required('E-mail is required'),
      phone: yup
        .string()
        .matches(PHONE_REG_EXP, 'Invalid phone numbeer')
        .required('Phone is required'),
      company: yup.object({
        '@id': yup.string().required('Company is required'),
      }),
    })
    .required('Repeat password')

export const FIELD_BY_USER_PROPERTY_PATH = {
  username: 'Login',
  phone: 'Phone',
  email: 'E-mail',
}

export const serverSideUsersValidation: ServerSideValidator = notify => e => {
  const error = e as HydraAxiosError
  if (error.response?.status === 422) {
    notify?.(error.response.data?.['hydra:description'] || '', {
      type: 'error',
    })
  }
}
