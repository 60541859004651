import { useSidebarState } from 'react-admin'
import { FCWithChildren } from 'shared/@types'
import { useMediaTheme } from 'shared/hooks'
import { useEffect } from 'react'
import cn from 'classnames'

export interface SidebarProps {
  className?: string
}

export const Sidebar: FCWithChildren<SidebarProps> = ({
  children,
  className = '',
}) => {
  const { isMaxMedium } = useMediaTheme()
  const [open, setOpen] = useSidebarState()

  useEffect(() => {
    isMaxMedium && setOpen(false)
  }, [isMaxMedium])

  return (
    <div
      className={cn(
        'w-[78px] overflow-hidden transition-[width] ease-default duration-[195ms] laptop-tablet:w-0',
        {
          'w-[250px] overflow-hidden shadow-[0_9px_18px_rgba(0,0,0,0.18),0_5.5px_5px_rgba(0,0,0,0.24)]  laptop-tablet:pt-16 laptop-tablet:top-0 laptop-tablet:fixed laptop-tablet:h-full laptop-tablet:!w-full laptop-tablet:bg-white laptop-tablet:z-10':
            open,
          [className]: className,
        }
      )}
    >
      {children}
    </div>
  )
}
