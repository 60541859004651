import { FC } from 'react'
import { CustomSaveButton, CustomCancelButton } from 'shared/ui'
import cn from 'classnames'

export interface CustomFormToolbarProps {
  className?: string
  saveButtonLabel?: string
}

export const CustomFormToolbar: FC<CustomFormToolbarProps> = ({
  className = '',
  saveButtonLabel = 'OK',
}) => {
  return (
    <div
      className={cn('flex gap-base w-full', {
        [className]: className,
      })}
    >
      <CustomCancelButton />
      <CustomSaveButton label={saveButtonLabel} />
    </div>
  )
}
