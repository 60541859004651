import { ROLE_ADMIN, ROLE_DEFAULT, ROLE_USER } from 'entities/users'
import Mock from 'mockjs'
import { rest } from 'msw'
import qs from 'qs'
import { PHONE_MOCK } from '../constants'
import { addBaseURL, addDefaultHeaders, getListSize } from '../helpers'

export const users = [
  rest.get(addBaseURL('/users/current'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(Mock.mock(USER_ENTITY_MOCK)))
  }),
  rest.get(addBaseURL('/users'), (req, res, ctx) => {
    const ids = qs.parse(req.url.search).id

    if (ids) {
      return res(
        ctx.status(200),
        ctx.json(
          Mock.mock({
            '@context': '/contexts/User',
            '@id': '/users',
            '@type': 'hydra:Collection',
            'hydra:member': (ids as string[]).map(id => ({
              ...USER_ENTITY_MOCK,
              'id|+1': id,
              '@id|+1': id,
            })),
            'hydra:totalItems': ids.length,
          })
        )
      )
    }
    return res(
      addDefaultHeaders,
      ctx.status(200),
      ctx.json(
        Mock.mock({
          '@context': '/contexts/User',
          '@id': '/users',
          '@type': 'hydra:Collection',
          [getListSize(req.url.searchParams)]: [USER_ENTITY_MOCK],
          'hydra:search': {
            '@type': 'hydra:IriTemplate',
            'hydra:template': '/users{id,id[]}',
            'hydra:variableRepresentation': 'BasicRepresentation',
            'hydra:mapping': [
              {
                '@type': 'IriTemplateMapping',
                variable: 'id',
                property: 'id',
                required: false,
              },
              {
                '@type': 'IriTemplateMapping',
                variable: 'id[]',
                property: 'id',
                required: false,
              },
            ],
          },
          'hydra:totalItems': 100,
        })
      )
    )
  }),
  rest.post(addBaseURL('/users'), (req, res, ctx) => {
    const body = req.body
    return res(ctx.status(201), ctx.json(Mock.mock(body)))
  }),
  rest.get(addBaseURL('/users/:id'), (req, res, ctx) => {
    const id = req.params.id
    return res(
      ctx.status(200),
      ctx.json(
        Mock.mock({
          ...USER_ENTITY_MOCK,
          '@context': '/contexts/User',
          'id|+1': `/users/${id}`,
          '@id|+1': `/users/${id}`,
        })
      )
    )
  }),
  rest.get(addBaseURL('/transaction_user_info/:id'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(
        Mock.mock({
          '@context': '/contexts/Transaction',
          '@id': '/transactions',
          '@type': 'hydra:Collection',
          'hydra:member': [USER_ENTITY_MOCK],
          'hydra:totalItems': 100,
        })
      )
    )
  }),
  rest.put(addBaseURL('/users/:id'), (req, res, ctx) => {
    const body = req.body
    return res(ctx.status(201), ctx.json(Mock.mock(body)))
  }),
]

const USER_ENTITY_MOCK = {
  'id|+1': 1,
  '@id|+1': function () {
    return `/users/${this.id}`
  },
  '@type': 'User',
  company: /\/companies\/[1-9]/,
  email: '@email',
  emailCanonical: '@email',
  activationType: 'email',
  'externalId|+1': 1,
  enabled: true,
  firstName: '@first',
  fullName: '@name(true)',
  externalUsername: '@word',
  isAgreementAccepted: true,
  isEmailConfirmed: true,
  isExternalUser: true,
  isPhoneConfirmed: true,
  isRealEmail: true,
  lastName: '@last',
  middleName: '@first',
  phone: PHONE_MOCK,
  'roles|1': [
    [ROLE_ADMIN, ROLE_USER, ROLE_DEFAULT],
    [ROLE_USER, ROLE_DEFAULT],
  ],
  username: '@word',
  dateCreate: '@date("dd.MM.yyyy in HH:MM")',
  dateUpdate: '@date("dd.MM.yyyy in HH:MM")',
  fingerprint: null,
  group: true,
  groupNames: [],
  groups: [],
  lastConfirmCodeDate: null,
  role: false,
  salt: null,
  superAdmin: false,
  userIdentifier: '@word',
  usernameCanonical: '@word',
  wallet: /\/wallets\/[1-9]/,
} as { [key: string]: unknown }
