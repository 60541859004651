import { FC } from 'react'
import {
  useRecordContext,
  useListContext,
  useDelete,
  useNotify,
} from 'react-admin'
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import cn from 'classnames'
import { HydraAxiosError } from 'shared/@types'
import { useTranslate } from 'react-admin'
export interface DialogDeleteEntityProps extends DialogProps {
  text?: string
  agreeButtonLabel?: string
  cancelButtonLabel?: string
  onClose: () => void
}

export const DialogDeleteEntity: FC<DialogDeleteEntityProps> = ({
  text = 'are_you_sure',
  open,
  agreeButtonLabel = 'yes',
  cancelButtonLabel = 'cancel',
  onClose,
  className = '',
  ...rest
}) => {
  const t = useTranslate()
  const record = useRecordContext()
  const notification = useNotify()
  const { resource } = useListContext()
  const [deleteOne, { isLoading }] = useDelete(
    resource,
    {
      id: record.id,
      previousData: record,
    },
    { onError: validateServerErrors }
  )

  function validateServerErrors(error: HydraAxiosError) {
    return notification(error.response?.data['hydra:description'] || '', {
      type: 'error',
    })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      className={cn('', {
        [className]: className,
      })}
      {...rest}
    >
      <DialogContent
        dividers
        className='min-w-[460px] !p-big grid grid-cols-2 gap-10'
      >
        <DialogContentText
          id='scroll-dialog-description'
          tabIndex={-1}
          className='col-span-2'
        >
          <p className='text-center text-black text-title'>
            {t(`dialog.${text}`)}
          </p>
        </DialogContentText>
        <LoadingButton
          variant='contained'
          color='primary'
          onClick={() => deleteOne()}
          loading={isLoading}
          fullWidth
          size='large'
        >
          {t(`toolbar.${agreeButtonLabel}`)}
        </LoadingButton>
        <LoadingButton
          variant='contained'
          color='secondary'
          className='col-start-2'
          onClick={onClose}
          fullWidth
          size='large'
        >
          {t(`toolbar.${cancelButtonLabel}`)}
        </LoadingButton>
      </DialogContent>
    </Dialog>
  )
}
