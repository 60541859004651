import { FC } from 'react'
import { IconButtonWithTooltipProps, IconButtonWithTooltip } from 'react-admin'
import DeleteIcon from '@mui/icons-material/Delete'
import { useModalState } from 'shared/hooks'
import { DialogDeleteEntity } from './dialog-delete-entity'

export const CustomDeleteButton: FC<
  Partial<IconButtonWithTooltipProps>
> = props => {
  const { isOpen, onToggle } = useModalState()
  return (
    <>
      <IconButtonWithTooltip
        {...props}
        label='action_button.delete'
        onClick={onToggle}
      >
        <DeleteIcon />
      </IconButtonWithTooltip>
      <DialogDeleteEntity open={isOpen} onClose={onToggle} />
    </>
  )
}
