import {
  ButtonProps,
  useListContext,
  useResourceContext,
  useRedirect,
} from 'react-admin'
import cn from 'classnames'
import { Button } from '@mui/material'
import { useTranslate } from 'react-admin'

export interface CustomCreateButtonProps extends ButtonProps {
  onAdd?: (listData?: unknown[]) => (() => void) | undefined
}

export const CustomCreateButton = ({
  className = '',
  onAdd,
  ...rest
}: CustomCreateButtonProps) => {
  const t = useTranslate()

  const resource = useResourceContext()
  const { data } = useListContext()
  const redirect = useRedirect()

  const onClick = onAdd?.(data)

  return (
    <Button
      onClick={() => (onClick ? onClick() : redirect('create', resource))}
      variant='contained'
      size='medium'
      className={cn('!px-[33px]', {
        className,
      })}
      {...rest}
    >
      {t('toolbar.add')}
    </Button>
  )
}
