import englishMessages from 'ra-language-english'

export default {
  ra: {
    ...englishMessages.ra,
    action: {
      ...englishMessages.ra.action,
      add_filter: 'FILTER',
      filters: 'Filters',
    },
    notification: {
      ...englishMessages.ra.notification,
      updated: 'Save changes',
    },
  },
  resources: {
    settings: {
      name: 'Settings',
    },
  },
  login_page: {
    login: 'Login',
    login_req: 'Login is required',
    password: 'Password',
    password_req: 'Password is required',
    log_in: 'Log In',
    create_account: 'Create account',
    forgot_password: 'Forgot passowrd?',
    invalid_email_or_password: 'Invalid email or password',
    user_not_activated: 'User not activated',
  },
  reset_password_page: {
    password_recovery: 'Password recovery',
    password_recovery_description: 'Enter the registered e-mail',
    email: 'E-mail',
    invalid_email: 'Invalid e-mail',
    email_req: 'E-mail is required',
    next: 'Next',
    cancel: 'Cancel',
    follow_the_link: 'Follow the link from the email sent to',
    got_it: 'Got it',
  },
  create_account: {
    create_account_title: 'Create account',
    company_name: 'Company name',
    company_name_req: 'Company name is required',
    company_description: 'Company description',
    company_description_req: 'Company description is required',
    url_site: 'Site URL',
    url_site_req: 'Site URL is required',
    url_site_invalid: 'Invalid site URL. Example: https://example.com',
    email: 'E-mail',
    email_req: 'E-mail is required',
    email_invalid: 'Invalid e-mail',
    name: 'Name',
    name_req: 'Name is required',
    lastname: 'Lastname',
    lastname_req: 'Lastname is required',
    phone_number: 'Phone number',
    phone_number_req: 'Phone number is required',
    phone_number_invalid: 'Invalid phone number',
    login: 'Login',
    login_req: 'Login is required',
    password: 'Password',
    password_req: 'Password is required',
    password_invalid: 'The password must contain at least 6 characters',
    password_must_contain:
      'The password must contain letters of the Latin alphabet, numbers, spaces and signs ( , _ - . )',
    repeat_password: 'Repeat the password',
    repeat_password_req: 'Repeat the password',
    repeat_password_must_match: 'Passwords must match',
    personal_data: 'I agree with the processing of personal data',
    personal_data_req: 'You must accept the privacy policy',
    next: 'Next',
    cancel: 'Cancel',
    user_phone_exist: 'User with this phone number already exists',
    user_email_exist: 'User with this Email already exists',
    user_login_exist: 'User with this login already exists',
    successful_user_registration: {
      title: 'E-mail confirmation',
      text: 'Follow the link from the email sent to',
      got_it: 'Got it',
      success: 'User successfully registered',
    },
  },
  action_button: {
    edit: 'Edit',
    show: 'Show',
    delete: 'Delete',
  },
  dialog: {
    are_you_sure: 'Are you sure you want to delete the entry?',
    copied: 'Copied',
  },
  header: {
    return: 'Return',
    exit: 'Exit',
    balance: 'Balance',
    wallet: 'Wallet',
    energy: 'Energy',
  },
  toolbar: {
    cancel: 'Cancel',
    OK: 'OK',
    save: 'Save',
    add: 'Add',
    remove: 'Remove',
    yes: 'Yes',
  },
  tabs: {
    company_balance: 'Company balance',
    users: 'Users',
    internal_transactions: 'Internal Transactions',
    external_transactions: 'External Transactions',
    payout_building: 'Payout building',
  },
  companies: {
    sort_by_id: 'Sort By Id',
    companies_title: 'Companies',
    filter: {
      id: 'ID',
      company: 'Company',
      creation_date_after: 'Creation date after',
      creation_date_before: 'Creation date before',
    },
    table: {
      id: 'ID',
      balance: 'Balance',
      company: 'Company',
      activation: 'Activation',
      isActivated: 'Activated',
      isNotActivated: 'Not activated',
      creation_date: 'Creation date',
      auth: 'Auth',
      modal: {
        signIn: 'Sign in with a company',
      },
    },
  },
  users: {
    sort_by_id: 'Sort By Id',
    sort_by_creation_date: 'Sort By Creation Date',
    select_all: 'Select all',
    users_title: 'Users',
    user_title: 'User',
    roles: {
      Admin: 'Admin',
      Referral: 'Referral',
      Company: 'Company',
    },
    filter: {
      id: 'ID',
      role: 'Role',
      email: 'E-mail',
      company: 'Company',
      all: 'All',
    },
    table: {
      id: 'ID',
      role: 'Role',
      email: 'E-mail',
      company: 'Company',
      creation_date: 'Creation date',
    },
    show: {
      user_view: 'User view',
      role: 'Role',
      company_id: 'Company id',
      company_name: 'Company name',
      email: 'E-mail',
      phone: 'Phone',
      name: 'Name',
      external_login_account: 'External login account',
      internal_wallet: 'Internal wallet',
      extarnal_wallet: 'Extarnal wallet',
    },
    edit: {
      edit_title: 'Edit User',
      role: 'Role',
      first_name: 'First name',
      last_name: 'Last name',
      login: 'Login',
      email: 'E-mail',
      phone: 'Phone',
      password: 'Password',
      company: 'Company',
    },
    confirmation: {
      success: {
        title: 'Great!',
        description: 'You have successfully activated your account',
        buttonText: 'Got it',
      },
      error: {
        title: 'Oops! :(',
        description: 'Something went wrong',
        buttonText: 'Send mail again',
      },
    },
  },
  internal_transactions: {
    sort_by_creation_date: 'Sort By Creation Date',
    transaction_history: 'Transaction history',
    filter: {
      type: 'Type',
      arbitrage_user: 'Arbitrage user',
      user: 'User',
      creation_date_after: 'Creation date after',
      creation_date_before: 'Creation date before',
    },
    table: {
      type: 'Type',
      arbitrage_user: 'Arbitrage user',
      event_type: 'Event type',
      company: 'Company',
      user: 'User',
      sum: 'Sum',
      status: 'Status',
      creation_date: 'Creation date',
    },
    statuses: {
      New: 'New',
      'In Queue': 'In queue',
      'In Process': 'In process',
      Completed: 'Completed',
      Error: 'Error',
      Cancel: 'Cancel',
    },
  },
  external_transactions: {
    total: 'Total',
    fee_cost: 'Fee Cost',
    withdraw_all: 'Withdraw All',
    sort_by_creation_date: 'Sort By Creation Date',
    external_transactions_title: 'External transactions',
    filter: {
      wallet: 'Wallet',
      user: 'User',
      status: 'Status',
      creation_date_after: 'Creation date after',
      creation_date_before: 'Creation date before',
      all: 'All',
    },
    notifications: {
      successfully_accepted: 'Transaction successfully dismissed',
      successfully_dismissed: 'Transaction successfully accepted',
    },
    table: {
      wallet_to: 'Wallet to',
      company: 'Company',
      user: 'User',
      date_time: 'Date & Time',
      status: 'Status',
      sum: 'Sum',
      accept: 'Accept',
      dismiss: 'Dismiss',
      creation_date: 'Creation date',
      modal: {
        do_you_want: 'Do you want to accept the transaction?',
        do_you_want_dismiss: 'Do you want to dismiss the transaction?',
        cannot_be_undone: 'This action cannot be undone',
        accept: 'Accept transaction',
        dismiss: 'Dismiss transaction',
        low_energy_error:
          'At the moment there is not enough energy to pay for the transaction',
        next_transation_with_trx: 'Transaction will be paid by TRX',
      },
    },
    statuses: {
      New: 'New',
      'In Queue': 'In queue',
      'In Process': 'In process',
      Completed: 'Completed',
      Error: 'Error',
      Cancel: 'Cancel',
    },
  },
  payout_building: {
    sort_by_creation_date: 'Sort By Creation Date',
    payout_building_title: 'Payout building',
    add: 'Add',
    filter: {
      way_name: 'Way name',
      steps: 'Steps',
      compensation: 'Compensation',
      blocked: 'Blocked',
    },
    table: {
      way_name: 'Way name',
      steps: 'Steps',
      compensation: 'Compensation',
      creation_date: 'Creation date',
      company: 'Company',
    },
    show: {
      payout_way_view: 'Payout way view',
      way_name: 'Way name',
      life_span: 'Life span',
      step: 'Step',
      compensation: 'Compensation',
      condition_must_not_be_met: 'Condition must not be met',
      cost: 'Cost',
    },
    edit: {
      edit_payout_way: 'Edit payout way',
    },
    create: {
      add_payout_way: 'Add payout way',
      way_name: 'Way name',
      referral_address: 'Referral address',
      company: 'Company',
      session_life_span: 'Session life span, hours',
      session_life_span_tooltip:
        'The duration of the users session during which he can perform the redirection steps (in hours)',
      step: 'Step',
      add_step: 'Add step',
      condition_must_not_be_met: 'Condition must not be met',
      add_cost: 'Add cost',
      step_name: 'Step name',
      webhook_token: 'Webhook token',
      copy: 'Copy',
      webhook_url: 'Webhook URL',
      cost: 'Cost',
      compensation: 'Compensation',
      after_saving_ref_system:
        'After saving the "Refer system" it will not be possible to edit it!',
      ref_system_name_req: 'Referral system name is required',
      use_https_link: 'Use https link. Example: https://example.com',
      ref_address_req: 'Referral address is required',
      session_life_span_integer: 'Session life span should be integer',
      session_life_span_min: 'Minimum session life span is 1 hour',
      session_life_span_req: 'Session life span is required',
      event_name_req: 'Event name is required',
      cost_must_greater: 'Cost must be greater than 0',
      company_req: 'Company is required',
      compensation_must_not_negative: 'Сompensation must not be negative',
      compensation_must_greater: 'Compensation must be greater than 0',
    },
    alert: {
      createdAlready:
        'You have already created a Payout way, to create a new, you need to delete previous way and try again',
    },
  },
  settings: {
    copy: 'Copy',
    company_wallet_no_activated: 'The company wallet is not activated',
    company_wallet_activated: 'The company wallet is activated',
    public_key: 'Public key:',
    private_key: 'Private key:',
    integration_instruction: 'Integration Instructions',
    download: 'Download',
    none: 'None',
  },
}
