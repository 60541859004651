import { forwardRef } from 'react'
import { TextInput, TextInputProps } from 'react-admin'
import SearchIcon from '@mui/icons-material/Search'
import cn from 'classnames'

export interface CustomTextInputProps extends TextInputProps {
  withSearch?: boolean
}

export const CustomTextInput = forwardRef<
  HTMLInputElement,
  CustomTextInputProps
>(({ className = '', withSearch, ...rest }, ref) => {
  return (
    <TextInput
      variant='filled'
      helperText={false}
      className={cn({
        [className]: className,
      })}
      {...rest}
      FormHelperTextProps={{
        ...rest.FormHelperTextProps,
        className: cn('!mt-0', {
          [rest.FormHelperTextProps?.className || '']:
            rest.FormHelperTextProps?.className,
        }),
      }}
      InputProps={{
        ...rest.InputProps,
        ref,
        className: cn('h-14 w-full', {
          [rest.InputProps?.className || '']: rest.InputProps?.className,
        }),
        endAdornment: withSearch && (
          <SearchIcon color='disabled' className='cursor-pointer' />
        ),
      }}
      InputLabelProps={{
        ...rest.InputLabelProps,
        className: cn('!top-1', {
          [rest.InputLabelProps?.className || '']:
            rest.InputLabelProps?.className,
        }),
      }}
    />
  )
})
