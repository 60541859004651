import Mock from 'mockjs'
import { rest } from 'msw'
import { addBaseURL } from '../helpers'

export const confirmation = [
  rest.post(addBaseURL('/confirm-code'), (req, res, ctx) => {
    const body = req.body as { code: string }

    if (!body.code) {
      return res(
        ctx.status(400),
        ctx.json({
          message: 'Invalid code',
        })
      )
    }

    return res(
      ctx.status(201),
      ctx.json(
        Mock.mock({
          username: '@word',
          message: '@title(1,3)',
        })
      )
    )
  }),
]
