export const INTERNAL_TRANSACTION_TYPE_HOLDING = 'holding'
export const INTERNAL_TRANSACTION_TYPE_WITHDRAW = 'withdraw'
export const INTERNAL_TRANSACTION_TYPE_REFUND = 'refund'

export const WALLETS_COLLECTION_REQUEST_TARGET = 'wallets'

export const WALLETS_COLLECTION_PRIMARY_KEY = [
  WALLETS_COLLECTION_REQUEST_TARGET,
  'wallets-collection',
]
