import { FC } from 'react'
import { PreAdminContent } from 'shared/ui'
import { useTranslate } from 'react-admin'

export interface SuccessfulUserRegistrationProps {
  email?: string
}

export const SuccessfulUserRegistration: FC<
  SuccessfulUserRegistrationProps
> = ({ email }) => {
  const t = useTranslate()
  return (
    <PreAdminContent
      title={t('create_account.successful_user_registration.title')}
      description={
        <>
          {t('create_account.successful_user_registration.text') + ' '}
          <span className='text-primary'>{email}</span>
        </>
      }
      buttonText={t('create_account.successful_user_registration.got_it')}
      redirectLink='/login'
    />
  )
}
