import { FC } from 'shared/@types'
import Button from '@mui/material/Button'
import { useLocaleState } from 'react-admin'
import { LANG_EN, LANG_RU } from 'shared/config'
import cn from 'classnames'

export const LocaleSwitcher: FC = ({ className }) => {
  const [locale, setLocale] = useLocaleState()
  function handleSetLocale() {
    let newLocale
    locale === LANG_RU ? (newLocale = LANG_EN) : (newLocale = LANG_RU)
    setLocale(newLocale)
    localStorage.setItem('locale', newLocale)
    window.location.reload()
  }
  return (
    <Button onClick={handleSetLocale} className={cn('!text-white', className)}>
      <span
        className={cn('font-thin', {
          '!font-bold underline': locale === LANG_RU,
        })}
      >
        {LANG_RU.toUpperCase()}
      </span>
      /
      <span
        className={cn('font-thin', {
          '!font-bold underline': locale === LANG_EN,
        })}
      >
        {LANG_EN.toUpperCase()}
      </span>
    </Button>
  )
}
