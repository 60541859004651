import { FC } from 'react'
import {
  AutocompleteInput,
  AutocompleteInputProps,
  ReferenceInput,
} from 'react-admin'
import cn from 'classnames'

export interface CustomAutocompleteInputProps extends AutocompleteInputProps {
  source: string
  reference: string
  link?: boolean
  className?: string
}

export const CustomAutoCompleteInput: FC<CustomAutocompleteInputProps> = ({
  className = '',
  source,
  reference,
  label = '',
  fullWidth = true,
  link,
  emptyText = '',
  ...rest
}) => {
  return (
    <ReferenceInput
      key={source + reference}
      source={source}
      reference={reference}
      helperText={false}
      emptyText={emptyText}
      link={link}
      fullWidth
    >
      <AutocompleteInput
        label={label}
        variant='filled'
        fullWidth={fullWidth}
        {...rest}
        source={source}
        className={cn('!mt-0 w-full h-fit', {
          [className]: className,
        })}
        TextFieldProps={{
          ...rest.TextFieldProps,
          className: cn('', {
            [rest.TextFieldProps?.className || '']:
              rest.TextFieldProps?.className,
          }),
        }}
        ListboxProps={{
          ...rest.ListboxProps,
          className: cn('', {
            [rest.ListboxProps?.className || '']: rest.ListboxProps?.className,
          }),
        }}
      />
    </ReferenceInput>
  )
}
