import React from 'react'
import ReactDOM from 'react-dom/client'
import App from 'app'
import { API_MOCKING } from 'shared/config'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const prepare = async () => {
  if (API_MOCKING === 'enabled') {
    const { worker } = require('app/mock-server/browser')
    await worker.start({
      waitUntilReady: true,
    })
  }

  return Promise.resolve()
}

prepare().then(() =>
  root.render(
    /**
     * Вот эта штука(StrictMode) ищет потенциальные проблемы
     * в 18 реакте вызывает двойной первый рендер
     * только в деве, так что убирать не надо
     **/
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
)
