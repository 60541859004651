import { httpClient } from 'shared/http-client'
import { AxiosRequestConfig } from 'axios'
import { User } from 'entities/users'
import { RegistrationFormData } from './types'

export const signUp = (config: AxiosRequestConfig<RegistrationFormData>) =>
  httpClient<User, RegistrationFormData>({
    ...config,
    url: '/users',
    method: 'POST',
  })
