import { Layout as MUILayout, LayoutComponent } from 'react-admin'
import { Header } from './header'
import { Menu } from './menu'
import { Sidebar } from './sidebar'
import { Title } from './titles'

const Layout: LayoutComponent = props => {
  return (
    <MUILayout
      {...props}
      appBar={Header}
      menu={Menu}
      sidebar={Sidebar}
      title={<Title />}
    />
  )
}

export default Layout
