import { Translate } from 'ra-core'
import * as yup from 'yup'

export const loginSchema = (t: Translate) =>
  yup
    .object({
      username: yup.string().required(t('login_page.login_req')),
      password: yup.string().required(t('login_page.password_req')),
    })
    .required()
