import Mock from 'mockjs'
import { rest } from 'msw'
import { addBaseURL } from '../helpers'

export const resetPassword = [
  rest.post(addBaseURL('/password/reset'), (req, res, ctx) => {
    const body = req.body
    return res(
      ctx.status(201),
      ctx.json(
        Mock.mock({
          message: 'ok',
          type: 'email',
          ...(body as object),
        })
      )
    )
  }),
]
