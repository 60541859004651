import { httpClient } from 'shared/http-client'
import { DISMISS_EXTERNAL_TRANSACTION_TARGET } from './constants'
import { ExternalTransaction } from './types'

export const dismissExternalTransaction = async (transactionId: number) =>
  httpClient<
    Pick<ExternalTransaction, 'transactionId'>,
    { transactionId: number }
  >({
    url: DISMISS_EXTERNAL_TRANSACTION_TARGET,
    data: { transactionId },
    method: 'POST',
  })
