import {
  Children,
  cloneElement,
  FormHTMLAttributes,
  isValidElement,
  ReactNode,
  useState,
} from 'react'
import {
  FieldErrors,
  FieldValues,
  FormProvider,
  useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ObjectSchema, AnySchema } from 'yup'
import merge from 'lodash.merge'
import { useFormErrors } from 'shared/hooks'

type FormChildren<T extends FieldValues> =
  | ReactNode
  | ReactNode[]
  | ((methods: UseFormReturn<T> & { isLoading: boolean }) => ReactNode)

export interface FormProps<T extends FieldValues>
  extends Omit<
    FormHTMLAttributes<HTMLFormElement>,
    'onSubmit' | 'onError' | 'children'
  > {
  children?: FormChildren<T>
  validationSchema?: ObjectSchema<Record<Partial<keyof T>, AnySchema>, object>
  params?: UseFormProps<T>
  className?: string
  onSubmit: (data: T, methods?: UseFormReturn<T>) => Promise<unknown> | void
  onError?: (
    errors?: FieldErrors<T>,
    methods?: UseFormReturn<T>
  ) => Promise<unknown> | void
}

export const Form = <TFormValues extends FieldValues>({
  children,
  validationSchema,
  params = {},
  className = '',
  onSubmit,
  onError,
  ...rest
}: FormProps<TFormValues>) => {
  const methods = useForm({
    ...params,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  })
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = methods

  const { getErrorByName } = useFormErrors(errors)
  const [isLoading, setIsLoading] = useState(false)

  const onFormSubmit = async (data: TFormValues) => {
    try {
      setIsLoading(true)
      await onSubmit(data, methods)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setIsLoading(false)
    }
  }

  const normalizeChildren = (
    childs?: ReactNode | ReactNode[]
  ): ReactNode | ReactNode[] | undefined => {
    return Children.map(childs, child => {
      if (isValidElement(child)) {
        const name = child.props.name

        if (!name) {
          return cloneElement(
            child,
            child.props,
            normalizeChildren(child.props.children)
          )
        }

        return cloneElement(child, {
          ...child.props,
          ...getErrorByName(name),
          ...(!child.props.control && { ...register(name) }),
          key: name,
        })
      }
      return child
    })
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(
          data => onFormSubmit(data),
          data => onError?.(data, methods)
        )}
        className={className}
        {...rest}
        noValidate
      >
        {normalizeChildren(
          typeof children === 'function'
            ? children(merge(methods, { isLoading }))
            : children
        )}
      </form>
    </FormProvider>
  )
}
