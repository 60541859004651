import { PreAdminContent } from 'shared/ui'

export const SuccessfullResetPassword = () => {
  return (
    <PreAdminContent
      title='Great!'
      description='Password recovered successfully'
      buttonText='Continue'
      redirectLink='/login'
    />
  )
}
