import {
  FunctionField,
  ReferenceField,
  DateField,
  SimpleList,
  useListContext,
  useSidebarState,
  TextField,
} from 'react-admin'
import { CustomMuiSelect } from 'shared/ui'
import { SORT_ASC, SORT_DESC } from 'shared/config'
import { FC } from 'react'
import { UserInfoComponent } from 'entities/users/ui'
import { UserInfo } from 'entities/users'
import { Wallet } from 'entities/transactions'
import { ConfirmExternalTransactionButtons } from './confirm-external-transaction-buttons'
import {
  ExternalTransaction,
  EXTERNAL_TRANSACTION_STATUSES,
  EXTERNAL_TRANSACTION_STATUS_NEW,
} from '../model'
import { CURRENCY_NAME } from 'entities/company'
import { useTranslate } from 'react-admin'

export const TransactionsMobile: FC = () => {
  const { setSort } = useListContext()
  const [open] = useSidebarState()
  const t = useTranslate()

  if (open) return null
  return (
    <>
      <CustomMuiSelect
        label={t('external_transactions.sort_by_creation_date')}
        fullWidth
        onChange={e =>
          setSort({ field: 'dateCreate', order: e.target.value as string })
        }
        choices={[
          { id: SORT_DESC, name: SORT_DESC },
          { id: SORT_ASC, name: SORT_ASC },
        ]}
      />
      <SimpleList
        linkType={false}
        primaryText={() => (
          <div className='mb-4'>
            {t('external_transactions.table.wallet_to') + ' '}
            <TextField label='Wallet to' source='addressTo' sortable={false} />
          </div>
        )}
        secondaryText={() => (
          <div className='grid grid-cols-4 gap-3 laptop:grid-cols-2 tablet:grid-cols-1'>
            <div className='flex items-center gap-2 text-center'>
              {t('external_transactions.table.company')}:
              <ReferenceField
                label='User'
                source='user[@id]'
                reference='users'
                link={false}
                sortable={false}
              >
                <TextField source='company.name' />
              </ReferenceField>
            </div>
            <div className='flex items-center gap-2 text-center'>
              {t('external_transactions.table.user')}:
              <ReferenceField
                label='User'
                source='user[@id]'
                reference='users'
                link={false}
                sortable={false}
              >
                <FunctionField
                  sortable={false}
                  render={(user: UserInfo) => <UserInfoComponent {...user} />}
                />
              </ReferenceField>
            </div>
            <div className='flex items-center gap-2 text-center'>
              {t('external_transactions.table.creation_date')}:
              <DateField
                label='Date&Time'
                source='dateCreate'
                options={{
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                }}
              />
            </div>
            <div className='flex items-center gap-2 text-center'>
              {t('external_transactions.table.status')}:
              <FunctionField
                label='Status'
                source='status'
                sortable={false}
                render={({ status }: ExternalTransaction) =>
                  t(
                    'external_transactions.statuses.' +
                      EXTERNAL_TRANSACTION_STATUSES[status]
                  )
                }
              />
            </div>
            <div className='flex items-center gap-2 text-center'>
              {t('external_transactions.table.sum')}:
              <FunctionField
                label='Sum'
                source='total'
                render={({ total }: Wallet) => `${total} ${CURRENCY_NAME}`}
              />
            </div>
            <FunctionField
              render={(transaction: ExternalTransaction) =>
                transaction.status === EXTERNAL_TRANSACTION_STATUS_NEW && (
                  <ConfirmExternalTransactionButtons
                    transactionId={transaction['@id'] as string}
                  />
                )
              }
            />
          </div>
        )}
      />
    </>
  )
}
