import { FC } from 'react'
import { NumberInput, NumberInputProps } from 'react-admin'
import cn from 'classnames'

export interface CustomNumberInputProps extends NumberInputProps {
  withSearch?: boolean
}

export const CustomNumberInput: FC<CustomNumberInputProps> = ({
  withSearch,
  ...rest
}) => {
  return (
    <NumberInput
      variant='filled'
      parse={value => value || 0}
      {...rest}
      FormHelperTextProps={{
        ...rest.FormHelperTextProps,
        className: cn('!mt-0', {
          [rest.FormHelperTextProps?.className || '']:
            rest.FormHelperTextProps?.className,
        }),
      }}
      InputProps={{
        ...rest.InputProps,
        className: cn('h-14 w-full', {
          [rest.InputProps?.className || '']: rest.InputProps?.className,
        }),
      }}
      InputLabelProps={{
        ...rest.InputLabelProps,
        className: cn('!top-1', {
          [rest.InputLabelProps?.className || '']:
            rest.InputLabelProps?.className,
        }),
      }}
    />
  )
}
