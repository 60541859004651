import {
  FunctionField,
  TextField,
  DateField,
  SimpleList,
  useListContext,
  useSidebarState,
} from 'react-admin'
import { getUserRole, User } from 'entities/users'
import {
  CustomActionButtons,
  CustomMuiSelect,
  CustomSelectedBar,
  CustomAllCheckboxSelected,
} from 'shared/ui'
import { getNumberFromString } from 'shared/helpers'
import { Checkbox } from '@mui/material'
import { SORT_ASC, SORT_DESC } from 'shared/config'
import { FC } from 'react'
import { useTranslate } from 'react-admin'

export const UserMobile: FC = () => {
  const { onToggleItem, setSort, selectedIds } = useListContext()
  const [open] = useSidebarState()
  const t = useTranslate()

  if (open) return null

  return (
    <>
      {!!selectedIds.length && <CustomSelectedBar />}
      <CustomMuiSelect
        className='!mt-1 mb-3'
        label={t('users.sort_by_id')}
        fullWidth
        onChange={e =>
          setSort({ field: 'id', order: e.target.value as string })
        }
        choices={[
          { id: SORT_DESC, name: SORT_DESC },
          { id: SORT_ASC, name: SORT_ASC },
        ]}
      />
      <CustomMuiSelect
        label={t('users.sort_by_creation_date')}
        fullWidth
        onChange={e =>
          setSort({ field: 'dateCreate', order: e.target.value as string })
        }
        choices={[
          { id: SORT_DESC, name: SORT_DESC },
          { id: SORT_ASC, name: SORT_ASC },
        ]}
      />
      <CustomAllCheckboxSelected />
      <SimpleList
        linkType={false}
        primaryText={record => (
          <div>
            <Checkbox
              onClick={() => onToggleItem(record.id)}
              checked={selectedIds.includes(record.id)}
            />
            {t('users.user_title')}{' '}
            <TextField label='Login' source='email' sortable={false} />
          </div>
        )}
        secondaryText={record => (
          <div className='grid grid-cols-2 ml-3 tablet:grid-cols-1'>
            <div>
              {t('users.filter.id')}:
              <FunctionField
                label={t('users.table.id')}
                source='id'
                render={() => getNumberFromString(String(record.id))}
              />
            </div>
            <div>
              {t('users.filter.role')}:
              <FunctionField
                label={t('users.table.role')}
                source='roles'
                render={() => getUserRole(record.roles)}
              />
            </div>
            <div>
              {t('users.filter.company')}:
              <FunctionField
                label={t('users.table.company')}
                source='company'
                render={() => record.company.name}
              />
            </div>
            <div>
              {t('users.table.creation_date')}:
              <DateField
                label={t('users.table.creation_date')}
                source='dateCreate'
                options={{
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                }}
              />
            </div>
            <CustomActionButtons<User>
              ShowButtonProps={{
                title: t('users.show.user_view'),
                showData: record => [
                  {
                    label: t('users.show.role'),
                    value: getUserRole(record.roles),
                  },
                  {
                    label: t('users.show.company_name'),
                    value: (
                      <FunctionField
                        label={t('users.table.company')}
                        source='company'
                        render={({ company }: User) => company.name}
                      />
                    ),
                  },
                  { label: t('users.edit.login'), value: record.email },
                  {
                    label: t('users.show.external_login_account'),
                    value: record.externalUsername,
                  },
                  { label: t('users.show.internal_wallet'), value: '' },
                  { label: t('users.show.extarnal_wallet'), value: '' },
                ],
              }}
            />
          </div>
        )}
      />
    </>
  )
}
