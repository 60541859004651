import { FC } from 'react'
import {
  ListBase,
  ListProps,
  Datagrid,
  FunctionField,
  ReferenceField,
  Pagination,
  DateInput,
  DateField,
  TextField,
  useAuthenticated,
} from 'react-admin'
import {
  CustomActionButtons,
  CustomActionList,
  CustomAutoCompleteInput,
  CustomSelectInput,
  CustomTextInput,
} from 'shared/ui'
import { Helmet } from 'react-helmet'
import { WithdrawComponent } from 'features/withdraw'
import { UserInfoComponent } from 'entities/users/ui'
import { useMediaTheme } from 'shared/hooks'
import { TransactionsMobile } from './external-transactions-mobile'
import { UserInfo } from 'entities/users'
import {
  TRANSACTION_STATUSES,
  TRANSACTION_STATUS_CANCEL,
  TRANSACTION_STATUS_COMPLETED,
  TRANSACTION_STATUS_ERROR,
  TRANSACTION_STATUS_IN_PROCESS,
  TRANSACTION_STATUS_IN_QUEUE,
  TRANSACTION_STATUS_NEW,
  Wallet,
} from 'entities/transactions'
import {
  ExternalTransaction,
  EXTERNAL_TRANSACTION_STATUSES,
  EXTERNAL_TRANSACTION_STATUS_NEW,
} from '../model'
import { ConfirmExternalTransactionButtons } from './confirm-external-transaction-buttons'
import { SORT_DESC } from 'shared/config'
import { CURRENCY_NAME } from 'entities/company'
import { useTranslate } from 'react-admin'

const TransactionsList: FC<ListProps> = props => {
  const t = useTranslate()

  useAuthenticated()
  const { isMaxMedium } = useMediaTheme()

  return (
    <ListBase {...props} sort={{ field: 'id', order: SORT_DESC }} perPage={25}>
      <Helmet>
        <title>{t('external_transactions.external_transactions_title')}</title>
      </Helmet>
      <CustomActionList
        title={t('external_transactions.external_transactions_title')}
        withCreateButton={false}
        filters={[
          <CustomTextInput
            key='addressTo'
            label='external_transactions.filter.wallet'
            source='addressTo'
          />,
          <CustomAutoCompleteInput
            key='referralSession.user'
            emptyText='All'
            label='external_transactions.filter.user'
            optionText='externalUsername'
            source='user'
            reference='users'
            className='!w-[160px]'
            filterToQuery={value => ({ externalUsername: value })}
          />,
          <CustomSelectInput
            key='status'
            label='external_transactions.filter.status'
            source='status'
            emptyText={t('external_transactions.filter.all')}
            className='tablet-small:!w-[160px]'
            choices={[
              {
                id: TRANSACTION_STATUS_NEW,
                name: t(
                  `external_transactions.statuses.${TRANSACTION_STATUSES[TRANSACTION_STATUS_NEW]}`
                ),
              },
              {
                id: TRANSACTION_STATUS_IN_QUEUE,
                name: t(
                  `external_transactions.statuses.${TRANSACTION_STATUSES[TRANSACTION_STATUS_IN_QUEUE]}`
                ),
              },
              {
                id: TRANSACTION_STATUS_IN_PROCESS,
                name: t(
                  `external_transactions.statuses.${TRANSACTION_STATUSES[TRANSACTION_STATUS_IN_PROCESS]}`
                ),
              },
              {
                id: TRANSACTION_STATUS_COMPLETED,
                name: t(
                  `external_transactions.statuses.${TRANSACTION_STATUSES[TRANSACTION_STATUS_COMPLETED]}`
                ),
              },
              {
                id: TRANSACTION_STATUS_ERROR,
                name: t(
                  `external_transactions.statuses.${TRANSACTION_STATUSES[TRANSACTION_STATUS_ERROR]}`
                ),
              },
              {
                id: TRANSACTION_STATUS_CANCEL,
                name: t(
                  `external_transactions.statuses.${TRANSACTION_STATUSES[TRANSACTION_STATUS_CANCEL]}`
                ),
              },
            ]}
          />,
          <DateInput
            key='creationDateAfter'
            source='dateCreate.after'
            label='external_transactions.filter.creation_date_after'
          />,
          <DateInput
            key='creationDateBefore'
            source='dateCreate.before'
            label='external_transactions.filter.creation_date_before'
          />,
        ]}
      />
      <>
        {isMaxMedium ? (
          <>
            <CustomActionButtons
              hasEdit={false}
              hasDelete={false}
              hasShow={false}
              hasWithdraw={true}
              WithdrawButtonProps={{
                title: t('external_transactions.withdraw_all'),
                label: t('external_transactions.withdraw_all'),
                showData: () => [
                  {
                    label: t('external_transactions.total'),
                    value: <WithdrawComponent total />,
                  },
                  {
                    label: t('external_transactions.fee_cost'),
                    value: <WithdrawComponent />,
                  },
                ],
              }}
            />
            <TransactionsMobile />
          </>
        ) : (
          <Datagrid bulkActionButtons={false}>
            <TextField
              label='external_transactions.table.wallet_to'
              source='addressTo'
              sortable={false}
            />
            <ReferenceField
              label='external_transactions.table.company'
              source='user[@id]'
              reference='users'
              link={false}
              sortable={false}
            >
              <TextField source='company.name' />
            </ReferenceField>
            <ReferenceField
              label='external_transactions.table.user'
              source='user[@id]'
              reference='users'
              link={false}
              sortable={false}
            >
              <FunctionField
                sortable={false}
                render={(user: UserInfo) => <UserInfoComponent {...user} />}
              />
            </ReferenceField>
            <DateField
              label='external_transactions.table.date_time'
              source='dateCreate'
              options={{
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }}
            />
            <FunctionField
              label='external_transactions.table.status'
              source='status'
              sortable={false}
              render={({ status }: ExternalTransaction) =>
                t(
                  `external_transactions.statuses.${EXTERNAL_TRANSACTION_STATUSES[status]}`
                )
              }
            />
            <FunctionField
              label='external_transactions.table.sum'
              source='total'
              render={({ total }: Wallet) => `${total} ${CURRENCY_NAME}`}
            />
            <FunctionField
              render={(transaction: ExternalTransaction) =>
                transaction.status === EXTERNAL_TRANSACTION_STATUS_NEW && (
                  <ConfirmExternalTransactionButtons
                    transactionId={transaction['@id'] as string}
                  />
                )
              }
            />
          </Datagrid>
        )}
      </>
      <Pagination />
    </ListBase>
  )
}

export default TransactionsList
