import { FC, useEffect, useState } from 'react'
import { TransactionFeeResponse } from '../lib'
import { getTransactionFee } from '../model'
export interface WithdrawComponentProps {
  total?: boolean
}

export const WithdrawComponent: FC<WithdrawComponentProps> = ({ total }) => {
  const [data, setData] = useState<TransactionFeeResponse>()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    const getTransaction = async () => {
      const { data } = await getTransactionFee()
      setData(data)
      setLoading(false)
    }
    getTransaction()
  }, [])
  return <>{loading ? 'loading...' : total ? data?.total : data?.feeCost}</>
}
