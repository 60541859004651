import { useState } from 'react'
import { Button } from '@mui/material'
import { CheckboxGroupInput, useNotify } from 'react-admin'
import { CustomPasswordInput, CustomTextField } from 'shared/ui'
import { Form, onSubmit } from 'shared/libs'
import { registrationSchema, serverSideUsersRegisterValidation } from '../lib'
import { Link } from 'react-router-dom'
import { RegistrationFormData, signUp } from '../model'
import { LoadingButton } from '@mui/lab'
import { SuccessfulUserRegistration } from './successful-user-registration'
import { useTranslate } from 'react-admin'

export const RegistrationForm = () => {
  const t = useTranslate()

  const notify = useNotify()
  const [hasUserRegistered, setHasUserRegistered] = useState(false)
  return (
    <Form<RegistrationFormData>
      validationSchema={registrationSchema(t)}
      onSubmit={data =>
        onSubmit(data)({
          submitRequest: signUp,
          notify,
          onSuccess: () => {
            setHasUserRegistered(true)
            notify(t('create_account.successful_user_registration.success'), {
              type: 'success',
            })
          },
          onError: () => serverSideUsersRegisterValidation(notify, t),
        })
      }
      className={
        'flex flex-col items-center bg-white p-big rounded tablet:max-w-[400px] tablet:w-full tablet-small:max-w-[350px] tablet-small:w-full'
      }
    >
      {({ watch, isLoading }) =>
        hasUserRegistered ? (
          <SuccessfulUserRegistration email={watch('email')} />
        ) : (
          <>
            <p className='text-black text-title mb-base'>
              {t('create_account.create_account_title')}
            </p>
            <div
              className={`grid grid-cols-2 gap-x-5 mb-big 
          [&>div]:min-h-[66px] [&>div]:h-fit [&>div]:justify-end [&>div>label:not(.Mui-error)]:!top-[14px]`}
            >
              <CustomTextField
                name='company.name'
                variant='standard'
                label={t('create_account.company_name')}
              />
              <CustomTextField
                name='company.description'
                variant='standard'
                label={t('create_account.company_description')}
              />
              <CustomTextField
                name='company.siteUrl'
                variant='standard'
                label={t('create_account.url_site')}
              />
              <CustomTextField
                name='email'
                variant='standard'
                label={t('create_account.email')}
              />
              <CustomTextField
                name='firstName'
                variant='standard'
                label={t('create_account.name')}
              />
              <CustomTextField
                name='lastName'
                variant='standard'
                label={t('create_account.lastname')}
              />
              <CustomTextField
                name='phone'
                variant='standard'
                label={t('create_account.phone_number')}
              />
              <CustomTextField
                name='username'
                variant='standard'
                label={t('create_account.login')}
              />
              <CustomPasswordInput
                label={t('create_account.password')}
                source='plainPassword'
                autoComplete='new-password'
              />
              <CustomPasswordInput
                label={t('create_account.repeat_password')}
                source='repeatPassword'
              />
            </div>
            <CheckboxGroupInput
              hiddenLabel
              label=''
              helperText={false}
              source='privacy'
              defaultValue={[]}
              choices={[
                {
                  id: 'accept',
                  name: t('create_account.personal_data'),
                },
              ]}
              className='!mt-0 !mb-big [&_.MuiFormControlLabel-label]:text-subtext'
            />
            <div className='flex items-center gap-base'>
              <Link to='/login'>
                <Button
                  variant='contained'
                  color='secondary'
                  className='w-button'
                  disabled={isLoading}
                >
                  {t('create_account.cancel')}
                </Button>
              </Link>
              <LoadingButton
                type='submit'
                variant='contained'
                className='w-button'
                loading={isLoading}
              >
                {t('create_account.next')}
              </LoadingButton>
            </div>
          </>
        )
      }
    </Form>
  )
}
