import { FC } from 'react'
import {
  ListBase,
  ListProps,
  Datagrid,
  FunctionField,
  ReferenceField,
  Pagination,
  DateInput,
  DateField,
  useAuthenticated,
  TextField,
} from 'react-admin'
import {
  CustomActionList,
  CustomSelectInput,
  CustomAutoCompleteInput,
} from 'shared/ui'
import { Helmet } from 'react-helmet'
import {
  Wallet,
  Transaction,
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_WEBHOOK,
  TRANSACTION_TYPE_PAYOUT,
} from '../model'
import { UserInfoComponent } from 'entities/users'
import { useMediaTheme } from 'shared/hooks'
import { TransactionsMobile } from './transactions-mobile'
import { UserInfo } from 'entities/users'
import { SORT_DESC } from 'shared/config'
import { INTERNAL_TRANSACTION_TYPE_WITHDRAW } from '../lib'
import { CURRENCY_NAME } from 'entities/company'
import { useTranslate } from 'react-admin'

const TransactionsList: FC<ListProps> = props => {
  const t = useTranslate()

  useAuthenticated()
  const { isMaxMedium } = useMediaTheme()

  return (
    <ListBase
      {...props}
      sort={{ field: 'id', order: SORT_DESC }}
      filterDefaultValues={{ type: INTERNAL_TRANSACTION_TYPE_WITHDRAW }}
      perPage={25}
    >
      <Helmet>
        <title>{t('internal_transactions.transaction_history')}</title>
      </Helmet>
      <CustomActionList
        title={t('internal_transactions.transaction_history')}
        withCreateButton={false}
        filters={[
          <CustomSelectInput
            key='type'
            label='internal_transactions.filter.type'
            source='referralEvent.type'
            emptyText='All'
            className='tablet-small:!w-[160px]'
            choices={[
              {
                id: TRANSACTION_TYPE_WEBHOOK,
                name: TRANSACTION_TYPES[TRANSACTION_TYPE_WEBHOOK],
              },
              {
                id: TRANSACTION_TYPE_PAYOUT,
                name: TRANSACTION_TYPES[TRANSACTION_TYPE_PAYOUT],
              },
            ]}
          />,
          <CustomAutoCompleteInput
            key='arbitrageUser'
            emptyText='All'
            label='internal_transactions.filter.arbitrage_user'
            optionText='externalUsername'
            source='referralSession.arbitrageUser'
            reference='users'
            className='!w-[160px]'
            filterToQuery={value => ({ externalUsername: value })}
          />,
          <CustomAutoCompleteInput
            key='user'
            emptyText='All'
            label='internal_transactions.filter.user'
            optionText='externalUsername'
            source='referralSession.user'
            reference='users'
            className='!w-[160px]'
            filterToQuery={value => ({ externalUsername: value })}
          />,
          <DateInput
            key='creationDateAfter'
            source='dateTransaction.after'
            label='internal_transactions.filter.creation_date_after'
          />,
          <DateInput
            key='creationDateBefore'
            source='dateTransaction.before'
            label='internal_transactions.filter.creation_date_before'
          />,
        ]}
      />
      <>
        {isMaxMedium ? (
          <TransactionsMobile />
        ) : (
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              label='internal_transactions.table.type'
              source='referralEvent'
              sortable={false}
              render={({ referralEvent }: Transaction) =>
                TRANSACTION_TYPES[referralEvent?.type] || ''
              }
            />
            <ReferenceField
              label='internal_transactions.table.arbitrage_user'
              source='referralSession.arbitrageUser[@id]'
              reference='users'
              link={false}
              sortable={false}
            >
              <FunctionField
                sortable={false}
                render={(user: UserInfo) => <UserInfoComponent {...user} />}
              />
            </ReferenceField>
            <FunctionField
              label='internal_transactions.table.event_type'
              source='referralEvent'
              sortable={false}
              render={({ referralEvent }: Transaction) =>
                referralEvent?.name || ''
              }
            />
            <ReferenceField
              label='internal_transactions.table.company'
              source='referralSession[@id]'
              reference='referral_sessions'
              link={false}
              sortable={false}
            >
              <ReferenceField
                source='user'
                reference='users'
                link={false}
                sortable={false}
              >
                <TextField source='company.name' />
              </ReferenceField>
            </ReferenceField>
            <ReferenceField
              label='internal_transactions.table.user'
              source='referralSession[@id]'
              reference='referral_sessions'
              link={false}
              sortable={false}
            >
              <ReferenceField
                source='user'
                reference='users'
                link={false}
                sortable={false}
              >
                <FunctionField
                  sortable={false}
                  render={(user: UserInfo) => <UserInfoComponent {...user} />}
                />
              </ReferenceField>
            </ReferenceField>
            <FunctionField
              label='internal_transactions.table.sum'
              source='total'
              render={({ total }: Wallet) => `${total} ${CURRENCY_NAME}`}
            />
            <DateField
              label='internal_transactions.table.creation_date'
              source='dateTransaction'
              options={{
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }}
            />
          </Datagrid>
        )}
      </>
      <Pagination />
    </ListBase>
  )
}

export default TransactionsList
