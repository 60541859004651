import { useParams } from 'react-router-dom'
import { Form, onSubmit } from 'shared/libs'
import { CustomPasswordInput, PreAdminContent } from 'shared/ui'
import { newPasswordSchema } from '../lib'
import {
  resetPasswordConfirmation,
  ResetPasswordConfirmationFormData,
} from '../model'
import { useState } from 'react'
import { SuccessfullResetPassword } from './successful-reset-password'

export const NewPasswordPage = () => {
  const { code } = useParams()
  const [isConfirmed, setIsConfirmed] = useState(false)

  if (isConfirmed) {
    return <SuccessfullResetPassword />
  }

  return (
    <Form<ResetPasswordConfirmationFormData>
      validationSchema={newPasswordSchema}
      onSubmit={data =>
        onSubmit(data)({
          submitRequest: () =>
            resetPasswordConfirmation({ data: { ...data, code: code! } }),
          onSuccess: () => setIsConfirmed(true),
        })
      }
    >
      <PreAdminContent
        title='Password recovery'
        description='Create a new password'
        buttonText='Apply'
      >
        <>
          <CustomPasswordInput
            label='Password'
            source='newPassword'
            className='!mb-base'
          />
          <CustomPasswordInput
            label='Repeat password'
            source='repeatPassword'
            className='!mb-big'
          />
        </>
      </PreAdminContent>
    </Form>
  )
}
