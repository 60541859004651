import { AxiosRequestConfig } from 'axios'
import { User } from 'entities/users'
import { httpClient } from 'shared/http-client'
import { USERS_CURRENT_PATH } from '../lib'

export const getCurrentViewer = async () => {
  try {
    const { data: user } = await httpClient<User, AxiosRequestConfig>({
      url: USERS_CURRENT_PATH,
    })
    localStorage.setItem(
      'referral_admin_current_user',
      JSON.stringify({
        user,
      })
    )
    return user
  } catch (err) {
    window.history.pushState(null, '', '/admin#/login')
    window.location.reload()
    return err
  }
}
