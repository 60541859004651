import { FC, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import {
  ExternalTransactionActionType,
  EXTERNAL_TRANSACTION_ACTION_TYPE,
  EXTERNAL_TRANSACTION_DISMISS_TYPE,
} from '../model'
import { useModalState } from 'shared/hooks'
import { Nullable } from 'shared/@types'
import { ConfirmExternalTransaction } from './confirm-external-transaction'
import { useTranslate } from 'react-admin'

export interface ConfirmExternalTransactionButtonsProps {
  transactionId: string
}

export const ConfirmExternalTransactionButtons: FC<
  ConfirmExternalTransactionButtonsProps
> = ({ transactionId }) => {
  const t = useTranslate()

  const { isOpen, onOpen, onClose } = useModalState()
  const [transactionActionData, setTransactionActionData] = useState<
    Nullable<{
      transactionId: string
      type: ExternalTransactionActionType
      isForce?: boolean
    }>
  >(null)

  useEffect(() => {
    transactionActionData?.transactionId && onOpen()
  }, [transactionActionData])

  return (
    <>
      <div className='flex gap-3 text-center [&_button]:w-20 [&_button]:h-[30px] '>
        <Button
          variant='contained'
          onClick={() =>
            setTransactionActionData({
              transactionId,
              type: EXTERNAL_TRANSACTION_ACTION_TYPE,
            })
          }
        >
          {t('external_transactions.table.accept')}
        </Button>
        <Button
          variant='contained'
          color='secondary'
          onClick={() =>
            setTransactionActionData({
              transactionId,
              type: EXTERNAL_TRANSACTION_DISMISS_TYPE,
            })
          }
        >
          {t('external_transactions.table.dismiss')}
        </Button>
      </div>
      <ConfirmExternalTransaction
        transactionId={transactionActionData?.transactionId}
        type={transactionActionData?.type}
        open={isOpen}
        onClose={onClose}
        isForce={transactionActionData?.isForce}
      />
    </>
  )
}
