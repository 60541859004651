import {
  auth,
  users,
  wallets,
  initial,
  companies,
  entrypoint,
  docsldJSON,
  confirmation,
  resetPassword,
  referral_systems,
  transactions,
  externalTransactions,
} from './routes'

export const handlers = [
  ...auth,
  ...users,
  ...wallets,
  ...initial,
  ...companies,
  ...entrypoint,
  ...docsldJSON,
  ...referral_systems,
  ...transactions,
  ...resetPassword,
  ...confirmation,
  ...externalTransactions,
]
