import { FC, useEffect, useState } from 'react'
import { Button, ButtonProps } from '@mui/material'
import { getTwoDigitsNumber } from 'shared/helpers'

export interface ButtonWithDelayProps extends ButtonProps {
  label: string
  labelAfterDelay: string
  delay: number
  onClick?: () => Promise<void> | void
}

export const ButtonWithDelay: FC<ButtonWithDelayProps> = ({
  label,
  labelAfterDelay,
  delay,
  onClick,
  ...rest
}) => {
  const [seconds, setSeconds] = useState(delay)

  useEffect(() => {
    const interval = setInterval(() => setSeconds(prev => prev && --prev), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const formatedDelay = `${getTwoDigitsNumber(
    Math.floor(seconds / 60)
  )}:${getTwoDigitsNumber(seconds % 60)}`

  return (
    <Button
      type='button'
      variant='contained'
      disabled={!!seconds}
      onClick={async () => {
        await onClick?.()
        setSeconds(delay)
      }}
      {...rest}
    >
      {seconds ? `${label} ${formatedDelay}` : labelAfterDelay}
    </Button>
  )
}
