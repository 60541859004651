import {
  DateField,
  SimpleList,
  useListContext,
  FunctionField,
  useSidebarState,
  TextField,
  ReferenceField,
} from 'react-admin'
import {
  CustomActionButtons,
  CustomMuiSelect,
  CustomSelectedBar,
  CustomAllCheckboxSelected,
} from 'shared/ui'
import { Checkbox } from '@mui/material'
import { SORT_ASC, SORT_DESC } from 'shared/config'
import { FC } from 'react'
import { Company, CURRENCY_NAME } from '../lib'
import { getNumberFromString } from 'shared/helpers'
import { WalletCollectionItem } from 'entities/transactions'
import { useTranslate } from 'react-admin'

export const CompanyMobile: FC = () => {
  const { onToggleItem, setSort, selectedIds } = useListContext()
  const [open] = useSidebarState()
  const t = useTranslate()
  if (open) return null
  return (
    <>
      {!!selectedIds.length && <CustomSelectedBar withDelete={false} />}
      <CustomMuiSelect
        label={t('companies.sort_by_id')}
        fullWidth
        onChange={e =>
          setSort({ field: 'id', order: e.target.value as string })
        }
        choices={[
          { id: SORT_DESC, name: SORT_DESC },
          { id: SORT_ASC, name: SORT_ASC },
        ]}
      />
      <CustomAllCheckboxSelected />
      <SimpleList
        linkType={false}
        primaryText={record => (
          <div className='flex items-center gap-2'>
            <Checkbox
              onClick={() => onToggleItem(record.id)}
              checked={selectedIds.includes(record.id)}
            />
            {t('companies.filter.id')}:{' '}
            <FunctionField
              label={t('companies.filter.id')}
              sortable
              sortBy='id'
              render={({ id }: Company) => getNumberFromString(id as string)}
            />
          </div>
        )}
        secondaryText={() => (
          <>
            <div className='grid grid-cols-4 gap-2 ml-3 laptop:grid-cols-2 tablet:grid-cols-1'>
              <div>
                {t('companies.filter.company')}:{' '}
                <TextField
                  label={t('companies.filter.company')}
                  source='name'
                />
              </div>
              <div>
                {t('companies.table.balance')}:
                <FunctionField
                  label={t('companies.table.balance')}
                  render={({ balance }: Company) =>
                    `${balance} ${CURRENCY_NAME}`
                  }
                />
              </div>
              <div>
                {t('companies.table.activation')} :{' '}
                <ReferenceField
                  label={t('companies.table.activation')}
                  source='wallet[0].@id'
                  reference='wallets'
                  link={false}
                  sortable={false}
                >
                  <FunctionField
                    sortable={false}
                    render={({ activated }: WalletCollectionItem) =>
                      activated
                        ? t('companies.table.isActivated')
                        : t('companies.table.isNotActivated')
                    }
                  />
                </ReferenceField>
              </div>
              <div>
                {t('companies.table.creation_date')}:
                <DateField
                  sortable
                  label={t('companies.table.creation_date')}
                  source='dateCreate'
                  options={{
                    month: 'numeric',
                    day: 'numeric',
                    year: 'numeric',
                  }}
                />
              </div>
              <FunctionField
                label=''
                render={() => (
                  <CustomActionButtons
                    hasEdit={false}
                    hasDelete={false}
                    hasShow={false}
                    hasWithdraw={false}
                    hasAuthCompany={true}
                    AuthCompanyButtonProps={{
                      title: t('companies.table.modal.signIn'),
                      label: t('companies.table.auth'),
                      variant: 'contained',
                      showData: record => [
                        {
                          label: t('companies.table.company'),
                          value: record.name,
                        },
                      ],
                    }}
                  />
                )}
              />
            </div>
          </>
        )}
      />
    </>
  )
}
