import { AxiosRequestConfig } from 'axios'
import { httpClient } from 'shared/http-client'
import { ResetPasswordFormData, ResetPasswordResponse } from './types'

export const resetPassword = (
  config: AxiosRequestConfig<ResetPasswordFormData>
) =>
  httpClient<ResetPasswordResponse, ResetPasswordFormData>({
    ...config,
    url: '/password/reset',
    method: 'POST',
  })
