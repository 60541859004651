import { FC } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectProps } from '@mui/material/Select'

export interface Choise {
  id: number | string
  name: string
}

export interface CustomMuiSelectProps extends SelectProps {
  label: string
  choices: Choise[]
}

export const CustomMuiSelect: FC<CustomMuiSelectProps> = ({
  label,
  choices,
  ...react
}) => {
  return (
    <FormControl variant='filled' className='!m-0' sx={{ m: 1 }}>
      <InputLabel id='demo-simple-select-filled-label'>{label}</InputLabel>
      <Select
        labelId='demo-simple-select-filled-label'
        id='demo-simple-select-filled'
        defaultValue={choices[0].id}
        {...react}
      >
        {choices.map(choise => (
          <MenuItem key={choise.id} value={choise.id}>
            {choise.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
