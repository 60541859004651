import { FC } from 'react'
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  SimpleForm,
  SimpleFormIterator,
  useEditContext,
  usePermissions,
  IconButtonWithTooltip,
  useNotify,
} from 'react-admin'
import {
  DEFAULT_REFERRAL_STEP,
  ReferralSystemRemoveButton,
  referralSystemSchema,
  preEditNormalizeReferralSystemData,
  ReferralSystems,
} from 'entities/referral-systems'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  CustomTextInput,
  CustomFormToolbar,
  CustomNumberInput,
  CustomAutoCompleteInput,
} from 'shared/ui'
import { Stack, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Permissions } from 'features/auth/basic'
import { useMediaTheme } from 'shared/hooks'
import { ROLE_ADMIN } from 'entities/users'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { BASE_URL } from 'shared/config'
import { CURRENCY_NAME } from 'entities/company'
import { useTranslate } from 'react-admin'

export interface ReferralSystemFormProps {
  isEdit?: boolean
}

export const ReferralSystemForm: FC<ReferralSystemFormProps> = ({ isEdit }) => {
  const t = useTranslate()

  const { record } = useEditContext<ReferralSystems>()
  const { permissions } = usePermissions<Permissions>()
  const { isBig, isMinMedium } = useMediaTheme()
  const notify = useNotify()

  if (!permissions) return null

  const isAdmin = permissions.roles.includes(ROLE_ADMIN)

  // Все кнопки/иконки/компоненты, которые имеют
  // `isEdit ||` или isEdit ? <></> : ... могут (и скорее всего вернутся)
  // вернуться в редактирование в будущем.
  return (
    <SimpleForm
      defaultValues={preEditNormalizeReferralSystemData(record)}
      toolbar={isEdit ? <></> : <CustomFormToolbar saveButtonLabel='save' />}
      resolver={yupResolver(referralSystemSchema(t))}
      warnWhenUnsavedChanges
      noValidate
    >
      <div className='grid w-full grid-cols-3 gap-5 mb-5 desktop-big:grid-cols-2 laptop:grid-cols-1'>
        <CustomTextInput
          label='payout_building.create.way_name'
          source='name'
          InputProps={{ className: 'w-input' }}
          disabled={isEdit}
        />
        <CustomTextInput
          label='payout_building.create.referral_address'
          source='targetLink'
          InputProps={{ className: 'w-input' }}
          disabled={isEdit}
        />
        {isAdmin ? (
          <CustomAutoCompleteInput
            TextFieldProps={{
              className: '!w-input',
            }}
            fullWidth={false}
            optionText='name'
            source='company[@id]'
            reference='companies'
            label='payout_building.create.company'
            disabled={!!record?.company}
            filterToQuery={value => ({ name: value })}
          />
        ) : (
          <CustomTextInput
            label='payout_building.create.company'
            source='company'
            disabled
            InputProps={{ className: 'w-input' }}
            defaultValue={permissions?.company}
            format={value => value?.name}
          />
        )}
      </div>
      <div className='flex items-baseline gap-2 mb-[55px]'>
        <CustomNumberInput
          label='payout_building.create.session_life_span'
          source='sessionLifeSpan'
          defaultValue={1}
          min={1}
          className='!mb-0'
          InputProps={{ className: 'w-input' }}
          disabled={isEdit}
        />
        <Tooltip
          title={t('payout_building.create.session_life_span_tooltip')}
          placement='top'
        >
          <InfoOutlinedIcon />
        </Tooltip>
      </div>
      <ArrayInput
        source='customReferralEvents'
        label=''
        className='!mt-0 !mb-[35px]'
        defaultValue={[DEFAULT_REFERRAL_STEP]}
      >
        <SimpleFormIterator
          getItemLabel={idx => t('payout_building.create.step') + ` ${++idx}`}
          disableReordering
          className={`[&>li:not(:last-child)]:!grid [&>li]:grid-cols-[repeat(auto-fill,_60px)] [&>li]:gap-y-large
            [&>li]:items-center [&>li]:gap-x-base [&>li>section]:row-start-2 
            [&>li>section]:col-span-full [&>li>div_p]:!text-title [&>li>div>div]:!mr-0
            [&>li>div>div]:!pt-0 [&>li>div_p]:!mr-0 [&>li>span]:!pt-0 [&>li]:!border-none
            [&>li:last-child]:mt-[15px] flex flex-col gap-y-large [&>li>section]:gap-y-large`}
          addButton={
            isEdit ? (
              <></>
            ) : (
              <Button variant='outlined' size='medium' startIcon={<AddIcon />}>
                {t('payout_building.create.add_step')}
              </Button>
            )
          }
          removeButton={isEdit ? <></> : <ReferralSystemRemoveButton />}
        >
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => (
              <>
                <Stack
                  direction={isMinMedium ? 'column' : 'row'}
                  columnGap='30px'
                >
                  <BooleanInput
                    label='payout_building.create.condition_must_not_be_met'
                    source={getSource?.('isNegation') || 'isNegation'}
                    helperText={false}
                    disabled={isEdit}
                  />
                  <BooleanInput
                    label='payout_building.create.add_cost'
                    source={getSource?.('hasCost') || 'hasCost'}
                    helperText={false}
                    disabled={isEdit}
                  />
                </Stack>
                <Stack
                  display='grid'
                  gridTemplateColumns={`repeat(${
                    !isBig ? '3' : `${isMinMedium ? '1' : '2'}`
                  }, 330px)`}
                  columnGap='20px'
                  rowGap='20px'
                >
                  <CustomTextInput
                    label='payout_building.create.step_name'
                    source={getSource?.('name') || 'name'}
                    fullWidth
                    helperText={false}
                    disabled={isEdit}
                  />
                  {isEdit && (
                    <div className='flex items-baseline gap-1'>
                      <CustomTextInput
                        label='payout_building.create.webhook_token'
                        source={getSource?.('webhookToken') || 'webhookToken'}
                        disabled
                        fullWidth
                        helperText={false}
                      />
                      <IconButtonWithTooltip
                        label='payout_building.create.copy'
                        size='small'
                        onClick={() =>
                          navigator.clipboard
                            .writeText(scopedFormData.webhookToken)
                            .then(() =>
                              notify(t('dialog.copied'), { type: 'success' })
                            )
                        }
                      >
                        <ContentCopyIcon
                          color='primary'
                          className='!text-title'
                        />
                      </IconButtonWithTooltip>
                    </div>
                  )}
                  {isEdit && (
                    <div className='flex items-baseline gap-1'>
                      <CustomTextInput
                        label='payout_building.create.webhook_url'
                        source={getSource?.('webhookUrl') || 'webhookUrl'}
                        disabled
                        fullWidth
                        format={() => BASE_URL + '...'}
                        helperText={false}
                      />
                      <IconButtonWithTooltip
                        label='payout_building.create.copy'
                        size='small'
                        onClick={() =>
                          navigator.clipboard
                            .writeText(
                              `${BASE_URL}/referral-webhooks/${scopedFormData.webhookUrl}`
                            )
                            .then(() =>
                              notify(t('dialog.copied'), { type: 'success' })
                            )
                        }
                      >
                        <ContentCopyIcon
                          color='primary'
                          className='!text-title'
                        />
                      </IconButtonWithTooltip>
                    </div>
                  )}
                  {scopedFormData?.hasCost && (
                    <CustomNumberInput
                      label={
                        t('payout_building.create.cost') + `, ${CURRENCY_NAME}`
                      }
                      source={getSource?.('sum') || 'sum'}
                      fullWidth
                      defaultValue={0}
                      min={0}
                      helperText={false}
                      disabled={isEdit}
                    />
                  )}
                </Stack>
              </>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <p className='mb-5 text-black text-title'>
        {t('payout_building.create.compensation')}
      </p>
      <CustomNumberInput
        InputProps={{ className: 'w-input' }}
        label={t('payout_building.create.compensation') + `, ${CURRENCY_NAME}`}
        source='compensation'
        defaultValue={0}
        min={0}
        disabled={isEdit}
      />
      {isEdit || (
        <p className='mt-5 text-lg'>
          {t('payout_building.create.after_saving_ref_system')}
        </p>
      )}
    </SimpleForm>
  )
}
