import { ButtonProps, Link, useResourceContext } from 'react-admin'
import cn from 'classnames'
import { Button } from '@mui/material'
import { useTranslate } from 'react-admin'

export const CustomCancelButton = ({
  className = '',
  ...rest
}: ButtonProps) => {
  const t = useTranslate()

  const resource = useResourceContext()

  return (
    <Link to={`/${resource}`} className='contents' color='inherit'>
      <Button
        variant='contained'
        color='secondary'
        fullWidth
        className={cn(
          'max-w-[180px] !text-black !shadow-none tablet-small:w-full',
          className
        )}
        {...rest}
      >
        {t('toolbar.cancel')}
      </Button>
    </Link>
  )
}
