import { FC } from 'react'
import { PreAdminContent } from 'shared/ui'
import { useTranslate } from 'react-admin'
export interface SuccessfullSendEmailProps {
  email: string
}

export const SuccessfullSendEmail: FC<SuccessfullSendEmailProps> = ({
  email,
}) => {
  const t = useTranslate()
  return (
    <PreAdminContent
      title={t('reset_password_page.password_recovery')}
      description={
        <>
          {t('reset_password_page.follow_the_link')}{' '}
          <span className='text-primary'>{email}</span>
        </>
      }
      buttonText={t('reset_password_page.got_it')}
      redirectLink='/login'
    />
  )
}
