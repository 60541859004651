import { getCurrentViewer } from 'entities/viewer'
import { authCompany } from 'features/auth/basic'
import { HydraAxiosError, Notify } from 'shared/@types'
import Cookies from 'universal-cookie'

export const onAuthCompany = async (authLogin: string, notify: Notify) => {
  const cookies = new Cookies()
  authCompany(authLogin)
    .then(res => {
      const access_token = cookies.get('user_access_token')
      const refresh_token = cookies.get('user_refresh_token')
      cookies.set('company_access_token', access_token, { path: '/' })
      cookies.set('company_refresh_token', refresh_token, { path: '/' })
      cookies.set('user_access_token', res.data.access_token, { path: '/' })
      cookies.set('user_refresh_token', res.data.refresh_token, { path: '/' })
    })
    .then(async () => {
      const user = await getCurrentViewer()
      localStorage.setItem(
        'referral_admin_current_user',
        JSON.stringify({ user })
      )
      window.history.pushState(null, '', '/admin#/users')
      window.location.reload()
      return Promise.resolve()
    })
    .catch((e: HydraAxiosError) =>
      notify(e.response?.data?.['hydra:description'] || '', { type: 'error' })
    )
}
