export const TRANSACTION_STATUS_NEW = 0
export const TRANSACTION_STATUS_IN_QUEUE = 1
export const TRANSACTION_STATUS_IN_PROCESS = 2
export const TRANSACTION_STATUS_COMPLETED = 3
export const TRANSACTION_STATUS_ERROR = 4
export const TRANSACTION_STATUS_CANCEL = 5

export const TRANSACTION_TYPE_WEBHOOK = 1
export const TRANSACTION_TYPE_PAYOUT = 2

export enum TRANSACTION_STATUSES {
  New = TRANSACTION_STATUS_NEW,
  'In Queue' = TRANSACTION_STATUS_IN_QUEUE,
  'In Process' = TRANSACTION_STATUS_IN_PROCESS,
  Completed = TRANSACTION_STATUS_COMPLETED,
  Error = TRANSACTION_STATUS_ERROR,
  Cancel = TRANSACTION_STATUS_CANCEL,
}

export enum TRANSACTION_TYPES {
  Webhook = TRANSACTION_TYPE_WEBHOOK,
  Payout = TRANSACTION_TYPE_PAYOUT,
}
