import Mock from 'mockjs'
import { rest } from 'msw'
import { addBaseURL, addDefaultHeaders, getListSize } from '../helpers'

export const transactions = [
  rest.get(addBaseURL('/transactions'), (req, res, ctx) => {
    return res(
      addDefaultHeaders,
      ctx.status(200),
      ctx.json(
        Mock.mock({
          '@context': '/contexts/Transactions',
          '@id': '/transactions',
          '@type': 'hydra:Collection',
          [getListSize(req.url.searchParams)]: [TRANSACTIONS_ENTITY_MOCK],
          'hydra:totalItems': 100,
        })
      )
    )
  }),
  rest.get(addBaseURL('/transactions/:id'), (req, res, ctx) => {
    const id = req.params.id
    return res(
      ctx.status(200),
      ctx.json(
        Mock.mock({
          ...TRANSACTIONS_ENTITY_MOCK,
          '@context': '/contexts/Transactions',
          'id|+1': `/transactions/${id}`,
          '@id|+1': `/transactions/${id}`,
        })
      )
    )
  }),
]

const REFERRAL_SYSTEM_ENTITY_MOCK = {
  'id|+1': 1,
  '@id|+1': function () {
    return `/referral_systems/${this.id}`
  },
  '@type': 'ReferralSystem',
  company: /\/companies\/[0-9]/,
  name: '@title(1,3)',
} as { [key: string]: unknown }

const REFERRAL_EVENT_ENTITY_MOCK = {
  'id|+1': 1,
  '@id|+1': function () {
    return `/referral_events/${this.id}`
  },
  '@type': 'ReferralEvent',
  name: '@title(1,3)',
  type: 1,
  referralSystem: REFERRAL_SYSTEM_ENTITY_MOCK,
} as { [key: string]: unknown }

const ARBITRAGE_USER_ENTITY_MOCK = {
  'id|+1': 1,
  '@id|+1': function () {
    return `/users/${this.id}`
  },
  '@type': 'User',
  'externalId|+1': 1,
  externalUsername: '@word',
} as { [key: string]: unknown }

const REFERRAL_SESSION_ENTITY_MOCK = {
  'id|+1': 1,
  '@id|+1': function () {
    return `/referral_sessions/${this.id}`
  },
  '@type': 'ReferralSession',
  arbitrageUser: ARBITRAGE_USER_ENTITY_MOCK,
} as { [key: string]: unknown }

const TRANSACTIONS_ENTITY_MOCK = {
  'id|+1': 1,
  '@id|+1': function () {
    return `/transactions/${this.id}`
  },
  'transactionId|+1': 1,
  '@type': 'Transactions',
  user: function () {
    return `/users/${this.id}`
  },
  fromWallet: /\/wallets\/[0-9]/,
  toWallet: /\/wallets\/[0-9]/,
  referralEvent: REFERRAL_EVENT_ENTITY_MOCK,
  referralSession: REFERRAL_SESSION_ENTITY_MOCK,
  dateTransaction: '@date("dd.MM.yyyy in HH:MM")',
  dateCreate: '@date("dd.MM.yyyy in HH:MM")',
  type: 'Transfer',
  errorMessage: '@sentence',
  utmSource: '@word',
  utmMedium: '@word',
  utmCampaign: '@word',
  utmContent: '@word',
  utmTerm: '@word',
  'status|0-5': 0,
  'fee|10-10000': 10,
  'total|10-10000': 10,
} as { [key: string]: unknown }
