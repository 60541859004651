import {
  usePermissions,
  TextField,
  DateField,
  SimpleList,
  useListContext,
  useSidebarState,
} from 'react-admin'
import {
  CustomActionButtons,
  CustomMuiSelect,
  CustomSelectedBar,
  CustomAllCheckboxSelected,
} from 'shared/ui'
import { Checkbox } from '@mui/material'
import { SORT_ASC, SORT_DESC } from 'shared/config'
import { Permissions } from 'features/auth/basic'
import { ROLE_ADMIN } from 'entities/users'
import { ReferralSystems } from '../model'
import { FC } from 'react'
import { CURRENCY_NAME } from 'entities/company'
import { useTranslate } from 'react-admin'
import { transformReferralEvents, findConpenstionEvent } from '../lib'

export const ReferralSystemsMobile: FC = () => {
  const { onToggleItem, setSort, selectedIds } = useListContext()
  const { permissions } = usePermissions<Permissions>()
  const t = useTranslate()
  const [open] = useSidebarState()
  if (open) return null
  return (
    <>
      {!!selectedIds.length && <CustomSelectedBar />}
      <CustomMuiSelect
        label={t('payout_building.sort_by_creation_date')}
        fullWidth
        onChange={e =>
          setSort({ field: 'dateCreate', order: e.target.value as string })
        }
        choices={[
          { id: SORT_DESC, name: SORT_DESC },
          { id: SORT_ASC, name: SORT_ASC },
        ]}
      />
      <CustomAllCheckboxSelected />
      <SimpleList
        linkType={false}
        primaryText={record => (
          <div>
            <Checkbox
              onClick={() => onToggleItem(record.id)}
              checked={selectedIds.includes(record.id)}
            />
            {t('payout_building.filter.way_name')}{' '}
            <TextField
              label={t('payout_building.filter.way_name')}
              source='name'
              sortable={false}
            />
          </div>
        )}
        secondaryText={() => (
          <div className='grid grid-cols-4 ml-3 laptop:grid-cols-2 tablet:grid-cols-1'>
            <div className='flex items-center text-center'>
              {t('payout_building.filter.steps')}:
              <TextField
                label={t('payout_building.filter.steps')}
                source='referralEventsCount'
                sortable={false}
              />
            </div>
            <div className='flex items-center text-center'>
              {t('payout_building.filter.compensation')}:
              <TextField
                label={`${t(
                  'payout_building.filter.compensation'
                )},, ${CURRENCY_NAME}`}
                source='referralEventsSum'
                sortable={false}
              />
            </div>
            <div className='flex items-center text-center'>
              {t('payout_building.table.creation_date')}:
              <DateField
                label={t('payout_building.table.creation_date')}
                source='dateCreate'
                options={{
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                }}
              />
            </div>
            {permissions?.roles?.includes(ROLE_ADMIN) && (
              <div className='flex items-center text-center'>
                {t('payout_building.table.company')}:
                <TextField
                  label='Company'
                  source='company.name'
                  sortable={false}
                />
              </div>
            )}
            <CustomActionButtons<ReferralSystems>
              ShowButtonProps={{
                title: t('payout_building.show.payout_way_view'),
                showData: record => [
                  {
                    label: t('payout_building.show.way_name'),
                    value: record.name,
                  },
                  ...transformReferralEvents(record.referralEvents).map(
                    (event, i) => ({
                      label: `${t('payout_building.show.step')} ${++i}`,
                      value: (
                        <div className='flex flex-col gap-0.5'>
                          <p className='text-black'>{event.name}</p>
                          {event.isNegation && (
                            <p className='text-gray'>
                              {t(
                                'payout_building.show.condition_must_not_be_met'
                              )}
                            </p>
                          )}
                          <a href={event.webhookUrl}>{event.webhookUrl}</a>
                          {!!event.sum && (
                            <p className='text-gray'>{`${t(
                              'payout_building.show.cost'
                            )}, ${CURRENCY_NAME} = ${event.sum}`}</p>
                          )}
                        </div>
                      ),
                    })
                  ),
                  {
                    label: t('payout_building.show.compensation'),
                    value:
                      String(
                        findConpenstionEvent(record.referralEvents)?.sum
                      ) || '0',
                  },
                ],
              }}
            />
          </div>
        )}
      />
    </>
  )
}
