import { AxiosRequestConfig } from 'axios'
import { httpClient } from 'shared/http-client'
import {
  ResetPasswordConfirmationFormData,
  ResetPasswordConfirmationResponse,
} from './types'

export const resetPasswordConfirmation = (
  config: AxiosRequestConfig<ResetPasswordConfirmationFormData>
) =>
  httpClient<
    ResetPasswordConfirmationResponse,
    ResetPasswordConfirmationFormData
  >({
    ...config,
    url: '/confirm-code',
    method: 'POST',
  })
