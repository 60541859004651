import { httpClient } from 'shared/http-client'
import { INSTRUCTION_REQUEST_TARGET, InstructionResponse } from '../lib'
import { SORT_DESC } from 'shared/config'

export const getInstruction = () =>
  httpClient<InstructionResponse>({
    params: {
      'order[id]': SORT_DESC,
    },
    url: INSTRUCTION_REQUEST_TARGET,
  })
