import {
  FunctionField,
  ReferenceField,
  DateField,
  SimpleList,
  useListContext,
  useSidebarState,
  TextField,
} from 'react-admin'
import { CustomMuiSelect } from 'shared/ui'
import { SORT_ASC, SORT_DESC } from 'shared/config'
import { FC } from 'react'
import {
  Wallet,
  Transaction,
  TRANSACTION_STATUSES,
  TRANSACTION_TYPES,
} from '../model'
import { UserInfoComponent } from 'entities/users/ui'
import { UserInfo } from 'entities/users'
import { CURRENCY_NAME } from 'entities/company'
import { useTranslate } from 'react-admin'

export const TransactionsMobile: FC = () => {
  const { setSort } = useListContext()
  const [open] = useSidebarState()
  const t = useTranslate()
  if (open) return null
  return (
    <>
      <CustomMuiSelect
        label={t('internal_transactions.sort_by_creation_date')}
        fullWidth
        onChange={e =>
          setSort({ field: 'dateCreate', order: e.target.value as string })
        }
        choices={[
          { id: SORT_DESC, name: SORT_DESC },
          { id: SORT_ASC, name: SORT_ASC },
        ]}
      />
      <SimpleList
        linkType={false}
        primaryText={() => (
          <div className='mb-4'>
            {t('internal_transactions.table.arbitrage_user')}{' '}
            <ReferenceField
              label={t('internal_transactions.table.arbitrage_user')}
              source='referralSession.arbitrageUser[@id]'
              reference='users'
              link={false}
              sortable={false}
            >
              <FunctionField
                sortable={false}
                render={(user: UserInfo) => <UserInfoComponent {...user} />}
              />
            </ReferenceField>
          </div>
        )}
        secondaryText={() => (
          <div className='grid grid-cols-4 gap-3 laptop:grid-cols-2 tablet:grid-cols-1'>
            <div className='flex items-center text-center'>
              {t('internal_transactions.table.type')}:
              <FunctionField
                label='Type'
                source='referralEvent'
                sortable={false}
                render={({ referralEvent }: Transaction) =>
                  TRANSACTION_TYPES[referralEvent.type]
                }
              />
            </div>
            <div className='flex items-center gap-2 text-center'>
              {t('internal_transactions.table.creation_date')}:
              <DateField
                label={t('internal_transactions.table.creation_date')}
                source='dateTransaction'
                options={{
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                }}
              />
            </div>
            <div className='flex items-center gap-2 text-center'>
              {t('internal_transactions.table.event_type')}:
              <FunctionField
                label={t('internal_transactions.table.event_type')}
                source='referralEvent'
                sortable={false}
                render={({ referralEvent }: Transaction) =>
                  TRANSACTION_TYPES[referralEvent.type]
                }
              />
            </div>
            <div className='flex items-center gap-2 text-center'>
              {t('internal_transactions.table.company')}:
              <ReferenceField
                label={t('internal_transactions.table.company')}
                source='referralSession[@id]'
                reference='referral_sessions'
                link={false}
                sortable={false}
              >
                <ReferenceField
                  source='user'
                  reference='users'
                  link={false}
                  sortable={false}
                >
                  <TextField source='company.name' />
                </ReferenceField>
              </ReferenceField>
            </div>
            <div className='flex items-center gap-2 text-center'>
              {t('internal_transactions.table.user')}:
              <ReferenceField
                label={t('internal_transactions.table.user')}
                source='referralSession[@id]'
                reference='referral_sessions'
                link={false}
                sortable={false}
              >
                <ReferenceField
                  source='user'
                  reference='users'
                  link={false}
                  sortable={false}
                >
                  <FunctionField
                    sortable={false}
                    render={(user: UserInfo) => <UserInfoComponent {...user} />}
                  />
                </ReferenceField>
              </ReferenceField>
            </div>
            <div className='flex items-center gap-2 text-center'>
              {t('internal_transactions.table.status')}:
              <FunctionField
                label='Status'
                source='status'
                sortable={false}
                render={({ status }: Transaction) =>
                  t(
                    'internal_transactions.statuses.' +
                      TRANSACTION_STATUSES[status]
                  )
                }
              />
            </div>
            <div className='flex items-center gap-2 text-center'>
              {t('internal_transactions.table.sum')}:
              <FunctionField
                label='Sum'
                source='total'
                render={({ total }: Wallet) => `${total} ${CURRENCY_NAME}`}
              />
            </div>
          </div>
        )}
      />
    </>
  )
}
