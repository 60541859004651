import { TokenResponse } from 'shared/auth-request'
import { httpClient } from 'shared/http-client'
import { AUTH_COMPANY } from '../lib'

export const authCompany = async (login: string) =>
  httpClient<TokenResponse, { login: string }>({
    url: AUTH_COMPANY,
    method: 'POST',
    data: { login },
  })
