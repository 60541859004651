import { rest } from 'msw'
import { addBaseURL, addDefaultHeaders } from '../helpers'

export const entrypoint = [
  rest.get(addBaseURL('/contexts/Entrypoint'), (_, res, ctx) => {
    return res(
      addDefaultHeaders,
      ctx.status(200),
      ctx.json({
        '@context': {
          '@vocab': 'https://api.referral.dev2.webant.ru/docs.jsonld#',
          hydra: 'http://www.w3.org/ns/hydra/core#',
          file: {
            '@id': 'Entrypoint/file',
            '@type': '@id',
          },
          user: {
            '@id': 'Entrypoint/user',
            '@type': '@id',
          },
          confirmCode: {
            '@id': 'Entrypoint/confirmCode',
            '@type': '@id',
          },
          company: {
            '@id': 'Entrypoint/company',
            '@type': '@id',
          },
          referralSystem: {
            '@id': 'Entrypoint/referralSystem',
            '@type': '@id',
          },
          referralEvent: {
            '@id': 'Entrypoint/referralEvent',
            '@type': '@id',
          },
          referralSession: {
            '@id': 'Entrypoint/referralSession',
            '@type': '@id',
          },
          referralWebHookModel: {
            '@id': 'Entrypoint/referralWebHookModel',
            '@type': '@id',
          },
          innerTransaction: {
            '@id': 'Entrypoint/innerTransaction',
            '@type': '@id',
          },
          externalTransaction: {
            '@id': 'Entrypoint/externalTransaction',
            '@type': '@id',
          },
          wallet: {
            '@id': 'Entrypoint/wallet',
            '@type': '@id',
          },
          companyAuthModel: {
            '@id': 'Entrypoint/companyAuthModel',
            '@type': '@id',
          },
          currentBalanceModel: {
            '@id': 'Entrypoint/currentBalanceModel',
            '@type': '@id',
          },
          currentBalanceArbitratorModel: {
            '@id': 'Entrypoint/currentBalanceArbitratorModel',
            '@type': '@id',
          },
        },
      })
    )
  }),
]
