import { useParams } from 'react-router-dom'
import { PreAdminContent } from 'shared/ui'
import { useEffect, useState } from 'react'
import { accountConfirmation } from '../model/account-confirmation-request'
import { useTranslate } from 'react-admin'

export const UserConfirmationPage = () => {
  const { code } = useParams()
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isError, setIsError] = useState(false)
  const t = useTranslate()

  useEffect(() => {
    accountConfirmation({ data: { code: code || '' } })
      .catch(() => setIsError(true))
      .finally(() => setIsConfirmed(true))
  }, [])

  if (isError) {
    return (
      <PreAdminContent
        isLoading={!isConfirmed}
        title={t('users.confirmation.error.title')}
        description={t('users.confirmation.error.description')}
        buttonText={t('users.confirmation.error.buttonText')}
        redirectLink='/login'
      />
    )
  }

  return (
    <PreAdminContent
      isLoading={!isConfirmed}
      title={t('users.confirmation.success.title')}
      description={t('users.confirmation.success.description')}
      buttonText={t('users.confirmation.success.buttonText')}
      redirectLink='/login'
    />
  )
}
