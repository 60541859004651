import { FC } from 'react'
import {
  SimpleForm,
  SimpleFormProps,
  useEditContext,
  usePermissions,
} from 'react-admin'
import {
  CustomTextInput,
  CustomFormToolbar,
  CustomPasswordInput,
  CustomSelectArrayInput,
  CustomAutoCompleteInput,
} from 'shared/ui'
import {
  ROLE_ADMIN,
  ROLE_NAME_BY_IDENTIFIER,
  userSchema,
  ROLE_USER,
  ROLE_COMPANY,
  User,
} from 'entities/users'
import { yupResolver } from '@hookform/resolvers/yup'
import { Helmet } from 'react-helmet'
import { Permissions } from 'features/auth/basic'
import { useTranslate } from 'react-admin'

export const UserForm: FC<
  Omit<SimpleFormProps, 'children'> & { isEdit?: boolean }
> = ({ isEdit, ...rest }) => {
  const t = useTranslate()

  const { record } = useEditContext<User>()
  const { permissions } = usePermissions<Permissions>()

  if (!permissions) {
    return null
  }

  const isAdmin = permissions && permissions.roles?.includes(ROLE_ADMIN)
  const isRefferalUser = record && record.roles.includes(ROLE_USER)
  return (
    <SimpleForm
      toolbar={<CustomFormToolbar />}
      resolver={yupResolver(userSchema(isEdit))}
      {...rest}
    >
      <Helmet>
        <title>{t('users.users_title')}</title>
      </Helmet>
      <div className='grid w-full grid-cols-3 gap-x-5 gap-y-10 laptop:grid-cols-2 laptop-tablet:grid-cols-1'>
        <CustomSelectArrayInput
          label='users.edit.role'
          source='roles'
          choices={[
            { id: ROLE_ADMIN, name: ROLE_NAME_BY_IDENTIFIER[ROLE_ADMIN] },
            { id: ROLE_COMPANY, name: ROLE_NAME_BY_IDENTIFIER[ROLE_COMPANY] },
            {
              id: ROLE_USER,
              name: ROLE_NAME_BY_IDENTIFIER[ROLE_USER],
              disabled: true,
            },
          ]}
          disabled={!isAdmin || isRefferalUser}
          defaultChecked
          fullWidth
        />
        <CustomTextInput
          disabled={isRefferalUser}
          label='users.edit.first_name'
          source='firstName'
          fullWidth
        />
        <CustomTextInput
          disabled={isRefferalUser}
          label='users.edit.last_name'
          source='lastName'
          fullWidth
        />
        <CustomTextInput
          disabled={isRefferalUser}
          label='users.edit.login'
          source='username'
          fullWidth
        />
        <CustomTextInput
          disabled={isRefferalUser}
          label='users.edit.email'
          source='email'
          fullWidth
        />
        <CustomTextInput
          disabled={isRefferalUser}
          label='users.edit.phone'
          source='phone'
          fullWidth
        />
        <CustomPasswordInput
          disabled={isRefferalUser}
          label='users.edit.password'
          variant='filled'
          fullWidth
          InputProps={{ className: '!h-14' }}
          autoComplete='new-password'
          source='plainPassword'
        />
        {isAdmin ? (
          <CustomAutoCompleteInput
            label='users.edit.company'
            optionText='name'
            source='company[@id]'
            reference='companies'
            disabled={isRefferalUser}
            filterToQuery={value => ({ name: value })}
          />
        ) : (
          <CustomTextInput
            label='users.edit.company'
            source='company'
            disabled
            InputProps={{ className: 'w-input' }}
            defaultValue={permissions?.company}
            format={value => value?.name}
          />
        )}
      </div>
    </SimpleForm>
  )
}
