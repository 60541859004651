import { HydraAxiosError, ServerSideValidator } from 'shared/@types'
import { EMAIL_REG_EXP, PASSWORD_REQUIREMENTS_REG_EXP } from 'shared/config'
import { Translate } from 'ra-core'
import * as yup from 'yup'

export const passwordRecoverySchema = (t: Translate) =>
  yup.object({
    email: yup
      .string()
      .matches(EMAIL_REG_EXP, t('reset_password_page.invalid_email'))
      .required(t('reset_password_page.email_req')),
  })

export const newPasswordSchema = yup.object({
  code: yup.string().notRequired(),
  newPassword: yup
    .string()
    .min(6, 'Password must contain at least 6 characters')
    .matches(
      PASSWORD_REQUIREMENTS_REG_EXP,
      'The password must contain letters of the Latin alphabet, numbers, spaces and signs ( , _ - . )'
    )
    .required('Password is required'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .required('Repeat password'),
})

export const serverSideResetPasswordValidation: ServerSideValidator =
  notify => e => {
    const error = e as HydraAxiosError
    if (error.response?.status === 404) {
      notify?.('User is not found', {
        type: 'error',
      })
    }

    if (error.response?.status === 423) {
      notify?.('Activation link already has been sent to your email', {
        type: 'error',
      })
    }
  }
