/**
 * Только константы не относящиеся к бизнес сущностям напрямую
 */

export const DefaultUserBrowserLanguage = Intl.DateTimeFormat()
  .resolvedOptions()
  .locale.toLocaleLowerCase()
  .includes('ru')
  ? 'ru'
  : 'en'

export const AXIOS_DEFAULT_HEADERS = {
  Accept: 'application/ld+json',
  'Content-Type': 'application/ld+json',
  Pragma: 'no-cache',
  'Cache-Control': 'no-cache',
  //todo вернуть с обработкой на нужные запросы
  // Localization: DefaultUserBrowserLanguage,
}

export const LANG_RU = 'ru'
export const LANG_EN = 'en'

export const SORT_ASC = 'ASC'
export const SORT_DESC = 'DESC'

export const PASSWORD_REQUIREMENTS_REG_EXP = new RegExp('^[a-zA-Z0-9, ._-]*$')

export const PHONE_REG_EXP = new RegExp(
  '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'
)

export const EMAIL_REG_EXP =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const URL_REG_EXP =
  // eslint-disable-next-line no-useless-escape
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/

export const ONLY_HTTPS_URL_REG_EXP =
  // eslint-disable-next-line no-useless-escape
  /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
