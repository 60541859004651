import { FC } from 'react'
import {
  useAuthenticated,
  ListBase,
  ListProps,
  Pagination,
  Datagrid,
  FunctionField,
  DateField,
  DateInput,
  TextField,
  TextInput,
  ReferenceField,
} from 'react-admin'
import { Helmet } from 'react-helmet'
import { CustomActionButtons, CustomActionList } from 'shared/ui'
import { SORT_DESC } from 'shared/config'
import { Company, CURRENCY_NAME } from '../lib'
import { useMediaTheme } from 'shared/hooks'
import { CompanyMobile } from './company-mobile'
import { getNumberFromString } from 'shared/helpers'
import { WalletCollectionItem } from 'entities/transactions'
import { useTranslate } from 'react-admin'

const CompanyList: FC<ListProps> = props => {
  const t = useTranslate()

  useAuthenticated()
  const { isMaxMedium } = useMediaTheme()
  return (
    <ListBase
      {...props}
      filterDefaultValues={{
        companyBalance: true,
      }}
      sort={{ field: 'id', order: SORT_DESC }}
      perPage={25}
    >
      <Helmet>
        <title>{t('companies.companies_title')}</title>
      </Helmet>
      <CustomActionList
        title={t('companies.companies_title')}
        withCreateButton={false}
        filters={[
          <TextInput key='id' label={t('companies.filter.id')} source='id' />,
          <TextInput
            key='company'
            label='companies.filter.company'
            source='name'
          />,
          <DateInput
            key='Creation date'
            source='dateCreate.after'
            label='companies.filter.creation_date_after'
          />,
          <DateInput
            key='Creation date'
            source='dateCreate.before'
            label='companies.filter.creation_date_before'
          />,
        ]}
      />
      {isMaxMedium ? (
        <CompanyMobile />
      ) : (
        <Datagrid bulkActionButtons={<></>}>
          <FunctionField
            label='companies.table.id'
            sortable
            sortBy='id'
            render={({ id }: Company) => getNumberFromString(id as string)}
          />
          <FunctionField
            label='companies.table.balance'
            render={({ balance }: Company) => (
              <div className='w-32'>{`${balance} ${CURRENCY_NAME}`}</div>
            )}
          />
          <TextField label='companies.table.company' source='name' />
          <ReferenceField
            label='companies.table.activation'
            source='wallet[0].@id'
            reference='wallets'
            emptyText={t('companies.table.isNotActivated')}
            link={false}
            sortable={false}
          >
            <FunctionField
              sortable={false}
              render={({ activated }: WalletCollectionItem) =>
                activated
                  ? t('companies.table.isActivated')
                  : t('companies.table.isNotActivated')
              }
            />
          </ReferenceField>
          <DateField
            sortable
            label='companies.table.creation_date'
            source='dateCreate'
            options={{ month: 'numeric', day: 'numeric', year: 'numeric' }}
          />
          <FunctionField
            label=''
            render={() => (
              <CustomActionButtons
                hasEdit={false}
                hasDelete={false}
                hasShow={false}
                hasWithdraw={false}
                hasAuthCompany={true}
                AuthCompanyButtonProps={{
                  title: t('companies.table.modal.signIn'),
                  label: t('companies.table.auth'),
                  variant: 'contained',
                  showData: record => [
                    {
                      label: t('companies.table.company'),
                      value: record.name,
                    },
                  ],
                }}
              />
            )}
          />
        </Datagrid>
      )}
      <Pagination />
    </ListBase>
  )
}

export default CompanyList
