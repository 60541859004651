import { httpClient } from 'shared/http-client'
import { ACCEPT_EXTERNAL_TRANSACTION_TARGET } from './constants'
import { ExternalTransaction } from './types'

export const acceptExternalTransaction = async (
  transactionId: number,
  isForce?: boolean
) => {
  const data = isForce
    ? { transactionId, forcePayout: true }
    : { transactionId }
  return httpClient<
    Pick<ExternalTransaction, 'transactionId'>,
    { transactionId: number; forcePayout?: boolean }
  >({
    url: ACCEPT_EXTERNAL_TRANSACTION_TARGET,
    data,
    method: 'POST',
  })
}
