import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { HttpClientOptions, HydraHttpClientResponse } from '@api-platform/admin'
import { getTokens } from 'shared/http-client'
import { URL } from 'url'

export const addLocaleIfNeed = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const locale = localStorage.getItem('locale')
  const resoursesWithoutLocale = [] as string[]
  if (locale && config.url) {
    const requestNeedLocale = !resoursesWithoutLocale.some(resourse =>
      config?.url?.includes(resourse)
    )
    if (requestNeedLocale) {
      if (config.params) {
        return { ...config, params: { ...config.params, locale: locale } }
      }
      return { ...config, params: { locale: locale } }
    }
  }
  return config
}

export const getDefaultAuthHeaders = (): Record<string, string> | {} => {
  const { access_token } = getTokens()

  if (!access_token) {
    return {}
  }

  return { Authorization: `Bearer ${access_token}` }
}

export const convertAxioseResponseDataToHydra = (
  response: AxiosResponse
): HydraHttpClientResponse => {
  const headers = new Headers(response.headers)
  return {
    status: response.status,
    headers,
    json: response.data,
  }
}

export const convertFetchConfigToAxios = (
  url: URL,
  fetchConfig: RequestInit | HttpClientOptions | (() => HeadersInit) = {}
): AxiosRequestConfig => {
  const config = JSON.parse(JSON.stringify(fetchConfig))
  config.url = url.pathname + (url.search || '')

  const body = (fetchConfig as RequestInit).body

  if (body && typeof body === 'string') {
    config.data = JSON.parse(body)
    delete config.body
  }

  return config as AxiosRequestConfig
}
